import React, { useContext } from "react";
import ProdPlannerContext from "./../../../context/prodPlannerContext";
import LineRow from "./LineRow";

import varNames from "../../../var-names.json";
import { cz } from "./../../../language.json";

export default function LinesTab() {
  const { app, handlers } = useContext(ProdPlannerContext);
  const lines = app[varNames.appSaved];
  const settings = app[varNames.appSettings];

  const handleLineChange = (line) => {
    const linesCopy = [...lines];
    const lineIndex = linesCopy
      .map((l) => l[varNames._id])
      .indexOf(line[varNames._id]);

    linesCopy[lineIndex] = line;

    handlers.changeSaved(linesCopy);
    handlers.handlePanelChange(varNames.linesName);
  };

  const handleLineAdd = (line) => {
    const linesCopy = [...lines];

    linesCopy.push(line);

    handlers.changeSaved(linesCopy);
    handlers.handlePanelChange(varNames.linesName);
  };

  const handleLineDelete = (lineId) => {
    const linesCopy = [...lines];
    const index = linesCopy.map((l) => l[varNames._id]).indexOf(lineId);

    if (index < 0) {
      handlers.handlePanelChange(varNames.linesName);
      return;
    }

    const decision = window.confirm(
      `${cz.general.rlyDeleteLine} ${linesCopy[index][varNames.title]} ?`
    );
    if (decision) {
      linesCopy.splice(index, 1);

      handlers.changeSaved(linesCopy);
      handlers.handlePanelChange(varNames.linesName);
    }
  };

  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-12 p-3 p-md-5 pt-5">
          <h1 className="text-center text-white app-font-main">
            {cz.general.lines}
          </h1>
          <div className="container container-white-trans">
            {lines.map((line, i) => (
              <LineRow
                key={i}
                line={line}
                onSubmit={handleLineChange}
                onDelete={handleLineDelete}
                showDelete={settings[varNames.lineDelete]}
              />
            ))}
            <button
              className="btn btn-warning"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {cz.general.newLine}
            </button>
            <div className="collapse" id="collapseExample">
              <LineRow
                onSubmit={handleLineAdd}
                showDelete={settings[varNames.lineDelete]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
