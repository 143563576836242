import { useFormikContext } from "formik";
import React from "react";

export default function SubmitButton({
  text,
  divClasses = "",
  btnClasses = "btn-warning",
}) {
  const { isValid, dirty } = useFormikContext();

  return (
    <div className={`text-center ${divClasses}`}>
      <button
        disabled={!isValid || !dirty}
        className={`btn ${btnClasses}`}
        type="submit"
      >
        {text}
      </button>
    </div>
  );
}
