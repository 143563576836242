import React, { useState, useContext, useEffect } from "react";
import { replaceAll } from "../../../../utils";
import CopyPasteModal from "./CopyPasteModal";
import LongDivisionContext from "../../../../context/longDivisionContext";

import varNames from "../../../../var-names.json";
import { cz } from "../../../../language.json";
import measures from "../../../../measures.json";

export default function CopyPasteInsert({ name, heading }) {
  const { app, handlers } = useContext(LongDivisionContext);
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const data = app[varNames.appData][name];

    let textAreaText = "";

    for (let item of data) {
      for (let key in item) {
        textAreaText += `${item[key]}\t`;
      }
      textAreaText += "\n";
    }

    setText(textAreaText);
  }, [app, name]);

  const areValid = (data) => {
    let areValid = true;
    const numberOfElements = 2;

    data.forEach((line, index) => {
      const numbers = line.split(" ");

      if (numbers.length !== numberOfElements) {
        areValid = false;
        setError(
          `${cz.validation.errorOnLine} ${index + 1}. ${
            cz.validation.wrongNumCount
          }`
        );
      }

      numbers.forEach((i) => {
        if (parseFloat(i) > measures.maxFormValue) {
          areValid = false;
          setError(
            `${cz.validation.errorOnLine} ${index + 1}. ${
              cz.validation.maximally
            } ${measures.maxFormValue}`
          );
        }

        if (parseFloat(i) < measures.minFormValue) {
          areValid = false;
          setError(
            `${cz.validation.errorOnLine} ${index + 1}. ${
              cz.validation.minimally
            } ${measures.minFormValue}`
          );
        }

        if (i.indexOf(".") !== i.lastIndexOf(".")) {
          areValid = false;
          setError(
            `${cz.validation.errorOnLine} ${index + 1}. ${
              cz.validation.moreDecimals
            }`
          );
        }
      });
    });

    return areValid;
  };

  const parseData = () => {
    const lines = text
      .split("\n")
      .map((line) => replaceAll(line, ",", "."))
      .map((line) => replaceAll(line, "\t", " "))
      .map((line) => replaceAll(line, /[^\d. ]| \.|\.$/g, ""))
      .map((line) => replaceAll(line.trim(), /  +/g, " "))
      .filter((line) => line !== "");

    // console.log(lines);

    const valid = areValid(lines);
    if (!valid) return;

    setError("");

    if (name === varNames.strips)
      mapForUsage(lines, varNames.strips, [
        varNames.stripWidth,
        varNames.neededWeight,
      ]);
    else if (name === varNames.rolls)
      mapForUsage(lines, varNames.rolls, [varNames.serie, varNames.rollWeight]);
    else throw new Error(cz.validation.error);

    setShow(false);
  };

  const mapForUsage = (data, name, properties) => {
    const ready = [];

    for (let item of data) {
      const parts = item.split(" ");

      const obj = {};

      if (parts.length !== properties.length)
        throw new Error(cz.validation.error);

      for (let i = 0; i < parts.length; i++) {
        obj[properties[i]] = parts[i];
      }

      ready.push(obj);
    }

    handlers.handleCopyPaste(ready, name);
  };

  const prop_handlers = {
    setShow,
    setText,
    parseData,
  };

  const prop_texts = {
    text,
    error,
  };

  return (
    <div>
      <button className="btn btn-dark btn-sm" onClick={() => setShow(true)}>
        {cz.general.copyInsert}
      </button>
      <CopyPasteModal
        show={show}
        heading={heading}
        handlers={prop_handlers}
        texts={prop_texts}
      />
    </div>
  );
}
