import React, { useContext, useEffect, useState } from "react";
import LongDivision from "../apps/LongDivision";
import ProdPlanner from "../apps/ProdPlanner";
import UserContext from "../../context/userContext";
import useApi from "../../hooks/useApi";
import { getApplication } from "../../services/applicationService";

import { cz } from "../../language.json";
import varNames from "../../var-names.json";
import logger from "../../services/logService";
import moment from "moment";

//musí rovnat componentName v DATABÁZI !!!
const components = {
  LongDivision,
  ProdPlanner,
};

export default function Application({ match, history }) {
  const { user } = useContext(UserContext);
  const { request: getApp, running, error, data: app } = useApi(getApplication);
  const [invalidAccess, setInvalidAccess] = useState(false);

  useEffect(() => {
    getApp([match.params.id]); //serverCall

    checkIfUserCanAccessApp(match.params.id, user);
  }, [match.params.id, history]);

  if (error) {
    logger.logError(error);
    return (window.location = "/");
  }

  if (invalidAccess)
    return (
      <h4 className="text-danger app-font-main text-center my-5">
        {cz.validation.appInvalidAccess}
      </h4>
    );

  if (running || !app || !app[varNames._id]) {
    return <h1>{cz.general.appLoading}</h1>;
  }

  const AppComponent = components[app[varNames.appComponentName]];

  return (
    <AppComponent
      defaultSettings={app[varNames.appDefSettings]}
      currentAppId={app[varNames._id]}
    />
  );

  function checkIfUserCanAccessApp(appId, user) {
    const appFromUser = user[varNames.userApps].find(
      (a) => a[varNames._id] === appId
    );

    if (
      !appFromUser ||
      moment(appFromUser[varNames.appPaidUntil]).isBefore(
        moment(new Date()),
        "days"
      )
    ) {
      setInvalidAccess(true);
      return false;
    }

    return true;
  }
}
