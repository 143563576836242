import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { getJwt, logout } from "./../services/authService";

import { cz } from "./../language.json";

export default function useSocket(user, appId, redirect) {
  const [saved, setSaved] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [error, setError] = useState(false);
  const [socket, setSocket] = useState(null);
  const [username, setUsername] = useState("");
  const [users, setUsers] = useState([]);

  // ptá se na jméno, pokud je zadané pokračuje se, pokud ne, tak zpátky na apps
  useEffect(() => {
    const u = prompt(cz.general.yourName);
    if (!u) return (window.location = window.location.origin + redirect);

    setUsername(u);
  }, [redirect]);

  // vytvoří socket (poté, co je zadané jméno)
  useEffect(() => {
    if (!username) return;

    const s = io(process.env.REACT_APP_API_URL, {
      query: { token: getJwt() },
    });
    setSocket(s);

    return () => {
      s.disconnect();
    };
  }, [username]);

  // socket listenery
  useEffect(() => {
    if (socket === null) return;

    socket.on("connect", () => {
      setError(false);

      socket.emit("join-room", {
        username,
        userId: user._id,
        appId,
        edit: false,
      });
    });

    socket.on("connect_error", () => setError(true));

    socket.on("users", (users) => setUsers(users));

    socket.on("saved", (data) => setSaved(data));

    socket.on("jwt-error", () => {
      logout();
      window.location = "/";
    });
  }, [socket, user._id, appId, username]);

  // handler pro změnu readOnly
  const changeReadOnly = () => {
    socket.emit("user-edit-change");
    setReadOnly((r) => !r);
  };

  // handler pro uložení nastavení
  const changeSaved = (newData) => {
    socket.emit("saved-edit", newData);
  };

  return { readOnly, changeReadOnly, changeSaved, users, saved, error };
}
