import React from "react";
import ErrorMessage from "./ErrorMessage";
import { Field, useFormikContext } from "formik";

export default function Slider({ title, name, min, max, step }) {
  const { values } = useFormikContext();

  return (
    <div className="p-2 d-flex flex-column align-items-center justify-content-center text-center">
      <label htmlFor={`range-${name}`} className="form-label fw-bold">
        {title}
      </label>
      <div className="text-center fs-5">{values[name]} %</div>
      <Field
        type="range"
        name={name}
        className="form-range"
        min={min}
        max={max}
        step={step}
        id={`range-${name}`}
      />
      <ErrorMessage name={name} />
    </div>
  );
}
