import { StyleSheet, Font } from "@react-pdf/renderer";

import RobotoLight from "../../../../fonts/Roboto-Light.ttf";
import Montserrat from "../../../../fonts/Montserrat-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "Montserrat", src: Montserrat });

const A4width = 279;
const A4height = 210;
const weekW = 10;
const dayW = 20;
const dateW = 15;
const shiftH = 4.8;
const shiftW = 20;
const lineH = 7;

const borderStyle = "1px solid black";

export const style_measures = {
  A4height,
  A4width,
  shiftH,
  shiftW,
  borderStyle,
};

export default StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "RobotoLight",
    marginTop: 2,
    display: "flex",
    alignItems: "center",
  },
  calCover: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  week: {
    width: `${weekW}mm`,
    border: borderStyle,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dayCover: {
    width: `${dayW}mm`,
    fontSize: 10,
    display: "flex",
    border: borderStyle,
    flexDirection: "row",
    alignItems: "center",
  },
  day: {
    width: `${dateW}mm`,
    textAlign: "center",
  },
  dayShift: {
    width: `${dayW - dateW}mm`,
    height: "100%",
    borderLeft: borderStyle,
  },
  dayShiftType: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 8,
  },
  lineColsCover: {
    display: "flex",
    flexDirection: "row",
  },
  lineTitle: {
    height: `${lineH}mm`,
    // width: `${shiftW}mm`,
    border: borderStyle,
    fontSize: 10,
    fontFamily: "Montserrat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  shiftDay: {
    width: `${shiftW}mm`,
    height: `${shiftH}mm`,
    fontSize: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: borderStyle,
    fontFamily: "Montserrat",
  },
  colUnderShiftColHeading: {
    marginTop: `${lineH}mm`,
  },
  lineCols: {
    display: "flex",
    flexDirection: "row",
  },
});
