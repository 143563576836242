import React from "react";

import { cz } from "./../language.json";

export default function SearchBox({ onChange }) {
  return (
    <div className="input-group mb-3 slider-width-reduction">
      <input
        type="text"
        className="form-control"
        placeholder={cz.general.productTitle}
        onChange={(e) => onChange(e.target.value)}
        autoFocus
      />
    </div>
  );
}
