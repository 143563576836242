import { StyleSheet, Font } from "@react-pdf/renderer";

import RobotoLight from "../../../../fonts/Roboto-Light.ttf";
import Montserrat from "../../../../fonts/Montserrat-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "Montserrat", src: Montserrat });

const A4width = 279;
const A4height = 210;
const tableInputWidth = 45;
const cellW = 10;
const wasteCellW = 15;
const missingHeaderW = 80;
const usedHeaderW = 50;

const verticalCenter = {
  paddingTop: 4,
  paddingBottom: 2,
};

const borderStyle = "1px solid black";

export const style_measures = {
  A4height,
  A4width,
  tableInputWidth,
  cellW,
  wasteCellW,
  borderStyle,
};

export default StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "RobotoLight",
    marginTop: 5,
  },
  table_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  other_tables_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: 20,
    marginHorizontal: 10,
  },
  table_vertical_container: {
    display: "flex",
    flexDirection: "column",
  },
  table_header: {
    borderTop: borderStyle,
    borderLeft: borderStyle,
    borderRight: borderStyle,
    display: "flex",
    flexDirection: "row",
  },
  table_header_input: {
    borderRight: borderStyle,
    textAlign: "center",
    width: `${tableInputWidth}mm`,
  },
  table_header_output: {
    borderRight: borderStyle,
    textAlign: "center",
  },
  table_row: {
    border: borderStyle,
    borderBottom: "",
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    textAlign: "center",
  },
  table_row_weights: {
    border: borderStyle,
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    textAlign: "center",
  },
  table_cell_thickness: {
    borderRight: borderStyle,
    width: "10mm",
    ...verticalCenter,
  },
  table_cell_width: {
    borderRight: borderStyle,
    width: "15mm",
    ...verticalCenter,
  },
  table_cell_weight: {
    borderRight: borderStyle,
    width: "20mm",
    ...verticalCenter,
  },
  table_cell_waste: {
    borderRight: borderStyle,
    width: `${wasteCellW}mm`,
    ...verticalCenter,
  },
  table_cell: {
    borderRight: borderStyle,
    width: `${cellW}mm`,
    ...verticalCenter,
  },
  table_header_missing: {
    textAlign: "center",
    width: `${missingHeaderW}mm`,
    ...verticalCenter,
  },
  table_missing_width: {
    borderRight: borderStyle,
    width: `17mm`,
    ...verticalCenter,
  },
  table_missing_label: {
    borderRight: borderStyle,
    width: `40mm`,
    ...verticalCenter,
  },
  table_missing_weight: {
    width: `23mm`,
    ...verticalCenter,
  },
  table_header_used: {
    textAlign: "center",
    width: `${usedHeaderW}mm`,
    ...verticalCenter,
  },
  table_used_serie: {
    borderRight: borderStyle,
    width: `15mm`,
    ...verticalCenter,
  },
  table_used_serie_heading: {
    borderRight: borderStyle,
    width: `15mm`,
    fontFamily: "Montserrat",
    ...verticalCenter,
  },
  table_used_weight: {
    width: `35mm`,
    ...verticalCenter,
  },
  table_used_weight_heading: {
    width: `35mm`,
    fontFamily: "Montserrat",
    ...verticalCenter,
  },
});
