import React, { useContext, useEffect, useRef, useState } from "react";
import TableMainHeader from "./Result/TableMainHeader";
import TableSecondaryHeader from "./Result/TableSecondaryHeader";
import LongDivisionContext from "./../../../context/longDivisionContext";
import TableDetails from "./Result/TableDetails";
import Table from "./Result/Table";
import Loading from "../../Loading";
import ProgressBar from "../../ProgressBar";
import _ from "lodash";
import PDFoutput from "./Result/PDFoutput";

import { cz } from "../../../language.json";
import varNames from "../../../var-names.json";
import measures from "../../../measures.json";
import logger from "./../../../services/logService";

export default function Result() {
  const { runData } = useContext(LongDivisionContext);
  const { running, result, runError } = runData;

  const numberOfSec = measures.longDivTimeOutSec;
  const [secLeft, setSecLeft] = useState(numberOfSec);

  const interval = useRef();

  useEffect(() => {
    setSecLeft(numberOfSec);

    if (running) {
      interval.current = setInterval(() => {
        setSecLeft((prev) => {
          const newValue = prev - 1;

          if (newValue === 0) clearInterval(interval.current);
          return newValue;
        });
      }, 1000);
    }

    if (!running) clearInterval(interval.current);
  }, [running, numberOfSec]);

  const renderError = (message) => {
    if (message === cz.validation.serverDown) logger.logError(runError);

    return (
      <div className="container">
        <div className="alert alert-danger m-0" role="alert">
          {message}
        </div>
      </div>
    );
  };

  const loadingWithProgressBar = () => {
    const secLeftInPercent = (100 / numberOfSec) * secLeft;

    return (
      <div className="loading-over-everything">
        <div className="container d-flex flex-column justify-content-center align-self-center vh-100">
          <Loading />
          <ProgressBar
            currentValue={secLeftInPercent}
            message={`${secLeft}s`}
          />
        </div>
      </div>
    );
  };

  //to že to nic nenašlo poznám tak, že 0. prvek v outputu má strips null nebo rollsUsedForMerge je prázdný

  return (
    <div className={`${Object.keys(result).length > 0 ? "py-3" : ""}`}>
      {running && loadingWithProgressBar()}
      {result[varNames.timeOut] && renderError(cz.validation.timeOut)}
      {runError && renderError(cz.validation.serverDown)}
      {result[varNames.longDivOutput] &&
        result[varNames.usedRolls].length === 0 &&
        renderError(cz.validation.noDataFound)}
      {result[varNames.longDivOutput] &&
        result[varNames.longDivOutput].length > 0 &&
        result[varNames.usedRolls].length > 0 &&
        generteTable(result)}
    </div>
  );
}

export const generteTable = (result, isForPdf = false) => {
  const mainRoll = result[varNames.longDivOutput][0];

  const isFromServer = Object.keys(mainRoll)[0].includes(
    varNames.serverDataPrefix
  );
  const prefix = isFromServer ? varNames.serverDataPrefix : "";

  const inputCols = 3;
  const outputCols = countNumberOfColls();
  const missingCols = 3;
  const usedRollsCols = 2;

  const waste = countWaste();
  const wasteWeight = countWasteWeight(waste);
  const numbers = getNumbers(outputCols);
  const titlesRow = getTitlesRow(numbers);
  const unitsRow = getUnitsRow(outputCols);
  const detailsRow = getDetailsNWidths(waste, getNumberedStrips());
  const weightsRow = [wasteWeight, ...getNumberedWeights(), wasteWeight];
  const missingRows = getMissingRows();
  const usedRolls = getUsedRollsRows();
  const usedRollsTitles = [cz.general.serie, cz.general.weight];

  if (isForPdf)
    return (
      <PDFoutput
        secRow={[cz.general.thicknessShortcut, ..._.slice(titlesRow, 1)]}
        thrRow={unitsRow}
        fouRow={detailsRow}
        lastRow={weightsRow}
        outputColSpan={outputCols}
        missingStrips={missingRows}
        usedRolls={usedRolls}
      />
    );

  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-12 p-3">
          <h1 className="text-center text-black app-font-main">
            {cz.general.result}
          </h1>
          <div className="container container-gray-trans">
            <div className="row p-0 m-0">
              <div className="col-12 d-flex justify-content-center">
                <Table classes="mb-5">
                  <thead>
                    <TableMainHeader
                      inputCols={inputCols}
                      outputCols={outputCols}
                    />
                    <TableSecondaryHeader items={titlesRow} />
                    <TableSecondaryHeader items={unitsRow} />
                  </thead>
                  <tbody>
                    <TableDetails items={detailsRow} />
                    <TableDetails
                      items={weightsRow}
                      colSpan={inputCols}
                      colSpanTitle={cz.general.partialWeight}
                    />
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="row p-0 m-0">
              <div className="col-sm-12 col-md-6 d-flex justify-content-center">
                <Table>
                  <thead>
                    <TableMainHeader
                      inputCols={missingCols}
                      firstTitle={cz.general.missingStrips}
                    />
                  </thead>
                  <tbody>
                    {missingRows.map((row, i) => (
                      <TableDetails key={i} items={row} notBold />
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="col-sm-12 col-md-6 d-flex justify-content-center">
                <Table>
                  <thead>
                    <TableMainHeader
                      inputCols={usedRollsCols}
                      firstTitle={cz.general.usedRolls}
                    />
                    <TableSecondaryHeader
                      items={usedRollsTitles}
                      noAddits
                      bold
                    />
                  </thead>
                  <tbody>
                    {usedRolls.map((row, i) => (
                      <TableDetails key={i} items={row} notBold />
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function countWaste() {
    let totalStripsWidth = 0;
    const arrayOfStrips = mainRoll[`${prefix}${varNames.strips}`];

    arrayOfStrips.forEach((obj) => {
      for (let key in obj) {
        totalStripsWidth +=
          obj[key][`${prefix}${varNames.stripWidth}`] * parseInt(key);
      }
    });

    return (mainRoll[`${prefix}${varNames.rollWidth}`] - totalStripsWidth) / 2;
  }

  function countNumberOfColls() {
    const stripsArray = mainRoll[`${prefix}${varNames.strips}`];
    let sum = 0;
    stripsArray.forEach((x) => {
      sum += parseInt(Object.keys(x)[0]);
    });

    return sum;
  }

  function getNumbers(outputCols) {
    return _.range(1, outputCols + 1);
  }

  function getNumberedStrips() {
    const output = [];

    const arrayOfStrips = mainRoll[`${prefix}${varNames.strips}`];

    arrayOfStrips.forEach((obj) => {
      for (let key in obj) {
        for (let i = 0; i < parseInt(key); i++)
          output.push(obj[key][`${prefix}${varNames.stripWidth}`]);
      }
    });

    return output;
  }

  function getNumberedWeights() {
    const output = [];

    const arrayOfStrips = mainRoll[`${prefix}${varNames.strips}`];

    arrayOfStrips.forEach((obj) => {
      for (let key in obj) {
        for (let i = 0; i < parseInt(key); i++) {
          const stripWidth = obj[key][`${prefix}${varNames.stripWidth}`];
          const rollWeight = mainRoll[`${prefix}${varNames.rollWeight}`];
          const rollWidth = mainRoll[`${prefix}${varNames.rollWidth}`];

          const stripWeight = (rollWeight / rollWidth) * stripWidth;
          output.push(_.round(stripWeight, measures.tableRounding));
        }
      }
    });

    return output;
  }

  function countWasteWeight(wasteWidth) {
    const rollWeight = mainRoll[`${prefix}${varNames.rollWeight}`];
    const rollWidth = mainRoll[`${prefix}${varNames.rollWidth}`];

    const wasteWeight = (rollWeight / rollWidth) * wasteWidth;

    return _.round(wasteWeight, measures.tableRounding);
  }

  function getTitlesRow(numbers) {
    return [
      cz.general.thickness,
      cz.general.width,
      cz.general.weight,
      cz.general.waste,
      ...numbers,
      cz.general.waste,
    ];
  }

  function getUnitsRow(count) {
    const mm = cz.general.mm;

    return [mm, mm, cz.general.kg, mm, ..._.fill(Array(count), mm), mm];
  }

  function getDetailsNWidths(waste, strips) {
    const thickness = mainRoll[`${prefix}${varNames.rollThickness}`];
    const width = mainRoll[`${prefix}${varNames.rollWidth}`];
    const weight = mainRoll[`${prefix}${varNames.rollWeight}`];

    return [thickness, width, weight, waste, ...strips, waste];
  }

  function getMissingRows() {
    const output = [];

    const arrayOfStrips = mainRoll[`${prefix}${varNames.strips}`];

    arrayOfStrips.forEach((obj) => {
      const innerArray = [];

      //tento cyklus proběhne vždy jen jednou...
      for (let key in obj) {
        const stripWidth = obj[key][`${prefix}${varNames.stripWidth}`];
        const neededWeight = obj[key][`${prefix}${varNames.neededWeight}`];
        const currentWeight = obj[key][`${prefix}${varNames.currentWeight}`];

        const missingWeight = _.round(
          neededWeight - currentWeight,
          measures.tableRounding
        );
        const sentence =
          missingWeight > 0 ? cz.general.undoneMissing : cz.general.doneOver;

        innerArray.push(`${stripWidth} ${cz.general.mm}`);
        innerArray.push(sentence);
        innerArray.push(`${Math.abs(missingWeight)} ${cz.general.kg}`);
      }
      output.push(innerArray);
    });

    return output;
  }

  function getUsedRollsRows() {
    const usedRolls = result[varNames.usedRolls];

    const output = usedRolls.map((roll) => {
      const serie = roll[`${prefix}${varNames.serie}`];
      const weight = roll[`${prefix}${varNames.rollWeight}`];

      return [`${serie}`, `${weight} ${cz.general.kg}`];
    });

    return output;
  }
};
