import React from "react";

import varNames from "../../../../var-names.json";
import { cz } from "../../../../language.json";

export default function General({ data }) {
  const renderMMitem = (data, item) =>
    data[item] || data[item] === 0 ? (
      `${data[item]} ${cz.general.mm}`
    ) : (
      <i>{cz.general.empty}</i>
    );

  return (
    <div className="container">
      <h3 className="text-center text-secondary">{cz.general.general}</h3>
      <table className="table text-center">
        <tbody>
          <tr>
            <td>{renderMMitem(data, varNames.rollWidth)}</td>
            <th>{cz.general.rollWidth}</th>
          </tr>
          <tr>
            <td>{renderMMitem(data, varNames.rollThickness)}</td>
            <th>{cz.general.rollThickness}</th>
          </tr>
          <tr>
            <td>{renderMMitem(data, varNames.minWaste)}</td>
            <th>{cz.general.minWaste}</th>
          </tr>
          <tr>
            <td>{renderMMitem(data, varNames.maxWaste)}</td>
            <th>{cz.general.maxWaste}</th>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
