import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import App from "./App";
import Home from "./components/routes/Home";
import NavBar from "./components/NavBar";
import Login from "./components/Login";
import Logout from "./components/Logout";
import { getCurrentUser } from "./services/authService";

export default function Client() {
  const user = getCurrentUser() || null;

  return (
    <>
      <NavBar user={user} />
      <Switch>
        <ProtectedRoute
          path="/app"
          render={(props) => <App {...props} userId={user ? user._id : ""} />}
        />
        <Route path="/login" component={Login} />
        <ProtectedRoute path="/logout" component={Logout} />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
    </>
  );
}
