import React from "react";

import { cz } from "../../../../language.json";

export default function CopyPasteModal({ show, heading, handlers, texts }) {
  const { setShow, setText, parseData } = handlers;
  const { text, error } = texts;

  return (
    <div className={`app-modal ${show ? "app-modal-display" : ""}`}>
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{`${cz.general.copyInsert} - ${heading}`}</h5>
            <button
              className="btn-close"
              onClick={() => setShow(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-floating">
              <textarea
                className="form-control"
                placeholder={cz.general.insertHere}
                id="text-area"
                style={{ height: "350px" }}
                onChange={(e) => setText(e.target.value)}
                value={text}
              ></textarea>
              <label htmlFor="text-area">{cz.general.insertHere}</label>
            </div>
            {error ? <span className="text-danger">{error}</span> : null}
          </div>
          <div className="modal-footer">
            <button onClick={() => setShow(false)} className="btn btn-danger">
              {cz.general.close}
            </button>
            <button className="btn btn-success" onClick={parseData}>
              {cz.general.save}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
