import { Field } from "formik";
import React from "react";
import ErrorMessage from "./ErrorMessage";

export default function CheckBox({ name, title }) {
  return (
    <>
      <div className="form-check form-switch d-flex align-items-center justify-content-center">
        <Field
          type="checkbox"
          name={name}
          className="form-check-input mx-2"
          id={`${name}-checkB`}
        />
        <label className="form-check-label fw-bold" htmlFor={`${name}-checkB`}>
          {title}
        </label>
      </div>
      <div className="text-center">
        <ErrorMessage name={name} />
      </div>
    </>
  );
}
