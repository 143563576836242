import React, { useMemo } from "react";
import * as Yup from "yup";
import Form from "../../forms/Form";
import LeftSelect from "./../../forms/LeftSelect";
import SubmitButton from "../../forms/SubmitButton";
import moment from "moment";
import _ from "lodash";
import { generatePdfOnClick } from "../../../hooks/usePdf";
import PDFoutputAdvanced from "./Output/PDFoutputAdvanced";

import varNames from "./../../../var-names.json";
import { cz } from "./../../../language.json";

export default function PdfConfig({
  current,
  lines,
  shiftRows,
  settings,
  shifts,
}) {
  const weeksDD = useMemo(() => makeWeeksDD(current), [current]);
  const weeksDuration = useMemo(() => generateNumbers(), []);

  const handleSubmit = (values) => {
    const startWeek = weeksDD.find(
      (w) => w.name.toString() === values[varNames.startWeek].toString()
    );

    const duration = values[varNames.weekDuration];

    const weeks = getNeededWeeks(startWeek, duration);
    const dates = getNeededDates(startWeek, duration);
    const dataDates = getNeededDataDates(startWeek, duration, shifts, settings);
    const titles = getNeededTitles(lines);

    const pdfData = { startWeek, duration, dates, weeks, dataDates, titles };

    generatePdfOnClick(
      <PDFoutputAdvanced
        data={pdfData}
        shiftRows={shiftRows}
        settings={settings}
      />
    );
  };

  return (
    <>
      <h4 className="app-font-main text-center fw-bold">
        {cz.general.chooseWeeks}
      </h4>
      <Form
        onSubmit={(values) => handleSubmit(values)}
        initialValues={initialValues()}
        validationSchema={validationSchema()}
      >
        <LeftSelect
          items={weeksDD}
          name={varNames.startWeek}
          label={cz.general.weekBegin}
        />
        <LeftSelect
          items={weeksDuration}
          name={varNames.weekDuration}
          label={cz.general.weekNumber}
        />
        <SubmitButton text={cz.general.generate} />
      </Form>
    </>
  );
}

function makeWeeksDD(current) {
  const firstDayOfMonth = current.clone().startOf("month");
  const lastDayOfMonth = current.clone().endOf("month");

  const firstDayNumber = firstDayOfMonth.isoWeekday();

  const firstWeekMonday = moment(firstDayOfMonth)
    .subtract(firstDayNumber - 1, "days")
    .toDate();

  const weeks = [];

  let curMonday = firstWeekMonday;

  do {
    const weekNumber = moment(curMonday).week();

    weeks.push({ _id: weekNumber, name: weekNumber, monday: curMonday });
    curMonday = moment(curMonday).add(7, "days").toDate();
  } while (moment(curMonday).isSameOrBefore(lastDayOfMonth, "day"));

  return weeks;
}

function generateNumbers() {
  const numbers = [];

  for (let i = 1; i <= 10; i++) {
    numbers.push({ _id: i, name: i });
  }

  return numbers;
}

function getNeededWeeks(startWeek, duration) {
  const weeks = [];

  let curMonday = startWeek.monday;
  for (let i = 0; i < duration; i++) {
    const weekNumber = moment(curMonday).week();

    weeks.push({ week: weekNumber });

    curMonday = moment(curMonday).add(7, "days").toDate();
  }
  console.log("PDF - weeks generated");

  return weeks;
}

function getNeededDates(startWeek, duration) {
  const dates = [];
  const days = duration * 7;
  const firstMonday = startWeek.monday;

  for (let i = 0; i < days; i++) {
    dates.push(moment(firstMonday).add(i, "days").toDate());
  }
  console.log("PDF - dates generated");

  return dates;
}

function getNeededDataDates(startWeek, duration, shifts, settings) {
  const { shiftsPerDay, startDate } = settings;
  const beforeFix = [];
  const afterFix = [];

  const startDay = moment(startWeek.monday);
  const startIndex = startDay.diff(startDate, "days") * shiftsPerDay;
  let saveStartIndex = startIndex;
  if (startIndex < 0) {
    saveStartIndex = 0;
    const missingBefore = Math.abs(startIndex);
    for (let i = 0; i < missingBefore; i++) {
      beforeFix.push({ isBlock: true });
    }
  }
  // console.log("startIndex", startIndex, "saveStartIndex", saveStartIndex);

  const endDay = moment(startWeek.monday).add(7 * duration, "days");
  const endIndex = endDay.diff(startDate, "days") * shiftsPerDay;
  let saveEndIndex = endIndex;
  if (endIndex > shifts[0].length) {
    saveEndIndex = shifts[0].length;
    const missingAfter = endIndex - saveEndIndex;
    for (let i = 0; i < missingAfter; i++) {
      afterFix.push({ isBlock: true });
    }
  }

  // console.log("endIndex", endIndex, "saveEndIndex", saveEndIndex);

  console.log("PDF - dates with data generated");

  return shifts.map((shiftsInside) => [
    ...beforeFix,
    ..._.slice(shiftsInside, saveStartIndex, saveEndIndex),
    ...afterFix,
  ]);
}

function getNeededTitles(lines) {
  return lines.map((l) => l.title);
}

function initialValues() {
  return {
    [varNames.startWeek]: "",
    [varNames.weekDuration]: "",
  };
}

function validationSchema() {
  const schema = {
    [varNames.startWeek]: Yup.number()
      .typeError(cz.validation.number)
      .required(cz.validation.required),
    [varNames.weekDuration]: Yup.number()
      .typeError(cz.validation.number)
      .required(cz.validation.required),
  };

  return Yup.object().shape(schema);
}
