import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles, { style_measures } from "./pdfStyles";

const { shiftH } = style_measures;

export default function PDFWeeks({ weeks, settings }) {
  const { shiftsPerDay } = settings;

  return (
    <View style={styles.colUnderShiftColHeading}>
      {weeks.map((week, i) => (
        <View
          key={i}
          style={{
            height: `${shiftH * shiftsPerDay * (week.days || 7)}mm`,
            ...styles.week,
          }}
        >
          <Text>{week.week}</Text>
        </View>
      ))}
    </View>
  );
}
