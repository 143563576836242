import React from "react";
import { Link, NavLink } from "react-router-dom";
import appLogo from "../assets/logo-white.png";

import { cz } from "../language.json";
import measures from "../measures.json";

export default function NavBar({ user }) {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark custom-navbar">
      <div className="container-fluid">
        <Link className="navbar-brand p-0 m-0" to="/">
          <img
            src={appLogo}
            alt={cz.head.appName}
            height={measures.navbarLogoHeight}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse custom-navbar-collapse"
          id="navbarNav"
        >
          <ul className="navbar-nav ms-auto">
            {user && (
              <>
                <li className="nav-item">
                  <NavLink
                    className="nav-link custom-navLink"
                    to="/app/dashboard"
                  >
                    {cz.head.menu}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link custom-navLink"
                    to="/app/applications"
                  >
                    {cz.head.apps}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link custom-navLink"
                    to="/app/account"
                  >
                    {cz.head.account}
                  </NavLink>
                </li>
                {user.isAdmin && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link custom-navLink"
                      to="/app/admin"
                    >
                      {cz.head.admin}
                    </NavLink>
                  </li>
                )}
                <li className="nav-item">
                  <NavLink className="nav-link custom-navLink" to="/logout">
                    <i className="fa fa-power-off fa-lg" aria-hidden="true"></i>
                  </NavLink>
                </li>
              </>
            )}
            {!user && (
              <li className="nav-item">
                <NavLink className="nav-link custom-navLink" to="/login">
                  {cz.head.login}
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
