import { BlobProvider, pdf } from "@react-pdf/renderer";
import Icon from "../components/Icon";
import { cz } from "../language.json";
import varNames from "../var-names.json";

const pdfIcon = <i className={`fa ${varNames.pdfIcon}`} aria-hidden="true"></i>;

export function renderPdfButton(doc) {
  return (
    <BlobProvider document={doc}>
      {({ blob, url, loading }) =>
        loading ? (
          <Icon
            icon={pdfIcon}
            text={cz.general.loading}
            bootstrap_color="warning text-white"
          />
        ) : (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none text-white"
          >
            <Icon
              icon={pdfIcon}
              text={cz.general.pdf}
              bootstrap_color="warning text-white"
            />
          </a>
        )
      }
    </BlobProvider>
  );
}

export async function generatePdfOnClick(Document) {
  const pdfDoc = await pdf(Document);
  pdfDoc.updateContainer(Document);
  const result = await pdfDoc.toBlob();

  const url = URL.createObjectURL(result);
  window.open(url, "_blank");
}
