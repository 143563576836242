import React from "react";
import { Field } from "formik";
import ErrorMessage from "./ErrorMessage";

export default function TableFormInput({
  name,
  type = "number",
  step = "any",
}) {
  //pokud by někdo chtěl do inputu text, tak to změním tady a do Yup schématu nastavim čísla (už jsou tam, tak chill)
  return (
    <>
      <Field name={name} className="form-control" type={type} step={step} />
      <ErrorMessage name={name} />
    </>
  );
}
