import http from "./httpService";

const endPoint = "/api/applications";

export function getApplications() {
  return http.get(endPoint);
}

export function getApplication(id) {
  return http.get(`${endPoint}/${id}`);
}
