import React from "react";

export default function ClickableCard({ favIcon, name }) {
  return (
    <div className="d-flex flex-column justify-content-center align-self-center clickable-card-container">
      <div className="text-center" style={{ fontSize: "180%" }}>
        <i className={`${favIcon} fa-5x`} aria-hidden="true"></i>
      </div>
      <div className="clickable-card-text-cover">
        <h4 className="app-font-main text-uppercase fw-bold p-0 m-0">{name}</h4>
      </div>
    </div>
  );
}
