import React from "react";

export default function TabWrapper({ children, tab, onTabClose }) {
  //přidat že tam div najede ze strany
  return (
    <div className="h-100 min-hv-100-no-navbar bg-img-dark">
      <i
        className="x-close-btn cursor-pointer fa fa-times fa-3x"
        aria-hidden="true"
        onClick={() => onTabClose(tab.name)}
      ></i>
      {children}
    </div>
  );
}
