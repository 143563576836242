import React from "react";

export default function TableDetails({
  items,
  colSpan,
  unit,
  colSpanTitle,
  notBold,
}) {
  return (
    <tr>
      {colSpan && <td colSpan={colSpan}>{colSpanTitle}</td>}
      {items.map((x, i) => {
        let classes = "";

        if (i !== 3 && i !== items.length - 1 && !colSpan) classes = "fw-bold";
        if (notBold) classes = classes.replace("fw-bold", "");

        return (
          <td key={i} className={classes}>
            {x} {unit}
          </td>
        );
      })}
    </tr>
  );
}
