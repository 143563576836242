import React from "react";
import { Document, Page, View } from "@react-pdf/renderer";
import styles from "./pdfStyles";
import PDFShiftsColumn from "./PDFShiftsColumn";
import PDFWeeks from "./PDFWeeks";
import PDFDates from "./PDFDates";

import varNames from "./../../../../var-names.json";

export default function PDFoutputAdvanced({ data, shiftRows, settings }) {
  const { weeks, dates, dataDates, titles } = data;

  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
        orientation={settings[varNames.pdfLandscape] ? "landscape" : "portrait"}
      >
        <View style={styles.calCover}>
          <PDFWeeks weeks={weeks} settings={settings} />
          <PDFDates dates={dates} settings={settings} shiftRows={shiftRows} />
          <View style={styles.lineColsCover}>
            {dataDates.map((dd, i) => (
              <PDFShiftsColumn
                key={i}
                dataDates={dd}
                title={titles[i]}
                settings={settings}
              />
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
}
