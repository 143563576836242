import React, { useContext } from "react";
import * as Yup from "yup";
import TableForm from "../../forms/TableForm";
import Form from "../../forms/Form";
import Input from "../../forms/Input";
import SubmitButton from "../../forms/SubmitButton";
import LongDivisionContext from "./../../../context/longDivisionContext";

import varNames from "../../../var-names.json";
import measures from "../../../measures.json";
import { cz } from "../../../language.json";
import DataChangedWarning from "../../forms/DataChangedWarning";

export default function EditTab() {
  const { app, handlers } = useContext(LongDivisionContext);

  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-12 p-3 p-md-5 pt-5">
          <h1 className="text-center text-white app-font-main">
            {cz.general.edit}
          </h1>
          <div className="container container-white-trans">
            <Form
              initialValues={app[varNames.appData]}
              onSubmit={(values) => handlers.handleEditSave(values)}
              validationSchema={validationSchema}
            >
              <DataChangedWarning text={cz.general.dataChangedPlsSave} />
              <div className="row p-0 m-0">
                <div className="col-md-6 col-sm-12 p-2">
                  <h2 className="text-center app-font-main">
                    {cz.general.general}
                  </h2>
                  <Input
                    label={`${cz.general.rollWidth} [${cz.general.mm}]`}
                    name={varNames.rollWidth}
                  />
                  <Input
                    label={`${cz.general.rollThickness} [${cz.general.mm}]`}
                    name={varNames.rollThickness}
                  />
                  <Input
                    label={`${cz.general.minWaste} [${cz.general.mm}]`}
                    name={varNames.minWaste}
                  />
                  <Input
                    label={`${cz.general.maxWaste} [${cz.general.mm}]`}
                    name={varNames.maxWaste}
                  />
                </div>
                <div className="col-md-6 col-sm-12 p-2 d-flex flex-column align-items-center">
                  <div className="table-responsive w-100">
                    <TableForm
                      name={varNames.strips}
                      titles={[
                        `${cz.general.width} [${cz.general.mm}]`,
                        `${cz.general.weight} [${cz.general.kg}]`,
                      ]}
                      properties={{
                        [varNames.stripWidth]: "",
                        [varNames.neededWeight]: "",
                      }}
                      heading={cz.general.strips}
                    />
                  </div>
                  <div className="table-responsive w-100">
                    <TableForm
                      name={varNames.rolls}
                      titles={[
                        cz.general.serie,
                        `${cz.general.weight} [${cz.general.kg}]`,
                      ]}
                      properties={{
                        [varNames.serie]: "",
                        [varNames.rollWeight]: "",
                      }}
                      heading={cz.general.rolls}
                    />
                  </div>
                </div>
              </div>
              <SubmitButton text={cz.general.save} />
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export const validationSchema = Yup.object().shape({
  [varNames.strips]: Yup.array().of(
    Yup.object().shape({
      [varNames.stripWidth]: Yup.number()
        .typeError(cz.validation.number)
        .min(1, cz.validation.positive)
        .max(
          measures.maxFormValue,
          `${cz.validation.maximally} ${measures.maxFormValue}`
        )
        .required(cz.validation.required),
      [varNames.neededWeight]: Yup.number()
        .typeError(cz.validation.number)
        .min(1, cz.validation.positive)
        .max(
          measures.maxFormValue,
          `${cz.validation.maximally} ${measures.maxFormValue}`
        )
        .required(cz.validation.required),
    })
  ),
  [varNames.rolls]: Yup.array().of(
    Yup.object().shape({
      [varNames.serie]: Yup.number()
        .typeError(cz.validation.number)
        .min(1, cz.validation.positive)
        .max(
          measures.maxFormValue,
          `${cz.validation.maximally} ${measures.maxFormValue}`
        )
        .required(cz.validation.required),
      [varNames.rollWeight]: Yup.number()
        .typeError(cz.validation.number)
        .min(1, cz.validation.positive)
        .max(
          measures.maxFormValue,
          `${cz.validation.maximally} ${measures.maxFormValue}`
        )
        .required(cz.validation.required),
    })
  ),
  [varNames.rollWidth]: Yup.number()
    .typeError(cz.validation.number)
    .min(1, cz.validation.positive)
    .max(
      measures.maxFormValue,
      `${cz.validation.maximally} ${measures.maxFormValue}`
    ),
  [varNames.rollThickness]: Yup.number()
    .typeError(cz.validation.number)
    .min(1, cz.validation.positive)
    .max(
      measures.maxFormValue,
      `${cz.validation.maximally} ${measures.maxFormValue}`
    ),
  [varNames.minWaste]: Yup.number()
    .typeError(cz.validation.number)
    .min(0, cz.validation.positive)
    .max(
      measures.maxFormValue,
      `${cz.validation.maximally} ${measures.maxFormValue}`
    ),
  [varNames.maxWaste]: Yup.number()
    .typeError(cz.validation.number)
    .min(0, cz.validation.positive)
    .max(
      measures.maxFormValue,
      `${cz.validation.maximally} ${measures.maxFormValue}`
    )
    .moreThan(
      Yup.ref(varNames.minWaste),
      `${cz.validation.greaterThan} ${cz.general.minWaste}`
    ),
});
