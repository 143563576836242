import React from "react";
import TopScroller from "../../TopScroller";

import { cz } from "../../../language.json";
import measures from "../../../measures.json";
import varNames from "../../../var-names.json";

const leftButtonsContent = () => {
  return (
    <span>
      {cz.help.longDivision.leftBtns1}
      <br />
      {cz.help.longDivision.leftBtns2}
      <br />
      {cz.help.longDivision.leftBtns3}
      <br />
      {cz.help.longDivision.leftBtns4}
      <b>{cz.help.longDivision.warning}</b>
      {cz.help.longDivision.leftBtns5}
    </span>
  );
};

const rightButtonsContent = () => {
  const items = [
    {
      title: `${cz.general.start} - ${cz.help.longDivision.rightBtnsTitle1}`,
      text: cz.help.longDivision.rightBtnsText1,
    },
    {
      title: `${cz.general.pdf} - ${cz.help.longDivision.rightBtnsTitle2}`,
      text: cz.help.longDivision.rightBtnsText2,
    },
    {
      title: `${cz.general.wipe} - ${cz.help.longDivision.rightBtnsTitle3}`,
      text: cz.help.longDivision.rightBtnsText3,
    },
    {
      title: `${cz.general.reset} - ${cz.help.longDivision.rightBtnsTitle4}`,
      text: cz.help.longDivision.rightBtnsText4,
    },
    {
      title: `${cz.general.save.toUpperCase()} - ${
        cz.help.longDivision.rightBtnsTitle5
      }`,
      text: cz.help.longDivision.rightBtnsText5,
    },
  ];

  return (
    <>
      <p>{cz.help.longDivision.rightBtnsText}</p>
      <ul className="m-0">
        {items.map((item, i) => (
          <li key={i}>
            <b>{item.title}:</b> {item.text}
          </li>
        ))}
      </ul>
    </>
  );
};

const sumupContent = () => {
  return (
    <span>
      {cz.help.longDivision.sumup1}
      <br />
      {cz.help.longDivision.sumup2}
      <br />
      {cz.help.longDivision.sumup3}:
      <br />
      <b>{cz.general.strips}:</b> {cz.help.longDivision.sumup4}
      <br />
      <b>{cz.general.rolls}:</b> {cz.help.longDivision.sumup5}
      <br />
      {cz.help.longDivision.sumup6}{" "}
      <a href={measures.longDivInsertingVideo} target="_blank" rel="noreferrer">
        {cz.help.longDivision.sumup6_1}
      </a>
      .
      <br />
      <br />
      {cz.help.longDivision.sumupDashes}
      <br />
      {cz.help.longDivision.sumup7}:
      <br />
      {cz.help.longDivision.sumup8} <i>{cz.help.longDivision.sumup9}</i>
      <br />
      {cz.help.longDivision.sumup10} <i>{cz.help.longDivision.sumup11}</i>
      <br />
      <br />
      {cz.help.longDivision.sumup12}
      <br />
      {cz.help.longDivision.sumup13}
      <br />
      {cz.help.longDivision.sumup14}
      <br />
      {cz.help.longDivision.sumupDashes}
      <br />
      <br />
      {cz.help.longDivision.sumup15}
      <br />
      {cz.help.longDivision.sumup16}
      <br />
      {cz.help.longDivision.sumup17}
      <br />
      {cz.help.longDivision.sumup18}
    </span>
  );
};

const resultContent = () => {
  return (
    <span>
      {cz.help.longDivision.result1}
      <br />
      {cz.help.longDivision.result2}
      <br />
      {cz.help.longDivision.result3}
      <br />
      {cz.help.longDivision.result4}
      <b>{cz.help.longDivision.result5}</b>
      {cz.help.longDivision.result6}
      <br />
      {cz.help.longDivision.result7}
    </span>
  );
};

const editTabContent = () => (
  <span>
    {cz.help.longDivision.edit1}
    <i className={`fa ${varNames.editIcon}`}></i>
    {cz.help.longDivision.edit2}
    <br />
    {cz.help.longDivision.edit3}
    <b>{cz.help.longDivision.warning}</b>
    {cz.help.longDivision.edit4}
    <b>{cz.help.longDivision.edit5}</b>
    {cz.help.longDivision.edit6}
    <br />
    {cz.help.longDivision.edit7}
    <i>{cz.help.longDivision.edit8}</i>
    <br />
    {cz.help.longDivision.edit9}
    <br />
    {cz.help.longDivision.edit10}
    <b>{cz.help.longDivision.edit11}</b>
    {cz.help.longDivision.edit12}
    <br />
    {cz.help.longDivision.edit13}
    <br />
    {cz.help.longDivision.edit14}
    <b>{cz.help.longDivision.edit15}</b>
    {cz.help.longDivision.edit16}
    <i>{cz.help.longDivision.edit17}</i>
    <br />
    {cz.help.longDivision.edit18}
    <br />
    {cz.help.longDivision.edit19}
  </span>
);

const settingsTabContent = () => {
  const items = [
    {
      title: cz.general.stripsOverWeight,
      text: `${cz.help.longDivision.settingsText1_1} ${measures.minStripOverWeight}% - ${measures.maxStripOverWeight}%. ${cz.help.longDivision.settingsText1_2}`,
    },
    {
      title: cz.general.serverWork,
      text: cz.help.longDivision.settingsText2,
    },
  ];

  return (
    <span>
      {cz.help.longDivision.settings1}
      <i className={`fa ${varNames.settingsIcon}`}></i>
      {cz.help.longDivision.settings2}
      <br />
      <ul className="m-0">
        {items.map((item, i) => (
          <li key={i}>
            <b>{item.title}:</b> {item.text}
          </li>
        ))}
      </ul>
      <br />
      {cz.help.longDivision.settings3}
    </span>
  );
};

const historyTabContent = () => {
  return (
    <span>
      {cz.help.longDivision.history1}
      <i className={`fa ${varNames.historyIcon}`}></i>
      {cz.help.longDivision.history2}
      <br />
      {cz.help.longDivision.history3}
      <br />
      {cz.help.longDivision.history4}
      <br />
      {cz.help.longDivision.history5}
    </span>
  );
};

const savedTabContent = () => {
  return (
    <span>
      {cz.help.longDivision.saved1}
      <i className={`fa ${varNames.saveIcon}`}></i>
      {cz.help.longDivision.saved2}
      <br />
      {cz.help.longDivision.saved3}
      <br />
      {cz.help.longDivision.saved4}
      <br />
      {cz.help.longDivision.saved5}
      <br />
      {cz.help.longDivision.saved6}
    </span>
  );
};

const howItWorks = () => {
  const items = [
    cz.help.longDivision.runText1,
    cz.help.longDivision.runText2,
    cz.help.longDivision.runText3,
    cz.help.longDivision.runText4,
    cz.help.longDivision.runText5,
    cz.help.longDivision.runText6,
  ];

  return (
    <span>
      {cz.help.longDivision.run1}
      <ol className="m-0">
        {items.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ol>
      {cz.help.longDivision.run2}
      <br />
      {cz.help.longDivision.run3}
      <br />
      {cz.help.longDivision.run4}
      <ul className="m-0">
        <li>{cz.help.longDivision.run5}</li>
        <li>{cz.help.longDivision.run6}</li>
      </ul>
      {cz.help.longDivision.run7}
    </span>
  );
};

const tipsNtricks = () => {
  const items = [
    {
      title: cz.help.longDivision.tipsTitle1,
      text: cz.help.longDivision.tipsText1,
    },
    {
      title: cz.help.longDivision.tipsTitle2,
      text: cz.help.longDivision.tipsText2,
    },
    {
      title: cz.help.longDivision.tipsTitle3,
      text: cz.help.longDivision.tipsText3,
    },
  ];

  return (
    <>
      <p>{cz.help.longDivision.tips1}</p>
      <ul className="m-0">
        {items.map((item, i) => (
          <li key={i}>
            <b>{item.title}:</b> {item.text}
          </li>
        ))}
      </ul>
    </>
  );
};

const content = [
  {
    title: cz.help.longDivision.content1,
    href: "left-btns",
    content: leftButtonsContent(),
  },
  {
    title: cz.help.longDivision.content2,
    href: "right-btns",
    content: rightButtonsContent(),
  },
  {
    title: cz.help.longDivision.content3,
    href: "sumupTab",
    content: sumupContent(),
  },
  {
    title: cz.help.longDivision.content4,
    href: "resultTab",
    content: resultContent(),
  },
  {
    title: cz.help.longDivision.content5,
    href: "editTab",
    content: editTabContent(),
  },
  {
    title: cz.help.longDivision.content6,
    href: "settingsTab",
    content: settingsTabContent(),
  },
  {
    title: cz.help.longDivision.content7,
    href: "historyTab",
    content: historyTabContent(),
  },
  {
    title: cz.help.longDivision.content8,
    href: "saveTab",
    content: savedTabContent(),
  },
  {
    title: cz.help.longDivision.content9,
    href: "howRunWorks",
    content: howItWorks(),
  },
  {
    title: cz.help.longDivision.content10,
    href: "tipsNtricks",
    content: tipsNtricks(),
  },
];

const renderMenu = () => (
  <div className="help-content-section">
    <h3 className="help-content-heading">{cz.help.longDivision.content}</h3>
    <ul className="m-0">
      {content.map((c, i) => (
        <a
          href={`#${c.href}`}
          key={i}
          className="text-dark text-decoration-none app-font-secondary"
        >
          <li>{c.title}</li>
        </a>
      ))}
    </ul>
  </div>
);

export default function HelpTab() {
  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-12 p-3 p-md-5 pt-5">
          <h1 className="text-center text-white app-font-main">
            {cz.general.help}
          </h1>
          <div className="container container-white-trans app-font-secondary">
            <p className="text-center">
              <i>
                {cz.help.longDivision.contentText1}
                <b>{measures.contactEmail}</b>
              </i>
            </p>
            {renderMenu()}
            {content.map((section, i) => (
              <div key={i} className="help-content-section" id={section.href}>
                <h3 className="help-content-heading">{section.title}</h3>
                {section.content}
              </div>
            ))}
            <p className="text-center p-0 m-0 app-font-thirdary">
              {cz.help.longDivision.contentText2}
            </p>
          </div>
        </div>
      </div>

      <TopScroller />
    </>
  );
}
