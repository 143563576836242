import React from "react";

export default function Table({ children, classes }) {
  return (
    <div className={`table-responsive ${classes}`}>
      <table className="table table-bordered table-nonfluid text-center">
        {children}
      </table>
    </div>
  );
}
