import React, { useEffect, useState } from "react";

export default function TopScroller() {
  useEffect(() => {
    window.addEventListener("scroll", toggleScrollBtn);

    return () => {
      window.removeEventListener("scroll", toggleScrollBtn);
    };
  }, []);

  const [scrollBtn, setScrollBtn] = useState(false);

  const toggleScrollBtn = () => {
    const scrolled_1 = document.documentElement.scrollTop;
    const scrolled_2 = document.body.scrollTop;

    const navbarHeight = 80;

    if (scrolled_1 > navbarHeight || scrolled_2 > navbarHeight) {
      setScrollBtn(true);
    } else {
      setScrollBtn(false);
    }
  };

  if (!scrollBtn) return null;

  return (
    <a href={`${window.location.pathname}#`} className="move-top-button">
      <i className="fa fa-chevron-up" aria-hidden="true"></i>
    </a>
  );
}
