import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles, { style_measures } from "./pdfStyles";
import varNames from "./../../../../var-names.json";

const { shiftW } = style_measures;

export default function PDFShiftsColumn({ dataDates, title, settings }) {
  const { pdfAdditionalCols } = settings;
  return (
    <View>
      <View
        style={{
          ...styles.lineTitle,
          width: `${pdfAdditionalCols ? shiftW * 2 : shiftW}mm`,
        }}
      >
        <Text>{title}</Text>
      </View>
      <View>
        {dataDates.map((shift, i) => {
          const isBlock = shift && shift[varNames.isBlock];
          const isDone = shift && shift[varNames.isDone];

          return (
            <View key={i} style={styles.lineCols}>
              <View
                style={{
                  ...styles.shiftDay,
                  backgroundColor: getBgColor(isBlock, isDone),
                }}
              >
                <Text>{shift && !isBlock ? shift[varNames.title] : ""}</Text>
              </View>
              {pdfAdditionalCols && <View style={styles.shiftDay} />}
            </View>
          );
        })}
      </View>
    </View>
  );
}

function getBgColor(isBlock, isDone) {
  if (isBlock) return "#ebebeb";
  if (isDone) return "#FF00004D";

  return "";
}
