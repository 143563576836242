import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ClickableCard from "./../ClickableCard";
import UserContext from "./../../context/userContext";
import AppsContext from "./../../context/appsContext";
import moment from "moment";

import { cz } from "../../language.json";
import varNames from "../../var-names.json";

export default function Dashboard() {
  const { user } = useContext(UserContext);
  const apps = useContext(AppsContext);

  const renderUserApps = () => {
    const userApps = user[varNames.userApps];

    return apps.map((app, i) => {
      const paidApp = userApps.find(
        (ua) => ua[varNames._id] === app[varNames._id]
      );

      let paidUntil;

      if (paidApp) {
        const diff = Math.ceil(
          moment
            .duration(moment(paidApp[varNames.appPaidUntil]).diff(new Date()))
            .asDays()
        );

        if (diff >= 0) paidUntil = `${diff} ${cz.general.days}`;
        else paidUntil = cz.general.periodFinished;
      }

      if (!paidUntil) return null;

      return (
        <li key={i}>
          {cz.general.prepaidApp}{" "}
          <span className="fw-bold">{app[varNames.appName]}</span>{" "}
          {cz.general.finishedIn} {paidUntil ? paidUntil : cz.general.notBought}
        </li>
      );
    });
  };

  return (
    <div className="app-font-secondary">
      <h1 className="text-center app-font-main text-uppercase fw-bold my-3">
        {cz.general.mainMenu}
      </h1>
      <div className="container">
        <div className="row p-0 m-0">
          <div className="col-md-6 col-sm-12 mb-5 d-flex justify-content-center">
            <Link
              to="/app/applications"
              className="text-dark text-decoration-none"
            >
              <ClickableCard
                favIcon={"fa fa-window-restore"}
                name={cz.head.apps}
              />
            </Link>
          </div>
          <div className="col-md-6 col-sm-12 mb-5 d-flex justify-content-center">
            <Link to="/app/account" className="text-dark text-decoration-none">
              <ClickableCard
                favIcon={"fa fa-user-circle"}
                name={cz.head.account}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="p-0 m-0">
        <h2 className="text-center app-font-main text-uppercase fw-bold my-3">
          {cz.general.importantInfo}
        </h2>
        <div className="container container-gray-trans">
          <ul>{renderUserApps()}</ul>
        </div>
      </div>
      <div className="p-0 m-0">
        <h2 className="text-center app-font-main text-uppercase fw-bold my-3">
          {cz.general.news}
        </h2>
        <div className="container container-gray-trans text-center">
          {cz.general.noNews}
        </div>
      </div>
    </div>
  );
}
