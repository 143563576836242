import app2pic0 from "./app2-0.jpg";
import app2pic1 from "./app2-1.jpg";
import app2pic2 from "./app2-2.jpg";
import app2pic3 from "./app2-3.jpg";
import app2pic4 from "./app2-4.jpg";
import app2pic5 from "./app2-5.jpg";
import app2pic6 from "./app2-6.jpg";
import app2pic7 from "./app2-7.jpg";
import app2pic8 from "./app2-8.jpg";
import app2pic9 from "./app2-9.jpg";
import app2pic10 from "./app2-10.jpg";
import app2pic11 from "./app2-11.jpg";
import app2pic12 from "./app2-12.jpg";
import app2pic13 from "./app2-13.jpg";
import app2pic14 from "./app2-14.jpg";
import app2pic15 from "./app2-15.jpg";
import app2pic16 from "./app2-16.jpg";
import app2pic17 from "./app2-17.jpg";
import app2pic18 from "./app2-18.jpg";
import app2pic19 from "./app2-19.jpg";
import app2pic20 from "./app2-20.jpg";
import app2pic21 from "./app2-21.jpg";
import app2pic22 from "./app2-22.jpg";
import app2pic23 from "./app2-23.jpg";
import app2pic24 from "./app2-24.jpg";
import app2pic25 from "./app2-25.jpg";

const images = {
  app2pic0,
  app2pic1,
  app2pic2,
  app2pic3,
  app2pic4,
  app2pic5,
  app2pic6,
  app2pic7,
  app2pic8,
  app2pic9,
  app2pic10,
  app2pic11,
  app2pic12,
  app2pic13,
  app2pic14,
  app2pic15,
  app2pic16,
  app2pic17,
  app2pic18,
  app2pic19,
  app2pic20,
  app2pic21,
  app2pic22,
  app2pic23,
  app2pic24,
  app2pic25,
};
export default images;
