import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

function init() {
  Sentry.init({
    dsn: "https://ee64028da4a6430a8472338b95bee0c1@o980789.ingest.sentry.io/5935322",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

function logError(error) {
  Sentry.captureException(error);
}

function logActivity(activity, userId) {
  Sentry.setTag("userId", userId);
  Sentry.captureMessage(activity);
}

const logger = {
  init,
  logError,
  logActivity,
};

export default logger;
