import React from "react";
import { ChromePicker } from "react-color";
import { useFormikContext } from "formik";
import ErrorMessage from "./ErrorMessage";

export default function ColorPicker({ label, name, readOnly }) {
  const { handleChange, values } = useFormikContext();

  const handleChangeRaw = (color) => {
    handleChange({ target: { value: color.hex, name } });
  };

  return (
    <div className="mb-3">
      <div className="form-group row p-0 m-0 container">
        {label && (
          <div className="col-md-3 col-sm-12 d-flex align-items-center">
            <label htmlFor={name} className="form-label m-0 fw-bold">
              {label}
            </label>
          </div>
        )}
        <div className={`col-md-${label ? "9" : "12"} col-sm-12`}>
          <div
            className="rounded border"
            style={{
              backgroundColor: values[name],
              width: "100%",
              height: "40px",
            }}
          ></div>
          {!readOnly && (
            <div className="my-3 d-flex justify-content-center align-items-center">
              <ChromePicker
                name={name}
                color={values[name]}
                onChange={handleChangeRaw}
                disableAlpha
              />
            </div>
          )}
        </div>
      </div>
      <div className="row container">
        <div className="col-12">
          <ErrorMessage name={name} />
        </div>
      </div>
    </div>
  );
}
