import React, { useMemo } from "react";
import * as Yup from "yup";
import Form from "./../../forms/Form";
import LeftInput from "./../../forms/LeftInput";
import SubmitButton from "./../../forms/SubmitButton";
import LeftSelect from "./../../forms/LeftSelect";
import DatePicker from "./../../forms/DatePicker";
import { generateObjId } from "./../../../utils";

import { cz } from "./../../../language.json";
import varNames from "./../../../var-names.json";
import measures from "./../../../measures.json";

const actionTypes = [{ _id: varNames.actionDelete, name: cz.general.delete }];

const data = [
  { title: cz.general.note, name: varNames.note, type: "text" },
  { title: cz.general.numberOfShifts, name: varNames.shifts, type: "number" },
];

export default function BlockedEventForm({
  modal,
  setModal,
  onSubmit,
  readOnly,
  isNew,
  lines,
  shiftsPerDay,
  startDate,
  endDate,
}) {
  const shiftsDropdown = useMemo(() => getShfSel(shiftsPerDay), [shiftsPerDay]);

  return (
    <Form
      initialValues={initialValues(modal, isNew)}
      onSubmit={(values) =>
        onSubmitHandle(values, modal, isNew, onSubmit, setModal)
      }
      validationSchema={validationSchema(
        isNew,
        shiftsPerDay,
        startDate.toDate(),
        endDate.toDate()
      )}
    >
      {isNew && (
        <LeftSelect
          label={cz.general.line}
          name={varNames.lineId}
          items={linesWithAllOptions(lines)}
        />
      )}

      {data.map((item, i) => (
        <LeftInput
          key={i}
          name={item.name}
          type={item.type}
          readOnly={readOnly}
          label={item.title}
        />
      ))}

      <DatePicker
        label={cz.general.startDate}
        readOnly={readOnly}
        name={varNames.startDate}
        minDate={startDate.toDate()}
        maxDate={endDate.toDate()}
      />
      <LeftSelect
        label={cz.general.startShift}
        name={varNames.startShift}
        items={shiftsDropdown}
        readOnly={readOnly}
      />

      {!readOnly && !isNew && (
        <LeftSelect
          label={cz.general.action}
          name={varNames.action}
          items={actionTypes}
        />
      )}
      {!readOnly && <SubmitButton text={cz.general.save} />}
    </Form>
  );
}

const validationSchema = (isNew, shiftsPerDay, minDate, maxDate) => {
  const schema = {
    [varNames._id]: Yup.string(cz.validation.string).required(
      cz.validation.required
    ),
    [varNames.note]: Yup.string(cz.validation.string).max(
      measures.maxEventNote,
      `${cz.validation.maximally} ${measures.maxEventNote} ${cz.validation.chars}`
    ),
    [varNames.shifts]: Yup.number()
      .typeError(cz.validation.number)
      .min(
        measures.minShifts,
        `${cz.validation.minimally} ${measures.minShifts}`
      )
      .max(
        measures.maxShifts,
        `${cz.validation.maximally} ${measures.maxShifts}`
      )
      .required(cz.validation.required),
    [varNames.startDate]: Yup.date(cz.validation.date)
      .min(minDate, `${cz.validation.minimally} ${minDate}`)
      .max(maxDate, `${cz.validation.maximally} ${maxDate}`)
      .required(cz.validation.required),
    [varNames.startShift]: Yup.number()
      .typeError(cz.validation.number)
      .min(1, `${cz.validation.minimally} 1`)
      .max(shiftsPerDay, `${cz.validation.maximally} ${shiftsPerDay}`)
      .required(cz.validation.required),
  };

  if (isNew)
    schema[varNames.lineId] = Yup.string(cz.validation.string).required(
      cz.validation.required
    );

  return Yup.object().shape(schema);
};

const initialValues = (modal, isNew) => {
  if (isNew) {
    return {
      [varNames._id]: generateObjId(),
      [varNames.lineId]: "",
      [varNames.note]: "",
      [varNames.shifts]: "",
      [varNames.startDate]: "",
      [varNames.startShift]: "",
    };
  } else {
    return {
      [varNames._id]: modal.shift[varNames._id],
      [varNames.note]: modal.shift[varNames.note],
      [varNames.shifts]: modal.shift[varNames.shifts],
      [varNames.startDate]: modal.shift.from[varNames.date],
      [varNames.startShift]: modal.shift.from[varNames.shift],
      [varNames.action]: "",
    };
  }
};

const onSubmitHandle = (values, modal, isNew, onSubmit, setModal) => {
  const shift = {
    [varNames._id]: values[varNames._id],
    from: {
      [varNames.date]: values[varNames.startDate],
      [varNames.shift]: Math.round(values[varNames.startShift]),
    },
    [varNames.shifts]: Math.round(values[varNames.shifts]),
    [varNames.isBlock]: true,
    [varNames.note]: values[varNames.note],
  };

  if (isNew) return onSubmit(shift, values.lineId, true, setModal);
  else
    return onSubmit(
      modal.line[varNames._id],
      shift,
      true,
      values[varNames.action],
      undefined,
      setModal
    );
};

function getShfSel(shiftsPerDay) {
  const data = [];

  for (let i = 1; i <= shiftsPerDay; i++) {
    data.push({ [varNames._id]: i, name: i });
  }

  return data;
}

function linesWithAllOptions(lines) {
  if (!lines) return;

  const copy = [...lines];
  copy.unshift({ [varNames._id]: varNames.actionAll, name: cz.general.all });

  return copy;
}
