import React from "react";
import { useFormikContext } from "formik";

export default function DataChangedWarning({ text }) {
  const { dirty } = useFormikContext();

  if (!dirty) return null;

  return (
    <div className="text-center bg-danger text-white app-font-main fw-bold display-6 rounded">
      {text}
    </div>
  );
}
