import React, { useState } from "react";

import { cz } from "../../../../language.json";

export default function ShowSRStuff({ tables }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <i
        className="fa fa-eye cursor-pointer"
        aria-hidden="true"
        onClick={() => setShow(true)}
      ></i>
      <div className={`app-modal ${show ? "app-modal-display" : ""}`}>
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{cz.general.findOut}</h5>
              <button
                className="btn-close"
                onClick={() => setShow(false)}
              ></button>
            </div>
            <div className="modal-body">
              {tables.map((t, i) => (
                <div key={i}>{t}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
