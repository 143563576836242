import { useState } from "react";

const useApi = (apiFunc) => {
  const [data, setData] = useState([]);
  const [running, setRunning] = useState(false);
  const [error, setError] = useState(false);

  /**
   * Reprezentuje volání api.
   * @param {Array} args - pole argumentů do apiCallu
   * @param {Function} onFulfil - metoda, co se volá když program doběhne
   * @param {Array} fulfilArgs - pole argumentů do callBacku
   */
  const request = async (args, onFulfil, fulfilArgs = []) => {
    setRunning(true);
    const response = await apiFunc(...args);
    setRunning(false);

    if (!response.ok) return setError(response.problem);

    setError(false);
    setData(response.data);
    if (onFulfil) onFulfil(response.data, ...fulfilArgs);
  };

  const resetData = () => {
    setData([]);
  };

  const resetError = () => {
    setError(false);
  };

  return {
    data,
    running,
    error,
    request,
    resetData,
    resetError,
    setData,
  };
};

export default useApi;
