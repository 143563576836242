import React from "react";
import { generatePdfOnClick } from "../../../hooks/usePdf";
import LineDivider from "./../../LineDivider";
import PDFoutput from "./Output/PDFoutput";
import PdfConfig from "./PdfConfig";

import { cz } from "./../../../language.json";

export default function PdfModal({
  current,
  lines,
  dates,
  weeks,
  shiftRows,
  settings,
  shifts,
}) {
  return (
    <div className="d-flex flex-column justify-content-center aling-items-center">
      <button
        className="btn btn-warning slider-width-reduction"
        onClick={() =>
          generatePdfOnClick(
            <PDFoutput
              current={current}
              lines={lines}
              dates={dates}
              weeks={weeks}
              shiftRows={shiftRows}
              settings={settings}
              shifts={shifts}
            />
          )
        }
      >
        {cz.general.wholeMonth}
      </button>
      <LineDivider color="black" />
      <PdfConfig
        current={current}
        lines={lines}
        shiftRows={shiftRows}
        settings={settings}
        shifts={shifts}
      />
    </div>
  );
}
