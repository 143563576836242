import React from "react";
import Viewer from "react-viewer";

export default function ImageViewer({ images = [], isOpen, onClose }) {
  return (
    <>
      {isOpen && (
        <div
          className="home-our-apps-viewer-container"
          id="viewer-container-div"
        />
      )}
      <Viewer
        visible={isOpen}
        onClose={onClose}
        drag={false}
        rotatable={false}
        scalable={false}
        noImgDetails={true}
        showTotal={false}
        disableMouseZoom={true}
        images={images}
        container={document.getElementById("viewer-container-div")}
      />
    </>
  );
}
