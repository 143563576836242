import React from "react";
import { useFormikContext } from "formik";
import ErrorMessage from "./ErrorMessage";

function LeftSelect({ name, label, items, readOnly }) {
  const { handleBlur, handleChange, values } = useFormikContext();

  return (
    <div className="mb-3">
      <div className="form-group row p-0 m-0 container">
        {label && (
          <div className="col-md-3 col-sm-12 d-flex align-items-center">
            <label htmlFor={name} className="form-label m-0 fw-bold">
              {label}
            </label>
          </div>
        )}
        <div className={`col-md-${label ? "9" : "12"} col-sm-12`}>
          <select
            id={name}
            name={name}
            aria-describedby={`${name}Help`}
            className="form-select"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[name]}
          >
            <option value="" disabled></option>
            {items.map((item) => (
              <option key={item._id} value={item._id} disabled={readOnly}>
                {item.name}
              </option>
            ))}
          </select>
          <ErrorMessage name={name} />
        </div>
      </div>
    </div>
  );
}

export default LeftSelect;
