import React, { useMemo } from "react";
import _ from "lodash";

import varNames from "../../../var-names.json";
import measures from "../../../measures.json";
import PDFShiftsColumn from "./Output/PDFShiftsColumn";

export default function ShiftsColumn({
  current,
  line,
  settings,
  handlers,
  shifts,
  forPDF,
}) {
  const { shiftsPerDay, startDate } = settings;
  const { setModal } = handlers;

  const dataDates = useMemo(
    () => getDatesWithData(current, shifts, shiftsPerDay, startDate),
    [current, shifts, shiftsPerDay, startDate]
  );

  const handleOnShiftClick = (shift) => {
    if (!shift) return;
    setModal({ shift, line });
  };

  if (forPDF)
    return (
      <PDFShiftsColumn
        dataDates={dataDates}
        title={line[varNames.title]}
        settings={settings}
      />
    );

  return (
    <div>
      <div className="shift-col-heading">{line[varNames.title]}</div>

      {dataDates.map((shift, i) => (
        <div
          key={i}
          className={`shift-day ${getShiftStyle(shift)}`}
          style={{
            height: `${measures.shiftHeight}px`,
            backgroundColor:
              shift && shift[varNames.color] ? shift[varNames.color] : "",
            borderBottom:
              (i + 1) % shiftsPerDay === 0
                ? "1px solid #adabab"
                : "1px solid #ffe8e84d",
            borderTop:
              (i + 1) % shiftsPerDay === 0
                ? "1px solid #ffe8e84d"
                : "1px solid #adabab",
          }}
          onClick={() => handleOnShiftClick(shift)}
        >
          {shift ? (shift[varNames.isBlock] ? "" : shift[varNames.title]) : ""}
        </div>
      ))}
    </div>
  );
}

function getShiftStyle(shift) {
  if (!shift) return "empty";

  if (shift[varNames.isBlock]) return "blockedEvent";

  if (shift[varNames.isDone]) return "eventDone";

  return "event";
}

function getDatesWithData(current, shifts, shiftsPerDay, startDate) {
  const startDay = current.clone().startOf("month");
  const startIndex = startDay.diff(startDate, "days") * shiftsPerDay;
  // console.log("startIndex", startIndex);

  const endDay = current.clone().endOf("month");
  const endIndex = endDay.diff(startDate, "days") * shiftsPerDay;
  // console.log("endIndex", endIndex);

  console.log("dates with data generated");

  return _.slice(shifts, startIndex, endIndex + shiftsPerDay);
}
