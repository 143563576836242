import app1pic1 from "./app1-1.jpg";
import app1pic2 from "./app1-2.jpg";
import app1pic3 from "./app1-3.jpg";
import app1pic4 from "./app1-4.jpg";
import app1pic5 from "./app1-5.jpg";
import app1pic6 from "./app1-6.jpg";
import app1pic7 from "./app1-7.jpg";
import app1pic8 from "./app1-8.jpg";
import app1pic9 from "./app1-9.jpg";
import app1pic10 from "./app1-10.jpg";
import app1pic11 from "./app1-11.gif";

const images = {
  app1pic1,
  app1pic2,
  app1pic3,
  app1pic4,
  app1pic5,
  app1pic6,
  app1pic7,
  app1pic8,
  app1pic9,
  app1pic10,
  app1pic11,
};
export default images;
