import React from "react";
import DatePickerReact from "react-date-picker";
import ErrorMessage from "./ErrorMessage";
import { useFormikContext } from "formik";
import moment from "moment";

export default function DatePicker({
  label,
  name,
  readOnly,
  minDate,
  maxDate,
}) {
  const { handleChange, values } = useFormikContext();

  const handleChangeRaw = (value) => {
    handleChange({ target: { value, name } });
  };

  return (
    <div className="mb-3">
      <div className="form-group row p-0 m-0 container">
        {label && (
          <div className="col-md-3 col-sm-12 d-flex align-items-center">
            <label htmlFor={name} className="form-label m-0 fw-bold">
              {label}
            </label>
          </div>
        )}
        <div className={`col-md-${label ? "9" : "12"} col-sm-12`}>
          {" "}
          <DatePickerReact
            className="form-control"
            onChange={handleChangeRaw}
            value={getValue(values[name])}
            required
            disabled={readOnly}
            minDate={minDate}
            maxDate={maxDate}
          />
          <ErrorMessage name={name} />
        </div>
      </div>
    </div>
  );
}

// aby byl datum prázdný, musí tam být null
function getValue(date) {
  if (!date) return null;
  else return moment(date).toDate();
}
