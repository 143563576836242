import { create } from "apisauce";
import { getJwt } from "./authService";

const api = create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.addAsyncRequestTransform(async (request) => {
  const authToken = getJwt();
  if (!authToken) return;

  request.headers["x-auth-token"] = authToken;
});

export default api;
