import React from "react";

import varNames from "../../../var-names.json";

const editIcon = (
  <i className={`fa ${varNames.editIconP}`} aria-hidden="true"></i>
);
const watchIcon = (
  <i className={`fa ${varNames.eyeIcon}`} aria-hidden="true"></i>
);

export default function ConnectedUsers({ users }) {
  return (
    <div className="d-flex justify-content-center">
      <ul className="connected-users">
        {users.map((user) => (
          <li key={user.id}>
            {user.edit ? editIcon : watchIcon} {user.username}
          </li>
        ))}
      </ul>
    </div>
  );
}
