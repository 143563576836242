import React, { useState } from "react";
import ImageViewer from "../ImageViewer";
import TopScroller from "./../TopScroller";

import logo from "../../assets/logo-white.png";
import app2images from "./../../assets/app2";
import app1images from "./../../assets/app1";

const info_boxes = [
  {
    icon: "fa-clock-o",
    heading: "Kdykoliv",
    text: "Všechny aplikace jsou kromě pravidelné údržby (v nočních hodinách) dostupné 24/7. Od uživatele není potřeba je zapínat nebo vypínat.",
  },
  {
    icon: "fa-globe",
    heading: "Odkudkoliv",
    text: "Možnost připojení se do uživatelského účtu a použití aplikací je odkudkoliv na světě. Stačí mít pouze dostatečné připojení k internetu.",
  },
  {
    icon: "fa-mobile",
    heading: "Jakkoliv",
    text: "Veškeré uživatelské rozhraní je uzpůsobeno pro použití na všech zařízeních. Kromě PC tedy například i na tabletu či telefonu.",
  },
];

const our_apps = [
  {
    title: "Podélné dělení",
    version: "1.2.1",
    pics: [
      {
        src: app1images.app1pic1,
        alt: "Hlavní okno aplikace - sekce Shrnutí. Zde uživatel vidí veškeré zadané údaje",
      },
      {
        src: app1images.app1pic2,
        alt: "Záložka Zadání parametrů. Zde může uživatel editovat parametry výpočtu a ručně zadávat svitky/pásky",
      },
      {
        src: app1images.app1pic3,
        alt: "Hlavní okno aplikace s vyplněnými daty",
      },
      {
        src: app1images.app1pic4,
        alt: "Vkládání svitků kopírováním. Stejná možnost je i pro vkládání pásek",
      },
      {
        src: app1images.app1pic5,
        alt: "Hlavní okno aplikace s vyplněnými daty (po vložení svitků skrze kopírování - viz. předchozí obrázek)",
      },
      {
        src: app1images.app1pic6,
        alt: "Záložka Nastavení. Zde uživatel edituje obecné nastavení pro všechny výpočty",
      },
      {
        src: app1images.app1pic7,
        alt: "Záložka Historie. Zde má uživatel možnost vrátit se k předchozím výpočtům",
      },
      {
        src: app1images.app1pic8,
        alt: 'Zobrazení dat daného výpočtu v záložce historie (po kliknutí na ikonu oka ve sloupci "Zobrazit")',
      },
      {
        src: app1images.app1pic9,
        alt: "Záložka Uloženo. Zde má uživatel možnost procházet data, která si předtím uložil",
      },
      {
        src: app1images.app1pic10,
        alt: "Tabulka výsledku po skončení výpočtu",
      },
      {
        src: app1images.app1pic11,
        alt: "Krátké, opakující-se video (40s), které ukazuje vkládání pásek/svitků z Excelu, vyplňování dat a spouštění výpočtu",
      },
    ],
    text: getLongDivisionText(),
  },
  {
    title: "Plánování výroby",
    version: "1.4.1",
    pics: [
      {
        src: app2images.app2pic0,
        alt: "Hlavní stránka. Nahoře je měsíc/rok, tlačítka na posun měsíce a tlačítko DNES, které uživatele vrátí na aktuální měsíc. Pod nimi jsou aktuálně připojení uživatelé. Dále jsou vidět linky. Blokované směny jsou šrafované, dokončené výrobky jsou přeškrtnuté a ostatní výrobky mají přiřazenou barvu.",
      },
      {
        src: app2images.app2pic1,
        alt: "Blokované směny jsou šrafované, dokončené výrobky jsou přeškrtnuté a ostatní výrobky mají přiřazenou barvu.",
      },
      {
        src: app2images.app2pic2,
        alt: "Správa linek, možnost vložit novou linku, změnit název současných nebo linku celou odstranit (lze vypnout)",
      },
      {
        src: app2images.app2pic3,
        alt: "Náhled po kliknutí na blokovanou směnu v hlavním kalendáři v režimu ČTENÍ",
      },
      {
        src: app2images.app2pic4,
        alt: "Náhled po kliknutí na výrobek v hlavním kalendáři v režimu ČTENÍ",
      },
      {
        src: app2images.app2pic5,
        alt: "Náhled po kliknutí na výrobek v hlavním kalendáři v režimu ČTENÍ",
      },
      {
        src: app2images.app2pic6,
        alt: "Zapnutí režimu EDITACE (po kliknutí na tužku v pravé části stránky)",
      },
      {
        src: app2images.app2pic7,
        alt: "Přidání výrobku",
      },
      {
        src: app2images.app2pic8,
        alt: "Volba linek při přidávání výrobku",
      },
      {
        src: app2images.app2pic9,
        alt: "Vyplněné přidání výrobku (všechny data jsou validována!)",
      },
      {
        src: app2images.app2pic10,
        alt: "Výrobek z předchozího obrázku (nalevo) z názvem TEST se přidal na 3. linku",
      },
      {
        src: app2images.app2pic11,
        alt: "Přidání blokace",
      },
      {
        src: app2images.app2pic12,
        alt: "Volba linek při přidávání blokace - je zde navíc možnost přidat blokaci na VŠECHNY linky",
      },
      {
        src: app2images.app2pic13,
        alt: "Vyplněné přidání blokace (všechny data jsou validována!)",
      },
      {
        src: app2images.app2pic14,
        alt: "Blokace z předchozího obrázku (nalevo) byla přidána na 14.9. od první směny na dobu 3 směn. Všechny výrobky se posunuly.",
      },
      {
        src: app2images.app2pic15,
        alt: "Editace výrobku - lze provádět úpravy dat a také akce",
      },
      {
        src: app2images.app2pic16,
        alt: "K některým akcím, jako např. posunutí výrobku je potřeba přidávat další parametry",
      },
      {
        src: app2images.app2pic17,
        alt: "Editace blokace - lze provádět úpravy dat a také akce",
      },
      {
        src: app2images.app2pic18,
        alt: "Okno, které se zobrazí po kliknutí na ikonku PDF v pravé části obrazovky",
      },
      {
        src: app2images.app2pic19,
        alt: "Vygenerované PDF pro CELÝ MĚSÍC na šířku s dodatečnýma sloupcema pro ruční poznámky. Víkendy - zeleně, blokace - šedě, hotové - červeně",
      },
      {
        src: app2images.app2pic20,
        alt: "Vygenerované PDF pro CELÝ MĚSÍC na výšku bez dodatečných sloupců pro ruční poznámky. Víkendy - zeleně, blokace - šedě, hotové - červeně",
      },
      {
        src: app2images.app2pic21,
        alt: "Vybírání specifického týdne pro tisk PDF (vždy se zobrazují týdny v aktuálním měsíci)",
      },
      {
        src: app2images.app2pic22,
        alt: "Vybírání počtu týdnů, které se mají vytisknout do PDF",
      },
      {
        src: app2images.app2pic23,
        alt: "Po kliknutí na GENEROVAT se vytvoří PDF ze zadaných týdnů",
      },
      {
        src: app2images.app2pic24,
        alt: "PDF vytvořené pouze ze zadaných týdnů. Doplní se i údaje z jiných měsíců, pokud do nich týden zasahuje!",
      },
      {
        src: app2images.app2pic25,
        alt: "Vyhledávání výrobků",
      },
    ],
    text: getProdPlannerText(),
  },
];

const contacts = [
  {
    icon: "fa-phone",
    title: "Telefon",
    text: "+420 608 328 761",
    href: "tel:+420608328761",
  },
  {
    icon: "fa-envelope",
    title: "Email",
    text: "contact@factorysolutions.eu",
    href: "mailto:contact@factorysolutions.eu",
  },
];

const generateBasicInfo = () => {
  return (
    <div className="col-sm-12 col-md-6 col-lg-3 my-3 home-footer-col-border home-footer-col-1">
      <img src={logo} alt="Factory Solutions" width="200px" />
      <p>Vytvořeno na základě úspěšného školního projektu, rok 2021</p>
    </div>
  );
};

const generateRespPerson = () => {
  const data = [
    { icon: "fa-user", text: "Štěpán Zavadil" },
    { icon: "fa-arrow-circle-right", text: "IČO: 09126082" },
    {
      icon: "fa-envelope",
      text: "zavadil@factorysolutions.eu",
      href: "mailto:zavadil@factorysolutions.eu",
    },
    { icon: "fa-phone", text: "+420 608 328 761", href: "tel:+420608328761" },
  ];

  return (
    <div className="col-sm-12 col-md-6 col-lg-3 my-3 p-0 d-flex flex-column align-items-center home-footer-col-border">
      <h5 className="home-footer-col-title my-3">Odpovědná osoba</h5>
      <div>
        <ul className="home-footer-list home-footer-col-2">
          {data.map((item, i) => (
            <li key={i}>
              <a href={item.href} className="text-white text-decoration-none">
                <span className="home-footer-icon">
                  <i className={`fa ${item.icon}`} aria-hidden="true"></i>
                </span>
                {item.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const generateNavigation = () => {
  const data = [
    { href: "#", text: "Úvod" },
    { href: "#info-box", text: "Info" },
    { href: "#our-apps", text: "Naše aplikace" },
    { href: "#contact", text: "Kontakt" },
  ];

  return (
    <div className="col-sm-12 col-md-6 col-lg-3 my-3 p-0 d-flex flex-column align-items-center home-footer-col-border">
      <h5 className="home-footer-col-title my-3">Navigace</h5>
      <div>
        <ul className="home-footer-list home-footer-col-3">
          {data.map((item, i) => (
            <li key={i}>
              <a className="text-decoration-none text-white" href={item.href}>
                {item.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const generatePartners = () => {
  const data = [{ href: "http://www.itsbenda.cz", text: "ITS BENDA s.r.o." }];

  return (
    <div className="col-sm-12 col-md-6 col-lg-3 my-3 p-0 d-flex flex-column align-items-center">
      <h5 className="home-footer-col-title my-3">Partneři</h5>
      <div>
        <ul className="home-footer-list home-footer-col-4">
          {data.map((item, i) => (
            <li key={i}>
              <a
                className="text-decoration-none text-white"
                href={item.href}
                target="_blank"
                rel="noreferrer"
              >
                {item.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default function Home() {
  const [currentApp, setApp] = useState(our_apps[0]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="home-welcome-img-container">
        <div className="home-welcome-img-texts">
          <div className="container">
            <p className="home-welcome-img-welcoming-text m-0 p-0">
              Vítejte na stránkách
            </p>
            <p className="home-welcome-img-brand-text p-0">FACTORY SOLUTIONS</p>
            <p className="home-welcome-img-additional-text m-0 p-0">
              Zabýváme se tvorbou webových aplikací pro výrobní a provozní účely
              do malých a středních firem. <br />
              Časově náročnou práci lidí převádíme na počítač. Naše aplikace
              zrychlují proces o stovky procent.
            </p>
            <div className="home-welcome-img-buttons-container">
              <a
                className="home-welcome-img-button-first btn btn-dark"
                href="#our-apps"
              >
                Naše aplikace
              </a>
              <a
                className="home-welcome-img-button-second btn btn-dark"
                href="#contact"
              >
                Kontaktovat
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="home-info-box-cover" id="info-box">
        <div className="home-info-box-backdrop py-5">
          <div className="container">
            <div className="row m-0 p-0">
              {info_boxes.map((box, i) => (
                <div
                  className="col-sm-12 col-md-4 my-4 p-0 home-info-box"
                  key={i}
                >
                  <i
                    className={`fa ${box.icon} home-info-box-icon`}
                    aria-hidden="true"
                  ></i>
                  <h3 className="home-info-box-heading">{box.heading}</h3>
                  <p className="home-info-box-text">{box.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="home-our-apps-container" id="our-apps">
        <div className="home-our-apps-backdrop py-4">
          <h1 className="text-center app-font-main text-uppercase my-3 text-white">
            <span className="heading-underline">Naše aplikace</span>
          </h1>
          <div className="container">
            <div className="row p-0 m-0">
              <div className="col-sm-12 col-md-9 my-3 m-0 p-0">
                <div className="card mx-3 home-our-apps-card">
                  <div className="card-body">
                    <h5 className="card-title home-our-apps-card-title">
                      {currentApp.title}
                    </h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      Verze <b>{currentApp.version}</b>
                    </h6>
                    <p className="card-text home-our-apps-card-text">
                      {currentApp.text}
                    </p>
                    {currentApp.pics.length > 0 ? (
                      <>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Obrázky
                        </h6>
                        <div
                          className="home-our-apps-pic-container cursor-pointer"
                          onClick={() => setIsOpen(true)}
                        >
                          <img
                            src={currentApp.pics[0].src}
                            alt="FACTORY SOLUTIONS"
                            className="home-our-apps-pic"
                          />
                          <div className="home-our-apps-pic-overlay">
                            {currentApp.pics.length}
                          </div>
                        </div>
                      </>
                    ) : null}
                    <a
                      href="#contact"
                      className="card-link btn btn-warning home-our-apps-btn text-decoration-none mt-3 fw-bold text-dark"
                    >
                      Kontaktovat pro objednání
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-3 my-3 m-0 p-0">
                <ul className="list-group mx-3 home-our-apps-list-group">
                  {our_apps.map((app, i) => (
                    <a
                      href="#our-apps"
                      className="text-dark text-decoration-none"
                      key={i}
                    >
                      <li
                        className={`list-group-item list-group-item-action cursor-pointer home-our-apps-list-item ${
                          app === currentApp
                            ? "home-our-apps-list-item-active"
                            : ""
                        }`}
                        onClick={() => setApp(app)}
                      >
                        <i
                          className="fa fa-chevron-circle-left"
                          aria-hidden="true"
                        ></i>{" "}
                        {app.title}
                      </li>
                    </a>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-contact-container" id="contact">
        <div className="home-contact-backdrop py-5">
          <h1 className="text-center app-font-main text-uppercase m-0 p-0">
            <span className="heading-underline">Kontakt</span>
          </h1>
          <div className="container">
            <div className="row m-0 p-0">
              {contacts.map((contact, i) => (
                <div
                  className="col-sm-12 col-md-6 mt-5 p-0 text-center d-flex flex-column align-items-center justify-content-center"
                  key={i}
                >
                  <a href={contact.href} className="text-decoration-none">
                    <i
                      className={`fa ${contact.icon} home-contact-icon`}
                      aria-hidden="true"
                    />
                    <h3 className="home-contact-title mt-4">{contact.title}</h3>
                    <span className="home-contact-text">{contact.text}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="home-footer-container py-5">
        <div className="container">
          <div className="row m-0 p-0">
            {generateBasicInfo()}
            {generateRespPerson()}
            {generateNavigation()}
            {generatePartners()}
          </div>
        </div>
      </div>
      <div className="home-bottom-footer py-3 text-center">
        <p className="m-0 p-0">&copy; Všechna práva vyhrazena, 2021</p>
      </div>

      <ImageViewer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        images={currentApp.pics}
      />

      <TopScroller />
    </div>
  );
}

function getLongDivisionText() {
  return (
    <span>
      Aplikace Podélné dělení je primárně navrhnuta pro dělení svitků na pásky,
      lze ale využít pro vytváření nářezových plánů pro jakékoliv podélné
      dělení.
      <br />
      Od uživatele je vyžadováno, aby zadal potřebná data, jako je šířka a
      tloušťka děleného materiálu (svitku) a také akceptovatelný odpad po
      okrajích. Následně do aplikace vloží svitky a pásky. U svitků se uvádí
      celková hmotnost a číslo série (pro identifikaci). U pásek se uvádí šířka
      a celková potřebná hmotnost (která se má vytvořit).
      <br />
      Vše lze zadat ručně nebo zkopírovat např. z firemního informačního systému
      nebo tabulky excel. Program sám kontroluje správnost zadaných údajů a při
      chybě uživatele upozorní a nenechá ho nesprávný údaj uložit. Po vyplnění
      minimálního počtu všech potřebných údajů se objeví tlačítko pro spuštění
      výpočtu.
      <br />
      Výpočet probíhá maximálně 15 vteřin (pro běžná data pod 2 vteřiny) a je
      odeslán a vypočítán na vzdáleném serveru, aby nezatěžoval počítač
      uživatele. Výpočet je vyhotoven pomocí speciálního algoritmu, který hledá
      nejlepší kombinaci pásek do celkové šířky svitku, přičemž upřednostňuje
      pásky s největší prioritou (v pořadí, jak jsou zadané), počítá s odpady a
      také nedovolí, aby se pásek do svitku naskládalo tolik, že by vznikla
      nadbytečná hmotnost pásky (tento údaj lze upravit v nastavení - procenta
      přesahu).
      <br />
      Po skončení výpočtu se zobrazí přehledná tabulka a tlačítko, které
      umožnuje převod výsledku do PDF.
      <br />
      Každý výpočet se automaticky ukládá do historie a je možné se k němu
      kdykoliv vrátit. Výpočty, nebo jen surová data ještě před výpočtem lze
      uložit a také se k nim kdykoliv vrátit, případně je smazat.
      <br />
      Při objednání aplikace je při pořízení v ceně kompletní zaškolení. Zároveň
      se v menu aplikace nachází návod k obsluze.
      <br />
      Aplikaci je možné na 30 dní vyzkoušet ZDARMA.
    </span>
  );
}

function getProdPlannerText() {
  return (
    <span>
      Aplikace Plánování výroby slouží ke snadnějšímu zádávání požadavků na
      výrobu, jejich následné editaci a rychlé propagaci změn ke všem uživatelům
      programu.
      <br />
      V programu si uživatelé mohou vytvořit libovolný počet výrobních linek, do
      kterých následně vkládají výrobky. V hlavním náhledu je vždy zobrazen
      měsíc, ve kterém je pro každou směnu přiřazen výrobek na určité lince.
      <br />
      Vložený výrobek se automaticky zařadí na konec linky. Později lze s
      výrobkem libovolně manipulovat pomocí seznamu akcí (viz. obrázky), které
      slouží k posouvání vůči ostatním výrobkům, lze také upravovat počet směn
      (po kterou se výrobek bude vyrábět - tedy kolik místa v kalendáři zabere),
      název, poznámku a barvu. Všechny změny se ihned po upravení projeví.
      <br />
      Program defaultně pracuje s 2mi směnami za den, do kterých se výrobky
      automaticky skládají. Počet směn za den lze měnit.
      <br />
      Pokud nastane směna, ve které linka nemůže fungovat (např. víkend,
      porucha, státní svátek, apod.), lze na danou směnu umístit tzv. blokaci.
      Blokace zařídí, že se k dané směně žádný výrobek nepřiřadí a dojde k
      přeskočení směny. Blokace se přidává na určité datum a směnu. Následně se
      nastavuje jak dlouho (kolik směn) trvá. Po vložení je možnost blokaci
      upravovat. Blokace je od běžných výrobků jasně vizuálně odlišena kvůli
      přehlednosti.
      <br />
      Výrobky, které jsou již reálně vyrobené je možno označit jako "DOKONČENÉ",
      aby byl plán přehlednější. Tyto výrobky jsou od ostatních vizuálně
      odlišeny.
      <br />
      Aplikace umožňuje vygenerovat výstup do PDF. Je možné si vybrat PDF pro
      celý kalendářní měsíc, nebo určit týdny, pro které se má PDF vytvořit.
      <br />
      V programu je zabudován režim pro čtení a režim pro editaci. Při režimu
      pro čtení se uživatel nemusí obávat, že by způsobil nevyžádané změny,
      neboť to program nedovolí. Režim editace slouží k přidávání a úpravám
      výrobků/blokací.
      <br />
      Všichni uživatelé se v reálném čase zobrazují v horní části programu. Lze
      u nich vidět, zda mají zapnutou editaci či režim pro čtení. Veškeré změny
      v plánu (např. vložení výrobku/blokace, úpravy, apod.) se u všech
      uživatelů projeví během 2 vteřin bez nutnosti jakékoliv manuální
      aktualizace. Všichni uživatelé tedy vždy vidí stejný plán.
      <br />
      Nově program obsahuje také vyhledávání, které hledá výrobek podle názvu.
      Uživateli ukáže, v jakém termínu a na jaké lince se bude vyrábět a po
      kliknutí na výrobek je uživatel přesměrován přímo do daného měsíce.
      <br />
      Při objednání aplikace je při pořízení v ceně kompletní zaškolení. Zároveň
      se v menu aplikace nachází návod k obsluze.
      <br />
      Aplikaci je možné na 30 dní vyzkoušet ZDARMA.
    </span>
  );
}
