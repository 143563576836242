import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import styles, { style_measures } from "./pdfStyles";
import _ from "lodash";
import { cz } from "../../../../language.json";

export default function PDFoutput({
  secRow,
  thrRow,
  fouRow,
  lastRow,
  outputColSpan,
  missingStrips,
  usedRolls,
}) {
  const generateRow = (array) => {
    return (
      <>
        <Text style={styles.table_cell_thickness}>{array[0]}</Text>
        <Text style={styles.table_cell_width}>{array[1]}</Text>
        <Text style={styles.table_cell_weight}>{array[2]}</Text>
        <Text style={styles.table_cell_waste}>{array[3]}</Text>
        {_.slice(array, 4, array.length - 1).map((x, i) => (
          <Text key={i} style={styles.table_cell}>
            {x}
          </Text>
        ))}
        <Text style={styles.table_cell_waste}>{array[array.length - 1]}</Text>
      </>
    );
  };

  const generateButSkipInputRow = (array) => {
    return (
      <>
        <Text
          style={{
            ...styles.table_cell,
            width: `${style_measures.tableInputWidth}mm`,
          }}
        >
          {cz.general.partialWeight}
        </Text>
        <Text style={styles.table_cell_width}>{array[0]}</Text>
        {_.slice(array, 1, array.length - 1).map((x, i) => (
          <Text key={i} style={styles.table_cell}>
            {Math.round(x)}
          </Text>
        ))}
        <Text style={styles.table_cell_waste}>{array[array.length - 1]}</Text>
      </>
    );
  };

  const generateMissingStrips = (array) => {
    return array.map((row, i) => {
      let rowStyle = styles.table_row;
      if (i === array.length - 1)
        rowStyle = { ...rowStyle, borderBottom: style_measures.borderStyle };

      return (
        <View key={i} style={rowStyle}>
          <Text style={styles.table_missing_width}>{row[0]}</Text>
          <Text style={styles.table_missing_label}>{row[1]}</Text>
          <Text style={styles.table_missing_weight}>{row[2]}</Text>
        </View>
      );
    });
  };

  const generateUsedRolls = (array) => {
    return array.map((row, i) => {
      let rowStyle = styles.table_row;
      if (i === array.length - 1)
        rowStyle = { ...rowStyle, borderBottom: style_measures.borderStyle };

      return (
        <View key={i} style={rowStyle}>
          <Text style={styles.table_used_serie}>{row[0]}</Text>
          <Text style={styles.table_used_weight}>{row[1]}</Text>
        </View>
      );
    });
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.table_container}>
          <View style={styles.table_header}>
            <Text style={styles.table_header_input}>{cz.general.input}</Text>
            <Text
              style={{
                ...styles.table_header_output,
                width: `${
                  style_measures.wasteCellW * 2 +
                  outputColSpan * style_measures.cellW
                }mm`,
              }}
            >
              {cz.general.output}
            </Text>
          </View>
          <View style={styles.table_row}>{generateRow(secRow)}</View>
          <View style={styles.table_row}>{generateRow(thrRow)}</View>
          <View style={styles.table_row}>{generateRow(fouRow)}</View>
          <View style={styles.table_row_weights}>
            {generateButSkipInputRow(lastRow)}
          </View>
        </View>
        <View style={styles.other_tables_container}>
          <View style={styles.table_vertical_container}>
            <Text style={styles.table_header_missing}>
              {cz.general.missingStrips}
            </Text>
            {generateMissingStrips(missingStrips)}
          </View>
          <View style={styles.table_vertical_container}>
            <Text style={styles.table_header_used}>{cz.general.usedRolls}</Text>
            <View style={styles.table_row}>
              <Text style={styles.table_used_serie_heading}>
                {cz.general.serie}
              </Text>
              <Text style={styles.table_used_weight_heading}>
                {cz.general.weight}
              </Text>
            </View>
            {generateUsedRolls(usedRolls)}
          </View>
        </View>
      </Page>
    </Document>
  );
}
