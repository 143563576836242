import React from "react";
import * as Yup from "yup";
import Form from "./../../forms/Form";
import LeftInput from "./../../forms/LeftInput";
import SubmitButton from "./../../forms/SubmitButton";
import ColorPicker from "./../../forms/ColorPicker";
import LeftSelect from "./../../forms/LeftSelect";
import LeftCheckBox from "./../../forms/LeftCheckBox";
import AdditionalField from "./../../forms/AdditionalField";
import { generateHexColor, generateObjId } from "./../../../utils";

import { cz } from "./../../../language.json";
import varNames from "./../../../var-names.json";
import measures from "./../../../measures.json";

const actionTypes = [
  { _id: varNames.actionMoveToStart, name: cz.general.moveToStart },
  { _id: varNames.actionMoveForward, name: cz.general.moveForward },
  { _id: varNames.actionMoveBackward, name: cz.general.moveBackward },
  { _id: varNames.actionMovetoEnd, name: cz.general.moveToEnd },
  { _id: varNames.actionDelete, name: cz.general.delete },
];

const data = [
  { title: cz.general.product, name: varNames.title, type: "text" },
  { title: cz.general.note, name: varNames.note, type: "text" },
  { title: cz.general.numberOfShifts, name: varNames.shifts, type: "number" },
];

export default function EventForm({
  modal,
  setModal,
  onSubmit,
  readOnly,
  isNew,
  lines,
}) {
  return (
    <Form
      validationSchema={validationSchema(isNew)}
      initialValues={initialValues(modal, isNew)}
      onSubmit={(values) =>
        onSubmitHandle(values, modal, isNew, onSubmit, setModal)
      }
    >
      {isNew && (
        <LeftSelect
          label={cz.general.line}
          name={varNames.lineId}
          items={lines}
        />
      )}

      {data.map((item, i) => (
        <LeftInput
          key={i}
          name={item.name}
          type={item.type}
          readOnly={readOnly}
          label={item.title}
        />
      ))}

      <ColorPicker
        label={cz.general.color}
        readOnly={readOnly}
        name={varNames.color}
      />
      <LeftCheckBox
        label={cz.general.productDone}
        name={varNames.isDone}
        disabled={readOnly}
      />
      {!readOnly && !isNew && (
        <>
          <LeftSelect
            label={cz.general.action}
            name={varNames.action}
            items={actionTypes}
          />

          <AdditionalField
            equalName={varNames.action}
            equalValue={varNames.actionMoveForward}
            component={
              <LeftInput
                label={cz.general.quantity}
                name={varNames.actionAdditional}
              />
            }
          />
          <AdditionalField
            equalName={varNames.action}
            equalValue={varNames.actionMoveBackward}
            component={
              <LeftInput
                label={cz.general.quantity}
                name={varNames.actionAdditional}
              />
            }
          />
        </>
      )}
      {!readOnly && <SubmitButton text={cz.general.save} />}
    </Form>
  );
}

const validationSchema = (isNew) => {
  const schema = {
    [varNames._id]: Yup.string(cz.validation.string).required(
      cz.validation.required
    ),
    [varNames.title]: Yup.string(cz.validation.string)
      .max(
        measures.maxEventTitle,
        `${cz.validation.maximally} ${measures.maxEventTitle} ${cz.validation.chars}`
      )
      .required(cz.validation.required),
    [varNames.note]: Yup.string(cz.validation.string).max(
      measures.maxEventNote,
      `${cz.validation.maximally} ${measures.maxEventNote} ${cz.validation.chars}`
    ),
    [varNames.shifts]: Yup.number()
      .typeError(cz.validation.number)
      .min(
        measures.minShifts,
        `${cz.validation.minimally} ${measures.minShifts}`
      )
      .max(
        measures.maxShifts,
        `${cz.validation.maximally} ${measures.maxShifts}`
      )
      .required(cz.validation.required),
    [varNames.color]: Yup.string(cz.validation.string).required(
      cz.validation.required
    ),
    [varNames.isDone]: Yup.boolean().required(cz.validation.required),
    [varNames.action]: Yup.string(cz.validation.string),
    [varNames.actionAdditional]: Yup.number()
      .typeError(cz.validation.number)
      .min(measures.minMove, `${cz.validation.minimally} ${measures.minMove}`)
      .when(varNames.action, {
        is: (val) =>
          val === varNames.actionMoveForward ||
          val === varNames.actionMoveBackward,
        then: Yup.number()
          .typeError(cz.validation.number)
          .required(cz.validation.required),
      }),
  };

  if (isNew)
    schema[varNames.lineId] = Yup.string(cz.validation.string).required(
      cz.validation.required
    );

  return Yup.object().shape(schema);
};

const initialValues = (modal, isNew) => {
  if (isNew)
    return {
      [varNames._id]: generateObjId(),
      [varNames.lineId]: "",
      [varNames.title]: "",
      [varNames.note]: "",
      [varNames.shifts]: "",
      [varNames.color]: generateHexColor(),
      [varNames.isDone]: false,
      [varNames.action]: "",
      [varNames.actionAdditional]: "",
    };
  else {
    return {
      [varNames._id]: modal.shift[varNames._id],
      [varNames.title]: modal.shift[varNames.title],
      [varNames.note]: modal.shift[varNames.note],
      [varNames.shifts]: modal.shift[varNames.shifts],
      [varNames.color]: modal.shift[varNames.color],
      [varNames.isDone]: modal.shift[varNames.isDone] ? true : false,
      [varNames.action]: "",
      [varNames.actionAdditional]: "",
    };
  }
};

const onSubmitHandle = (values, modal, isNew, onSubmit, setModal) => {
  const shift = { ...values };
  shift[varNames.shifts] = Math.round(shift[varNames.shifts]);

  delete shift[varNames.action];
  delete shift[varNames.actionAdditional];
  delete shift[varNames.lineId];

  if (isNew) return onSubmit(shift, values.lineId, false, setModal);
  else
    return onSubmit(
      modal.line[varNames._id],
      shift,
      false,
      values[varNames.action],
      Math.round(values[varNames.actionAdditional]),
      setModal
    );
};
