import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { cz } from "../../language.json";
import varNames from "../../var-names.json";
import moment from "moment";
import ClickableCard from "../ClickableCard";
import AppsContext from "../../context/appsContext";
import UserContext from "../../context/userContext";

export default function Applications() {
  const apps = useContext(AppsContext);
  const { user } = useContext(UserContext);

  const paidApps = [];
  const otherApps = [];

  apps.forEach((app) => {
    for (const userApp of user.apps) {
      if (userApp[varNames._id] === app[varNames._id] && isStillValid(userApp))
        return paidApps.push(app);
    }

    otherApps.push(app);
  });

  const appsData = [
    { title: cz.general.yourApps, data: paidApps },
    { title: cz.general.notPaidApps, data: otherApps },
  ];

  return (
    <>
      <h1 className="text-center app-font-main text-uppercase fw-bold my-3">
        {cz.general.apps}
      </h1>
      {appsData.map((appGroup, i) => {
        if (appGroup.data.length === 0) return null;

        return (
          <div key={i}>
            <h5 className="text-center app-font-main text-uppercase fw-bold my-3">
              {appGroup.title}
            </h5>
            <div className="container">
              <div className="row p-0 m-0">
                {appGroup.data.map((app) => (
                  <div
                    className="col mb-5 d-flex justify-content-center"
                    key={app[varNames._id]}
                  >
                    <Link
                      to={`applications/${app[varNames._id]}`}
                      className={`text-dark text-decoration-none ${
                        appGroup.title === cz.general.notPaidApps
                          ? "apps-not-paid"
                          : ""
                      }`}
                    >
                      <ClickableCard
                        favIcon={app[varNames.appFavIcon]}
                        name={app[varNames.appName]}
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

function isStillValid(app) {
  if (moment(app[varNames.appPaidUntil]).isBefore(moment(new Date()), "days"))
    return false;

  return true;
}
