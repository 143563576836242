import React, { useContext, useState } from "react";
import SearchBox from "../../SearchBox";
import varNames from "./../../../var-names.json";
import moment from "moment";

import { cz } from "./../../../language.json";
import ProdPlannerContext from "./../../../context/prodPlannerContext";

const format = "D.M.YY";

export default function SearchTab() {
  const [sShifts, setSShifts] = useState([]);
  const { shifts, app, handlers } = useContext(ProdPlannerContext);

  const lines = app[varNames.appSaved];
  const { startDate, shiftsPerDay } = app[varNames.appSettings];

  const handleSearch = (query) => {
    const newSS = [];

    // console.log("changing");
    shifts.forEach((shiftLine, i) => {
      newSS.push([]);
      shiftLine.forEach((shift, shiftIndex) => {
        if (
          shift.title &&
          shift.title.toLowerCase().includes(query.toLowerCase())
        ) {
          newSS[i].push({ ...shift, index: shiftIndex });
        }
      });
    });
    // console.log("changing DONE");
    setSShifts(shrinkSShifts(newSS, startDate, shiftsPerDay));
  };

  const handleProductClick = (date) => {
    handlers.setCurrent(date);
    handlers.handlePanelChange(varNames.searchName);
  };

  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-12 p-3 p-md-5 pt-5">
          <h1 className="text-center text-white app-font-main">
            {cz.general.search}
          </h1>
          <div className="container container-white-trans">
            <SearchBox onChange={handleSearch} />
            <div className="d-flex justify-content-center">
              <div className="d-flex horizontally-scrollable">
                {sShifts.map((line, i) => {
                  return (
                    <div key={i}>
                      <div className="shift-col-heading">
                        {lines[i][varNames.title]}
                      </div>
                      {line.map((shift, i) => (
                        <div
                          key={i}
                          className="searched-shift cursor-pointer"
                          onClick={() =>
                            handleProductClick(moment(shift.startDay, format))
                          }
                        >
                          <span className="fw-bold text-center">
                            {shift.title}
                          </span>
                          <span>
                            {shift.startDay} - {shift.endDay}
                          </span>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function shrinkSShifts(sShifts, startDate, shiftsPerDay) {
  // console.log("before", sShifts);
  return sShifts.map((shifts) => {
    const shrinked = [];
    let sId = "";
    let sIStart, sIEnd;

    for (let shiftIndex = 0; shiftIndex < shifts.length; shiftIndex++) {
      // shift je stejná jako ta předtim
      if (shifts[shiftIndex][varNames._id] === sId) {
        if (shiftIndex < shifts.length - 1) {
          if (shifts[shiftIndex + 1][varNames._id] !== sId) {
            sIEnd = shifts[shiftIndex].index;
            const startDay = moment(startDate).add(
              Math.floor(sIStart / shiftsPerDay),
              "days"
            );

            const endDay = moment(startDate).add(
              Math.floor(sIEnd / shiftsPerDay),
              "days"
            );

            if (moment(endDay).isBefore(moment(), "month")) continue;
            const obj = {
              ...shifts[shiftIndex],
              startDay: startDay.format(format),
              endDay: endDay.format(format),
            };
            delete obj.index;
            shrinked.push(obj);
          }
        } else {
          sIEnd = shifts[shiftIndex].index;
          const startDay = moment(startDate).add(
            Math.floor(sIStart / shiftsPerDay),
            "days"
          );

          const endDay = moment(startDate).add(
            Math.floor(sIEnd / shiftsPerDay),
            "days"
          );

          if (moment(endDay).isBefore(moment(), "month")) continue;
          const obj = {
            ...shifts[shiftIndex],
            startDay: startDay.format(format),
            endDay: endDay.format(format),
          };
          delete obj.index;
          shrinked.push(obj);
        }
      } else {
        sIStart = shifts[shiftIndex].index;
        sId = shifts[shiftIndex][varNames._id];
      }
    }

    return shrinked;
  });
}
