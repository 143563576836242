import React from "react";

export default function DashIcon({ settings, onClick }) {
  const { iconStyle, name, opened } = settings;

  return (
    <div
      className={`cursor-pointer d-flex justify-content-center align-items-center ${
        opened ? "bg-black text-white border-end-0" : "border"
      }`}
      onClick={() => onClick(name)}
    >
      <i className={`fa ${iconStyle} p-3 icon-stuff`} aria-hidden="true"></i>
    </div>
  );
}
