import React, { useContext, useEffect, useState } from "react";
import UserContext from "./../../context/userContext";
import ProdPlannerContext from "./../../context/prodPlannerContext";
import LinesTab from "./ProdPlanner/LinesTab";
import HelpTab from "./ProdPlanner/HelpTab";
import SettingsTab from "./ProdPlanner/SettingsTab";
import SearchTab from "./ProdPlanner/SearchTab";
import Output from "./ProdPlanner/Output";
import TabWrapper from "./../TabWrapper";
import DashIcon from "./../DashIcon";
import Error from "./../Error";

import { cz } from "../../language.json";
import varNames from "../../var-names.json";
import moment from "moment";
import useSocket from "../../hooks/useSocket";
import useShifts from "../../hooks/useShifts";
import logger from "./../../services/logService";

const leftPanel = [
  {
    name: varNames.linesName,
    iconStyle: varNames.linesIcon,
    opened: false,
    component: LinesTab,
  },
  {
    name: varNames.settingsName,
    iconStyle: varNames.settingsIcon,
    opened: false,
    component: SettingsTab,
  },
  {
    name: varNames.searchName,
    iconStyle: varNames.searchIcon,
    opened: false,
    component: SearchTab,
  },
  {
    name: varNames.helpName,
    iconStyle: varNames.helpIcon,
    opened: false,
    component: HelpTab,
  },
];

export default function ProdPlanner({ defaultSettings, currentAppId }) {
  const [current, setCurrent] = useState(moment());
  const { user } = useContext(UserContext);
  const [settings, setSettings] = useState({ ...defaultSettings });
  const [leftPanelSettings, setLeftPanelSettings] = useState(leftPanel);
  const {
    readOnly,
    changeReadOnly,
    changeSaved,
    users,
    saved: lines,
    error,
  } = useSocket(user, currentAppId, "/app/applications");

  const { shifts } = useShifts(
    lines,
    settings[varNames.startDate],
    settings[varNames.endDate],
    settings[varNames.shiftsPerDay]
  );

  useEffect(() => {
    const appFromUser = user[varNames.userApps].find(
      (a) => a[varNames._id] === currentAppId
    );

    const newSettings = {
      ...defaultSettings,
      ...appFromUser[varNames.appSettings],
    };

    newSettings[varNames.startDate] = moment(newSettings[varNames.startDate]);
    newSettings[varNames.endDate] = moment(newSettings[varNames.endDate]);

    setSettings(newSettings);
  }, [user, currentAppId, defaultSettings]);

  const handleEventChange = (
    lineId,
    event,
    isBlock,
    actionType,
    actionAdditional,
    setModal
  ) => {
    const linesCopy = [...lines];
    const line = linesCopy.find((l) => l[varNames._id] === lineId);

    const lineIndex = linesCopy.indexOf(line);
    const lineCopy = { ...line };

    // pokud je akce blokující
    if (isBlock) {
      const blockedEventsCopy = [...lineCopy[varNames.blockedEvents]];
      const blockedEventIndex = lineCopy[varNames.blockedEvents]
        .map((e) => e[varNames._id])
        .indexOf(event[varNames._id]);

      // ošetření kdy by blocked_event editovalo víc zdrojů, tak aby se nezměnil jinej náhodou
      if (blockedEventIndex < 0) {
        setModal(undefined); //zavření modalu
        return;
      }

      handleActions(
        event,
        blockedEventsCopy,
        blockedEventIndex,
        actionType,
        actionAdditional
      );

      lineCopy[varNames.blockedEvents] = blockedEventsCopy;
    }
    // pokud je akce normální
    else {
      const eventsCopy = [...lineCopy[varNames.events]];
      const eventIndex = lineCopy[varNames.events]
        .map((e) => e[varNames._id])
        .indexOf(event[varNames._id]);

      // ošetření kdy by event editovalo víc zdrojů, tak aby se nezměnil jinej náhodou
      if (eventIndex < 0) {
        setModal(undefined); //zavření modalu
        return;
      }

      handleActions(
        event,
        eventsCopy,
        eventIndex,
        actionType,
        actionAdditional
      );

      lineCopy[varNames.events] = eventsCopy;
    }

    linesCopy[lineIndex] = lineCopy;

    logger.logActivity("prodPlannerChange", user._id);
    changeSaved(linesCopy); // uložení dat
    setModal(undefined); //zavření modalu
  };

  const handleEventAdd = (event, lineId, isBlock, setModal) => {
    // console.log("přidat", event, lineId, isBlock);

    // spešl případ kdy je lineId === "all" -> přidávání blokujícího eventu do každé linky
    if (lineId === varNames.actionAll) {
      handleAddBlockedEventsToAll(event, lines, changeSaved, setModal);
      return;
    }

    const linesCopy = [...lines];
    const line = linesCopy.find((l) => l[varNames._id] === lineId);

    const lineIndex = linesCopy.indexOf(line);
    const lineCopy = { ...line };

    if (isBlock) {
      const blockedEventsCopy = [...lineCopy[varNames.blockedEvents]];
      blockedEventsCopy.push(event);

      lineCopy[varNames.blockedEvents] = blockedEventsCopy;
    } else {
      const eventsCopy = [...lineCopy[varNames.events]];
      eventsCopy.push(event);

      lineCopy[varNames.events] = eventsCopy;
    }

    linesCopy[lineIndex] = lineCopy;

    logger.logActivity("prodPlannerAdd", user._id);
    changeSaved(linesCopy); // uložení dat
    setModal(undefined); //zavření modalu
  };

  const handlePanelChange = (name) => {
    setLeftPanelSettings((prevSettings) => {
      const newSettings = prevSettings.map((item) => {
        const newItem = { ...item };

        if (newItem.name === name) newItem.opened = !newItem.opened;
        else newItem.opened = false;

        return newItem;
      });

      return newSettings;
    });
  };

  const renderTab = () => {
    let openedTab = leftPanelSettings.find((x) => x.opened === true);

    return openedTab ? (
      <TabWrapper tab={openedTab} onTabClose={handlePanelChange}>
        <openedTab.component />
      </TabWrapper>
    ) : null;
  };

  const app = {
    settings,
    saved: lines,
  };

  const handlers = {
    handleEventChange,
    handleEventAdd,
    changeReadOnly,
    changeSaved,
    handlePanelChange,
    setCurrent,
  };

  if (error) return <Error />;

  return (
    <ProdPlannerContext.Provider
      value={{ app, handlers, readOnly, users, shifts, current }}
    >
      <div className="row p-0 m-0 app-font-secondary">
        <div className="col-1 p-0">
          <div className="d-flex flex-column h-100 min-hv-100-no-navbar">
            {leftPanelSettings.map((setting) => (
              <DashIcon
                key={setting.name}
                onClick={handlePanelChange}
                settings={setting}
              />
            ))}
          </div>
        </div>
        <div className="col-11 p-0">{renderTab() || <Output />}</div>
      </div>
    </ProdPlannerContext.Provider>
  );
}

function handleActions(event, events, eventIndex, action, actionAdditional) {
  // žádná akce, jen změna dat
  if (action === "") {
    events[eventIndex] = event;
  }
  // akce 0 - posun na začátek + změna dat
  else if (action === varNames.actionMoveToStart) {
    events.splice(eventIndex, 1);
    events.unshift(event);
  }
  // akce 1 - posun dopředu + změna dat
  else if (action === varNames.actionMoveForward) {
    const newIndex = eventIndex + actionAdditional;

    events.splice(eventIndex, 1);
    events.splice(newIndex, 0, event);
  }
  // akce 2 - posun dozadu + změna dat
  else if (action === varNames.actionMoveBackward) {
    const newIndexPassed = eventIndex - actionAdditional;
    const newIndex = newIndexPassed < 0 ? 0 : newIndexPassed;

    events.splice(eventIndex, 1);
    events.splice(newIndex, 0, event);
  }
  // akce 3 - posun na konec + změna dat
  else if (action === varNames.actionMovetoEnd) {
    events.splice(eventIndex, 1);
    events.push(event);
  }
  // akce 4 - odstranění
  else if (action === varNames.actionDelete) {
    const confirm = window.confirm(
      `${cz.general.rlyDelete} ${
        event.title ? event.title : cz.general.blockedShift
      }?`
    );
    if (confirm) {
      events.splice(eventIndex, 1);
    }
  }
}

function handleAddBlockedEventsToAll(event, lines, changeSaved, setModal) {
  const linesCopy = [...lines];

  const newLines = linesCopy.map((line) => {
    const newLine = { ...line };

    const blockedEventsCopy = [...newLine[varNames.blockedEvents]];
    blockedEventsCopy.push(event);
    newLine[varNames.blockedEvents] = blockedEventsCopy;

    return newLine;
  });

  changeSaved(newLines); // uložení dat
  setModal(undefined); //zavření modalu
}
