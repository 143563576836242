import React from "react";
import { Field } from "formik";
import ErrorMessage from "./ErrorMessage";

function LeftCheckBox({ name, label, ...rest }) {
  return (
    <div className="mb-3">
      <div className="form-group row p-0 m-0 container">
        {label && (
          <div className="col-md-3 col-sm-12 d-flex align-items-center">
            <label htmlFor={name} className="form-label m-0 fw-bold">
              {label}
            </label>
          </div>
        )}
        <div className={`col-md-${label ? "9" : "12"} col-sm-12`}>
          <Field
            className="form-check-input"
            id={name}
            type="checkbox"
            name={name}
            {...rest}
          />
          <ErrorMessage name={name} />
        </div>
      </div>
    </div>
  );
}

export default LeftCheckBox;
