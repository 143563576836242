import { Field, getIn } from "formik";

export default function ErrorMessage({ name }) {
  return (
    <Field name={name}>
      {({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error && typeof error === "string" ? error : null;
      }}
    </Field>
  );
}
