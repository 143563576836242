import React, { useContext, useMemo, useRef, useState } from "react";
import ProdPlannerContext from "./../../../context/prodPlannerContext";
import FixedSideBar from "./../../FixedSideBar";
import ShiftsColumn from "./ShiftsColumn";
import Icon from "./../../Icon";
import moment from "moment";
import Modal from "./../../Modal";
import EventForm from "./EventForm";
import BlockedEventForm from "./BlockedEventForm";
import ConnectedUsers from "./ConnectedUsers";
import PPNav from "./PPNav";
import PdfModal from "./PdfModal";
import TopScroller from "./../../TopScroller";

import { cz } from "./../../../language.json";
import varNames from "./../../../var-names.json";
import measures from "./../../../measures.json";

const plusIcon = (
  <i className={`fa ${varNames.plusIcon}`} aria-hidden="true"></i>
);
const editIcon = (
  <i className={`fa ${varNames.editIconP}`} aria-hidden="true"></i>
);
const watchIcon = (
  <i className={`fa ${varNames.eyeIcon}`} aria-hidden="true"></i>
);

const pdfIcon = <i className={`fa ${varNames.pdfIcon}`} aria-hidden="true"></i>;

const shiftTypes = [
  cz.general.morningshift,
  cz.general.afternoonshift,
  cz.general.nightshift,
];

export default function Output() {
  const [modal, setModal] = useState(undefined);
  const [pdfModal, setPdfModal] = useState(false);
  const todayRow = useRef(null);

  const {
    app,
    handlers: PPhandlers,
    readOnly,
    users,
    shifts,
    current,
  } = useContext(ProdPlannerContext);
  const { saved: lines, settings } = app;
  const { shiftsPerDay, startDate, endDate } = settings;

  const dates = useMemo(() => getDates(current), [current]);
  const weeks = useMemo(() => getWeeks(current), [current]);
  const linesDD = useMemo(() => getDDLines(lines), [lines]);
  const shiftRows = useMemo(() => getShiftRows(shiftsPerDay), [shiftsPerDay]);

  const handlers = {
    setModal,
  };

  const handleHomeReturn = () => {
    // console.log("called");
    const isToday = moment().isSame(current, "day");
    if (!isToday) PPhandlers.setCurrent(moment());
    if (todayRow.current) todayRow.current.scrollIntoView();
  };

  return (
    <>
      <FixedSideBar>
        <Icon
          icon={pdfIcon}
          text={cz.general.pdf}
          bootstrap_color="warning text-white"
          onClick={() => setPdfModal(true)}
        />
        <Icon
          icon={plusIcon}
          text={cz.general.addProduction}
          onClick={() => setModal({ shift: { isNew: true }, line: {} })}
          bootstrap_color="success"
          hidden={readOnly}
        />
        <Icon
          icon={plusIcon}
          text={cz.general.addBlocked}
          onClick={() =>
            setModal({ shift: { isNew: true, isBlock: true }, line: {} })
          }
          bootstrap_color="danger"
          hidden={readOnly}
        />
        <Icon
          icon={readOnly ? editIcon : watchIcon}
          text={readOnly ? cz.general.switchEdit : cz.general.readOnly}
          onClick={() => PPhandlers.changeReadOnly()}
          bootstrap_color="primary"
        />
      </FixedSideBar>

      <div className="bg-img">
        <div className="row p-0 m-0">
          <div className="col-12 p-3">
            <PPNav
              handlers={PPhandlers}
              current={current}
              settings={settings}
            />
            <div className="d-flex align-items-center justify-content-center mb-2">
              <button
                className="btn btn-dark mx-3 app-font-main"
                onClick={() => handleHomeReturn()}
              >
                {cz.general.today}
              </button>
            </div>
            <div className="container container-gray-trans">
              <ConnectedUsers users={users} />
              <div className="d-flex justify-content-center">
                <div className="col-under-shift-col-heading ">
                  {weeks.map((week, i) => (
                    <div
                      style={{
                        height: measures.shiftHeight * week.days * shiftsPerDay,
                      }}
                      key={i}
                      className="just-week"
                    >
                      {week.week}
                    </div>
                  ))}
                </div>
                <div className="col-under-shift-col-heading ">
                  {dates.map((day) => {
                    const isWeekend =
                      moment(day).isoWeekday() === 6 ||
                      moment(day).isoWeekday() === 7;

                    const isToday = moment(day).isSame(moment(), "day");

                    return (
                      <div
                        key={day}
                        className={`d-flex flex-row align-items-center just-day ${
                          isWeekend ? "weekend-day" : ""
                        }`}
                        style={{
                          backgroundColor: isToday ? "yellow" : "",
                          height: measures.shiftHeight * shiftsPerDay,
                        }}
                        ref={isToday ? todayRow : null}
                      >
                        <span className="just-day-date text-center">
                          {moment(day).format("dd")}{" "}
                          {moment(day).format("D.M.")}
                        </span>
                        <div className="just-day-shift">{shiftRows}</div>
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex horizontally-scrollable">
                  {lines.map((line, i) => {
                    const propObj = {
                      current,
                      line,
                      settings,
                      handlers,
                      shifts: shifts[i],
                    };

                    return (
                      <ShiftsColumn key={line[varNames._id]} {...propObj} />
                    );
                  })}
                </div>
              </div>
            </div>
            <PPNav
              handlers={PPhandlers}
              current={current}
              settings={settings}
            />
          </div>
        </div>
      </div>

      <Modal
        visible={modal} //truthy|falsy
        onClose={() => setModal(undefined)}
        title={getModalTitle(modal)}
        children={getModalContent()}
      />

      <Modal
        visible={pdfModal} //truthy|falsy
        onClose={() => setPdfModal(false)}
        title={cz.general.createPdf}
        children={
          <PdfModal
            current={current}
            lines={lines}
            dates={dates}
            weeks={weeks}
            shiftRows={getShiftTypes(shiftsPerDay)}
            settings={settings}
            shifts={shifts}
          />
        }
      />

      <TopScroller />
    </>
  );

  function getModalContent() {
    if (!modal || !modal.shift || !modal.line) return "";

    const isBlock = modal.shift[varNames.isBlock];

    if (modal.shift.isNew) {
      if (isBlock)
        return (
          <BlockedEventForm
            modal={modal}
            setModal={setModal}
            onSubmit={PPhandlers.handleEventAdd}
            readOnly={readOnly}
            isNew
            lines={linesDD}
            shiftsPerDay={shiftsPerDay}
            startDate={startDate}
            endDate={endDate}
          />
        );
      else
        return (
          <EventForm
            modal={modal}
            setModal={setModal}
            onSubmit={PPhandlers.handleEventAdd}
            readOnly={readOnly}
            isNew
            lines={linesDD}
          />
        );
    }

    if (isBlock) {
      return (
        <BlockedEventForm
          modal={modal}
          setModal={setModal}
          onSubmit={PPhandlers.handleEventChange}
          readOnly={readOnly}
          shiftsPerDay={shiftsPerDay}
          startDate={startDate}
          endDate={endDate}
        />
      );
    }

    return (
      <EventForm
        modal={modal}
        setModal={setModal}
        onSubmit={PPhandlers.handleEventChange}
        readOnly={readOnly}
      />
    );
  }
}

function getDates(current) {
  const startDay = current.clone().startOf("month").date();
  const endDay = current.clone().endOf("month").date();

  const dates = [];

  for (let day = startDay; day <= endDay; day++) {
    const currentDate = current.clone().set("date", day);
    dates.push(currentDate.toDate());
  }

  console.log("dates generated");
  return dates;
}

function getWeeks(current) {
  const startDay = current.clone().startOf("month").date();
  const endDay = current.clone().endOf("month").date();

  const weeks = [
    {
      week: current.clone().set("date", startDay).isoWeek(),
      days: 0,
    },
  ];
  let weekIndex = 0;

  for (let day = startDay; day <= endDay; day++) {
    const currentDate = current.clone().set("date", day);
    if (weeks[weekIndex].week === currentDate.isoWeek())
      weeks[weekIndex].days++;
    else {
      weekIndex++;
      weeks[weekIndex] = {
        week: currentDate.isoWeek(),
        days: 1,
      };
    }
  }

  console.log("weeks generated");

  return weeks;
}

function getDDLines(lines) {
  return lines.map((line) => ({
    _id: line[varNames._id],
    name: line[varNames.title],
  }));
}

function getModalTitle(modal) {
  if (!modal || !modal.shift || !modal.line) return "";

  const isBlock = modal.shift[varNames.isBlock];

  if (modal.shift.isNew)
    return `${cz.general.adding} ${
      isBlock ? cz.general.blockedE : cz.general.prodE
    }`;

  return `${cz.general.line}: ${modal.line.title} ${
    isBlock ? `- ${cz.general.blockedShift}` : ""
  }`;
}

function getShiftTypes(shiftsPerDay) {
  return shiftTypes.slice(0, shiftsPerDay);
}

function getShiftRows(shiftsPerDay) {
  console.log("shiftTypes rows");
  const types = getShiftTypes(shiftsPerDay);

  return (
    <>
      {types.map((s, i) => {
        return (
          <div
            key={s}
            className="text-center"
            style={{
              height: measures.shiftHeight,
              borderBottom: i === types.length - 1 ? "" : "2px solid #adabab",
            }}
          >
            {s}
          </div>
        );
      })}
    </>
  );
}
