import React from "react";
import { FieldArray, useFormikContext } from "formik";
import TableFormInput from "./TableFormInput";
import ErrorMessage from "./ErrorMessage";

export default function TableForm({ name, titles, properties, heading }) {
  const { values } = useFormikContext();

  // asi né úplně reusable, cols jsou dělaný na 2 prvky + přidání/mazání
  return (
    <>
      <h2 className="text-center app-font-main">{heading}</h2>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            <table className="table table-bordered table-responsive-md table-striped text-center bg-light">
              <thead>
                <tr className="row p-0 m-0">
                  {titles.map((title, index) => (
                    <th
                      className="col-5 m-0 d-flex justify-content-center align-items-center"
                      key={index}
                    >
                      {title}
                    </th>
                  ))}
                  <th className="col-2 m-0 d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      onClick={() => arrayHelpers.push(properties)}
                    >
                      +
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {values[name].map((item, index) => (
                  <tr className="row p-0 m-0" key={index}>
                    {Object.keys(item).map((p) => (
                      <td
                        className="col-5 m-0 d-flex flex-column justify-content-center align-items-center"
                        key={p}
                      >
                        <TableFormInput name={`${name}[${index}].${p}`} />
                      </td>
                    ))}

                    <td className="col-2 m-0 d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      />
      <div className="text-center">
        <ErrorMessage name={name} />
      </div>
    </>
  );
}
