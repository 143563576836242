import React from "react";

import { cz } from "../language.json";

export default function Modal({ visible, children, onClose, title }) {
  if (!visible) return null;

  return (
    <>
      <div className="custom-modal">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                {cz.general.close}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
