import React from "react";

export default function TableSecondaryHeader({ items, noAddits, bold }) {
  return (
    <tr>
      {items.map((item, i) => {
        let classes = "";

        if (!noAddits) {
          if (i === 3 || i === items.length - 1) classes = "px-4";
          else if (i < 3) classes = "text-dark fst-italic";
          else classes = "text-dark";
        }

        if (bold) classes += " fw-bold";

        return (
          <td key={i} className={classes}>
            {item}
          </td>
        );
      })}
    </tr>
  );
}
