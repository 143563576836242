import React, { useContext } from "react";
import UserContext from "./../../context/userContext";
import AppsContext from "./../../context/appsContext";
import moment from "moment";
import Form from "../forms/Form";
import Input from "../forms/Input";
import SubmitButton from "../forms/SubmitButton";
import * as Yup from "yup";

import { cz } from "../../language.json";
import varNames from "../../var-names.json";
import measures from "../../measures.json";

export default function Account() {
  const { user, handlers } = useContext(UserContext);
  const apps = useContext(AppsContext);

  const userTable = [
    { value: user[varNames.userName], label: cz.general.title },
    { value: user[varNames.userEmail], label: cz.general.email },
    { value: user[varNames.userPhone], label: cz.general.phone },
    { value: user[varNames.userAddress], label: cz.general.address },
  ];

  const renderUserTable = () => {
    return userTable.map((row, i) => (
      <div key={i}>
        <div className="row">
          <div className="col-sm-3 d-flex align-self-center">
            <h6 className="m-0">{row.label}</h6>
          </div>
          <div className="col-sm-9 text-secondary text-md-end">{row.value}</div>
        </div>
        <hr />
      </div>
    ));
  };

  const renderPaidApps = () => {
    const userApps = user[varNames.userApps];

    return apps.map((app, i) => {
      const paidApp = userApps.find(
        (ua) => ua[varNames._id] === app[varNames._id]
      );

      let paidUntil;

      if (paidApp) {
        const diff = Math.ceil(
          moment
            .duration(moment(paidApp[varNames.appPaidUntil]).diff(new Date()))
            .asDays()
        );

        const end = moment(paidApp[varNames.appPaidUntil]).format("D. M. YY");

        if (diff >= 0) paidUntil = `${end} (${diff} ${cz.general.days})`;
        else paidUntil = cz.general.periodFinished;
      }

      return (
        <div key={i}>
          <div className="row">
            <div className="col-sm-5 d-flex align-self-center">
              <h6 className="m-0">{app[varNames.appName]}</h6>
            </div>
            <div className="col-sm-7 text-secondary text-md-end">
              {paidUntil ? paidUntil : cz.general.notBought}
            </div>
          </div>
          <hr />
        </div>
      );
    });
  };

  return (
    <>
      <h1 className="text-center app-font-main text-uppercase fw-bold my-3">
        {cz.general.customerAccount}
      </h1>
      <div className="container app-font-secondary">
        <div className="main-body">
          <div className="row">
            <div className="col-md-8 mb-3">
              <div className="card">
                <div className="card-body">
                  {renderUserTable()}
                  <Form
                    initialValues={{
                      [varNames.newPW]: "",
                    }}
                    onSubmit={(values) =>
                      handlers.handleChangePW(values[varNames.newPW])
                    }
                    validationSchema={validationSchema}
                  >
                    <div className="row">
                      <div className="col-sm-3">
                        <button
                          className="btn btn-warning"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#pwChangeCollapse"
                          aria-expanded="false"
                          aria-controls="pwChangeCollapse"
                        >
                          {cz.general.changePW}
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <div className="collapse" id="pwChangeCollapse">
                          <Input
                            name={varNames.newPW}
                            label={cz.general.newPW}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="collapse" id="pwChangeCollapse">
                          <SubmitButton
                            text={cz.general.save}
                            btnClasses="btn-primary"
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h6 className="d-flex align-items-center mb-3 text-uppercase">
                    {cz.general.prepaidApps}
                  </h6>
                  {renderPaidApps()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validationSchema = Yup.object().shape({
  [varNames.newPW]: Yup.string()
    .min(1, `${cz.validation.minimally} 1 ${cz.validation.chars}`)
    .max(
      measures.maxPWlength,
      `${cz.validation.maximally} ${measures.maxPWlength} ${cz.validation.chars}`
    )
    .required(cz.validation.required),
});
