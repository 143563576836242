import React from "react";

import { cz } from "../../../../language.json";
import varNames from "../../../../var-names.json";

export default function SettingsTable({ settings, ignore = [] }) {
  if (!settings) return null;

  const items = [
    {
      key: varNames.stripsOverWeight,
      title: cz.general.stripsOverWeightShort,
      value: `${settings[varNames.stripsOverWeight]} %`,
    },
    {
      key: varNames.serverWork,
      title: cz.general.serverWorkShort,
      value: settings[varNames.serverWork] ? cz.general.yes : cz.general.no,
    },
  ];

  return (
    <div className="container">
      <h3 className="text-center text-secondary">{cz.general.settings}</h3>
      <table className="table text-center">
        <tbody>
          {items.map((item) => {
            if (ignore.includes(item.key)) return null;

            return (
              <tr key={item.key}>
                <td>{item.value}</td>
                <th>{item.title}</th>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
