import React from "react";

export default function FixedSideBar({ children }) {
  return (
    <div
      className="d-flex flex-column justify-content-end align-items-end"
      style={{ position: "fixed", right: 0, bottom: "30%", zIndex: "9999" }}
    >
      {children}
    </div>
  );
}
