import React, { useContext, useState } from "react";
import Table from "../../table/Table";
import TooltipTable from "./Output/Table";
import ShowSRStuff from "./More/ShowStripsRollsStuff";
import General from "./Output/General";
import SettingsTable from "./Output/SettingsTable";
import LongDivisionContext from "./../../../context/longDivisionContext";
import moment from "moment";

import varNames from "../../../var-names.json";
import { cz } from "../../../language.json";
import Pagination from "../../Pagination";
import { paginate, sortByParam } from "../../../utils";

export default function HistoryTab() {
  const { app, handlers } = useContext(LongDivisionContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const columns = [
    {
      label: cz.general.date,
      content: (obj) => moment(obj[varNames.historyTime]).format("D.M.YYYY"),
      key: cz.general.date,
    },
    {
      label: cz.general.time,
      content: (obj) => moment(obj[varNames.historyTime]).format("HH:mm"),
      key: cz.general.time,
    },
    {
      label: cz.general.strips,
      content: (obj) => obj[varNames.historyInputData][varNames.strips].length,
      key: cz.general.strips,
    },
    {
      label: cz.general.rolls,
      content: (obj) => obj[varNames.historyInputData][varNames.rolls].length,
      key: cz.general.rolls,
    },
    {
      label: cz.general.found,
      content: (obj) => (isFound(obj) ? cz.general.yes : cz.general.no),
      key: cz.general.found,
    },
    {
      label: cz.general.show,
      content: (obj) => (
        <ShowSRStuff
          tables={[
            getSettingsTable(obj),
            getStripsTable(obj),
            getRollsTable(obj),
            getGeneralTable(obj),
          ]}
        />
      ),
      key: cz.general.show,
    },
    {
      label: "",
      content: (obj) => (
        <button
          onClick={() => handlers.handleHistoryInsert(obj)}
          className="btn btn-secondary"
        >
          {cz.general.insert}
        </button>
      ),
      key: cz.general.insert,
    },
  ];

  const isFound = (obj) => {
    if (obj[varNames.historyResult].length === 0) return false;
    if (obj[varNames.historyResult][varNames.timeOut]) return false;
    if (obj[varNames.historyResult][varNames.usedRolls].length > 0) return true;

    return false;
  };

  const getSettingsTable = (obj) => {
    const index = app[varNames.appHistory].indexOf(obj);

    return (
      <SettingsTable
        settings={app[varNames.appHistory][index][varNames.appSettings]}
        ignore={[varNames.serverWork]}
      />
    );
  };

  const getStripsTable = (obj) => {
    const index = app[varNames.appHistory].indexOf(obj);

    return (
      <TooltipTable
        data={app[varNames.appHistory][index][varNames.historyInputData]}
        item={varNames.strips}
        title={cz.general.strips}
        properties={[varNames.stripWidth, varNames.neededWeight]}
        titles={[cz.general.width, cz.general.weight]}
        units={[cz.general.mm, cz.general.kg]}
        notInserting
      />
    );
  };

  const getRollsTable = (obj) => {
    const index = app[varNames.appHistory].indexOf(obj);

    return (
      <TooltipTable
        data={app[varNames.appHistory][index][varNames.historyInputData]}
        item={varNames.rolls}
        title={cz.general.rolls}
        properties={[varNames.serie, varNames.rollWeight]}
        titles={[cz.general.serie, cz.general.weight]}
        units={["", cz.general.kg]}
        notInserting
      />
    );
  };

  const getGeneralTable = (obj) => {
    const index = app[varNames.appHistory].indexOf(obj);

    return (
      <General
        data={app[varNames.appHistory][index][varNames.historyInputData]}
      />
    );
  };

  const sorted = sortByParam(app[varNames.appHistory], [varNames.historyTime]);
  const paginatedData = paginate(sorted, currentPage, pageSize);

  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-12 p-3 p-md-5 pt-5">
          <h1 className="text-center text-white app-font-main">
            {cz.general.history}
          </h1>
          <div className="container container-white-trans">
            <Table data={paginatedData} columns={columns} />
            <div className="d-flex justify-content-center">
              <Pagination
                currentPage={currentPage}
                itemsCount={app[varNames.appHistory].length}
                pageSize={pageSize}
                onPageChange={(newPage) => setCurrentPage(newPage)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
