import { useEffect, useState } from "react";
import moment from "moment";
import varNames from "./../var-names.json";

export default function useShifts(lines, startDate, endDate, shiftsPerDay) {
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    const newShifts = [];

    lines.forEach((line) => {
      const days = endDate.diff(startDate, "days") + 1; //celkový počet dní (indexů)
      const arrayOfShifts = Array(days * shiftsPerDay);
      addBlocksToArrayOfShifts(
        line[varNames.blockedEvents],
        arrayOfShifts,
        shiftsPerDay,
        startDate
      );
      console.log("blocks added");
      addShiftsToArrayOfShifts(line[varNames.events], arrayOfShifts);
      console.log("shifts added");

      newShifts.push(arrayOfShifts);
    });

    setShifts(newShifts);
  }, [setShifts, lines, startDate, endDate, shiftsPerDay]);

  return { shifts };
}

function addBlocksToArrayOfShifts(
  blockEvents,
  shifts,
  shiftsPerDay,
  startDate
) {
  blockEvents.forEach((block) => {
    const diff =
      moment(block.from[varNames.date]).diff(startDate, "days") * shiftsPerDay;
    const blockStartIndex = diff + block.from[varNames.shift] - 1;

    for (
      let shiftIndex = blockStartIndex;
      shiftIndex < blockStartIndex + block[varNames.shifts];
      shiftIndex++
    ) {
      shifts[shiftIndex] = block;
    }
  });
}

function addShiftsToArrayOfShifts(events, shifts) {
  let index = 0;

  events.forEach((event) => {
    let shiftsAdded = 0;

    while (index < shifts.length && shiftsAdded < event[varNames.shifts]) {
      if (shifts[index]) {
        index++;
        continue;
      }

      shifts[index] = event;

      index++;
      shiftsAdded++;
    }
  });
}
