import React, { useState, useContext, useEffect } from "react";
import LongDivisionContext from "./../../context/longDivisionContext";
import UserContext from "./../../context/userContext";
import TabWrapper from "./../TabWrapper";
import DashIcon from "./../DashIcon";
import EditTab from "./LongDivision/EditTab";
import SettingsTab from "./LongDivision/SettingsTab";
import HistoryTab from "./LongDivision/HistoryTab";
import SaveTab from "./LongDivision/SaveTab";
import HelpTab from "./LongDivision/HelpTab";
import Output from "./LongDivision/Output";
import { runLongDivision } from "../../services/runService";
import useApi from "./../../hooks/useApi";

import varNames from "../../var-names.json";
import logger from "../../services/logService";

const leftPanel = [
  {
    name: varNames.editName,
    iconStyle: varNames.editIcon,
    opened: false,
    component: EditTab,
  },
  {
    name: varNames.settingsName,
    iconStyle: varNames.settingsIcon,
    opened: false,
    component: SettingsTab,
  },
  {
    name: varNames.historyName,
    iconStyle: varNames.historyIcon,
    opened: false,
    component: HistoryTab,
  },
  {
    name: varNames.saveName,
    iconStyle: varNames.saveIcon,
    opened: false,
    component: SaveTab,
  },
  {
    name: varNames.helpName,
    iconStyle: varNames.helpIcon,
    opened: false,
    component: HelpTab,
  },
];

// const initialFormData = {
//   [varNames.strips]: [
//     { [varNames.stripWidth]: 85, [varNames.neededWeight]: 41362 },
//     { [varNames.stripWidth]: 95, [varNames.neededWeight]: 42421 },
//     { [varNames.stripWidth]: 79, [varNames.neededWeight]: 5567 },
//   ],
//   [varNames.rolls]: [
//     { [varNames.serie]: 18758, [varNames.rollWeight]: 11110 },
//     { [varNames.serie]: 18754, [varNames.rollWeight]: 11190 },
//     { [varNames.serie]: 18761, [varNames.rollWeight]: 11190 },
//     { [varNames.serie]: 18753, [varNames.rollWeight]: 11200 },
//     { [varNames.serie]: 18757, [varNames.rollWeight]: 11230 },
//     { [varNames.serie]: 18759, [varNames.rollWeight]: 11310 },
//     { [varNames.serie]: 18760, [varNames.rollWeight]: 11320 },
//     { [varNames.serie]: 18762, [varNames.rollWeight]: 11350 },
//   ],
//   [varNames.rollWidth]: 1250,
//   [varNames.rollThickness]: 1.9,
//   [varNames.minWaste]: 1,
//   [varNames.maxWaste]: 6,
// };

const appDefault = {
  // data: initialFormData,
  [varNames.appData]: {
    [varNames.strips]: [],
    [varNames.rolls]: [],
    [varNames.rollWidth]: "",
    [varNames.rollThickness]: "",
    [varNames.minWaste]: "",
    [varNames.maxWaste]: "",
  },
};

export default function LongDivision({ defaultSettings, currentAppId }) {
  const { user, handlers: userHandlers } = useContext(UserContext);
  const {
    running,
    data: result,
    request: runApp,
    error: runError,
    resetData: resetResult,
    resetError: resetRunError,
    setData: setResult,
  } = useApi(runLongDivision);

  const [leftPanelSettings, setLeftPanelSettings] = useState(leftPanel);
  const [app, setApp] = useState(appDefault);
  const [insertedSettings, setInsertedSettings] = useState(null);

  useEffect(() => {
    const appFromUser = user[varNames.userApps].find(
      (a) => a[varNames._id] === currentAppId
    );

    setApp((prevApp) => {
      const newApp = { ...prevApp };
      const newAppSettings = {
        ...defaultSettings,
        ...appFromUser[varNames.appSettings],
      };
      newApp[varNames.appSettings] = newAppSettings;
      newApp[varNames.appHistory] = [...appFromUser[varNames.appHistory]];
      newApp[varNames.appSaved] = [...appFromUser[varNames.appSaved]];

      return newApp;
    });
  }, [user, currentAppId, defaultSettings]);

  const handlePanelChange = (name) => {
    setLeftPanelSettings((prevSettings) => {
      const newSettings = prevSettings.map((item) => {
        const newItem = { ...item };

        if (newItem.name === name) newItem.opened = !newItem.opened;
        else newItem.opened = false;

        return newItem;
      });

      return newSettings;
    });
  };

  const handleDataReset = () => {
    setApp((prevApp) => {
      const newApp = { ...prevApp };
      newApp[varNames.appData] = appDefault[varNames.appData];

      return newApp;
    });

    resetResult();
    resetRunError();
    setInsertedSettings(null);
  };

  const handleAppStart = (values) => {
    resetResult();
    resetRunError();
    setInsertedSettings(null);

    const dataToRun = {
      ...values,
      [varNames.stripsOverWeight]:
        app[varNames.appSettings][varNames.stripsOverWeight],
    };

    logger.logActivity("runLongDivision", user._id);

    runApp([dataToRun], handleHistorySave, [dataToRun]);
  };

  const handleEditSave = (data) => {
    setApp((prevApp) => {
      const newApp = { ...prevApp };
      newApp[varNames.appData] = data;

      return newApp;
    });

    resetResult();
    setInsertedSettings(null);
    handlePanelChange(varNames.editName);
  };

  const handleSettingsSave = (values) => {
    const onlyChangedSettings = {};

    //vyfiltruje vše, co není v default settings stejné a to uloží...
    for (let key in defaultSettings) {
      if (defaultSettings[key] !== values[key]) {
        onlyChangedSettings[key] = values[key];
      }
    }

    userHandlers.handleSettingsChange(currentAppId, onlyChangedSettings);
    handlePanelChange(varNames.settingsName);
    resetResult();
  };

  const handleCopyPaste = (items, name) => {
    setApp((prevApp) => {
      const newApp = { ...prevApp };
      newApp[varNames.appData] = { ...prevApp[varNames.appData] };
      newApp[varNames.appData][name] = items;

      return newApp;
    });

    resetResult();
    setInsertedSettings(null);
  };

  const handleHistoryInsert = (data) => {
    // je nutno aby objekt měl stejné vlastnosti jako "appData" konstanta
    const dataCopy = { ...data[varNames.historyInputData] };
    delete dataCopy[varNames.stripsOverWeight];

    setApp((prevApp) => {
      const newApp = { ...prevApp };
      newApp[varNames.appData] = dataCopy;

      return newApp;
    });

    setResult(data[varNames.historyResult]);
    setInsertedSettings(data[varNames.appSettings]);

    handlePanelChange(varNames.historyName);
  };

  const handleHistorySave = (result, inputData) => {
    const historyObj = {
      [varNames.historyResult]: result,
      [varNames.historyInputData]: inputData,
      [varNames.historyTime]: new Date(),
      [varNames.appSettings]: app[varNames.appSettings],
    };

    setInsertedSettings(null);

    userHandlers.handleAddHistory(currentAppId, historyObj);
  };

  const handleResultSave = () => {
    const savedObj = {
      [varNames.historyResult]: result,
      [varNames.historyInputData]: app[varNames.appData],
      [varNames.historyTime]: new Date(),
      [varNames.appSettings]: app[varNames.appSettings],
    };

    setInsertedSettings(null);

    userHandlers.handleAddSaved(currentAppId, savedObj);
  };

  const handleSavedInsert = (data) => {
    // je nutno aby objekt měl stejné vlastnosti jako "appData" konstanta
    const dataCopy = { ...data[varNames.historyInputData] };

    setApp((prevApp) => {
      const newApp = { ...prevApp };
      newApp[varNames.appData] = dataCopy;

      return newApp;
    });

    setResult(data[varNames.historyResult]);
    setInsertedSettings(data[varNames.appSettings]);

    handlePanelChange(varNames.saveName);
  };

  const handleSavedDelete = (obj) => {
    userHandlers.handleDeleteSaved(currentAppId, obj);
  };

  const handleResultWipe = () => {
    resetResult();
    setInsertedSettings(null);
  };

  const renderTab = () => {
    let openedTab = leftPanelSettings.find((x) => x.opened === true);

    return openedTab ? (
      <TabWrapper tab={openedTab} onTabClose={handlePanelChange}>
        <openedTab.component />
      </TabWrapper>
    ) : null;
  };

  const handlers = {
    handleEditSave,
    handleSettingsSave,
    handleDataReset,
    handleCopyPaste,
    handleHistoryInsert,
    handleResultSave,
    handleSavedInsert,
    handleSavedDelete,
    handleResultWipe,
    handleAppStart,
  };

  const runData = {
    running,
    result,
    runError,
  };

  const other = {
    insertedSettings,
  };

  return (
    <LongDivisionContext.Provider value={{ app, handlers, runData, other }}>
      <div className="row p-0 m-0 app-font-secondary">
        <div className="col-1 p-0">
          <div className="d-flex flex-column h-100 min-hv-100-no-navbar">
            {!running &&
              leftPanelSettings.map((setting) => (
                <DashIcon
                  key={setting.name}
                  onClick={handlePanelChange}
                  settings={setting}
                />
              ))}
          </div>
        </div>
        <div className="col-11 p-0">{renderTab() || <Output />}</div>
      </div>
    </LongDivisionContext.Provider>
  );
}
