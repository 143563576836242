import React from "react";

import { cz } from "../../../../language.json";

export default function TableMainHeader({
  inputCols,
  firstTitle = cz.general.input,
  outputCols,
}) {
  return (
    <tr>
      <th colSpan={inputCols} className="text-dark fs-3">
        {firstTitle}
      </th>
      {outputCols && (
        <th colSpan={outputCols + 2 /*2x odpad*/} className="text-dark fs-3">
          {cz.general.output}
        </th>
      )}
    </tr>
  );
}
