import { useEffect } from "react";
import {
  getUser,
  saveAppSettings,
  addAppHistory,
  addAppSaved,
  deleteAppSaved,
  changePassword,
} from "./services/userService";
import { getApplications } from "./services/applicationService";
import { Redirect, Route, Switch } from "react-router-dom";
import UserContext from "./context/userContext";
import Dashboard from "./components/routes/Dashboard";
import Applications from "./components/routes/Applications";
import Application from "./components/routes/Application";
import Account from "./components/routes/Account";
import Admin from "./components/routes/Admin";
import "./App.css";
import useApi from "./hooks/useApi";
import Loading from "./components/Loading";
import Error from "./components/Error";

import varNames from "./var-names.json";
import { cz } from "./language.json";
import AppsContext from "./context/appsContext";
import { checkErrorAndDecide } from "./utils";
import { logout } from "./services/authService";
import logger from "./services/logService";

function App({ userId }) {
  const {
    request: loadUser,
    data: user,
    setData: setUser,
    error: userError,
  } = useApi(getUser);
  const {
    request: loadApps,
    data: apps,
    error: appsError,
  } = useApi(getApplications);

  const handleSettingsChange = async (appId, newSettings) => {
    // optimistic update...
    const prevUserBackup = { ...user };

    setUser((prevUser) => {
      const newUser = { ...prevUser };
      const newApps = [...newUser[varNames.userApps]];
      const app = newApps.find((a) => a[varNames._id] === appId);
      const index = newApps.indexOf(app);

      const newAppObj = { ...app };
      newAppObj[varNames.appSettings] = newSettings;

      newApps[index] = newAppObj;
      newUser[varNames.userApps] = newApps;

      return newUser;
    });

    const response = await saveAppSettings(
      user[varNames._id],
      appId,
      newSettings
    ); //server call
    if (!response.ok) {
      checkErrorAndDecide(
        response.problem,
        () => alert(cz.general.invalidDataSend),
        () => alert(cz.general.saveSettingsError),
        () => alert(cz.general.saveSettingsError)
      );

      logger.logError(response);

      setUser(prevUserBackup);
    }
  };

  const handleAddHistory = async (appId, historyObj) => {
    // optimistic update...
    const prevUserBackup = { ...user };

    setUser((prevUser) => {
      const newUser = { ...prevUser };
      const newApps = [...newUser[varNames.userApps]];
      const app = newApps.find((a) => a[varNames._id] === appId);
      const index = newApps.indexOf(app);

      const newAppObj = { ...app };
      const newHistory = [...newAppObj[varNames.appHistory]];
      newHistory.push(historyObj);

      newAppObj[varNames.appHistory] = newHistory;

      newApps[index] = newAppObj;
      newUser[varNames.userApps] = newApps;

      return newUser;
    });

    const response = await addAppHistory(user[varNames._id], appId, historyObj); // server call
    if (!response.ok) {
      checkErrorAndDecide(
        response.problem,
        () => alert(cz.general.invalidDataSend),
        () => alert(cz.general.addHistoryError),
        () => alert(cz.general.addHistoryError)
      );

      logger.logError(response);

      setUser(prevUserBackup);
    } else {
      loadUser([userId]); //aby se historyObj seřadily a měli _id
    }
  };

  const handleAddSaved = async (appId, savedObj) => {
    // optimistic update...
    const prevUserBackup = { ...user };

    setUser((prevUser) => {
      const newUser = { ...prevUser };
      const newApps = [...newUser[varNames.userApps]];
      const app = newApps.find((a) => a[varNames._id] === appId);
      const index = newApps.indexOf(app);

      const newAppObj = { ...app };
      const newSaved = [...newAppObj[varNames.appSaved]];
      newSaved.push(savedObj);

      newAppObj[varNames.appSaved] = newSaved;

      newApps[index] = newAppObj;
      newUser[varNames.userApps] = newApps;

      return newUser;
    });

    const response = await addAppSaved(user[varNames._id], appId, savedObj); // server call
    if (!response.ok) {
      checkErrorAndDecide(
        response.problem,
        () => alert(cz.general.invalidDataSend),
        () => alert(cz.general.addSavedError),
        () => alert(cz.general.addSavedError)
      );

      logger.logError(response);

      setUser(prevUserBackup);
    } else {
      loadUser([userId]); //nutné aby se znovu stáhli saved i s _id, kdyby je pak user chtěl mazat. Toto není potřeba dělat u historie, protože ta se nemaže
    }
  };

  const handleDeleteSaved = async (appId, savedObj) => {
    // optimistic update...
    const prevUserBackup = { ...user };

    setUser((prevUser) => {
      const newUser = { ...prevUser };
      const newApps = [...newUser[varNames.userApps]];
      const app = newApps.find((a) => a[varNames._id] === appId);
      const index = newApps.indexOf(app);

      const newAppObj = { ...app };
      const newSaved = [...newAppObj[varNames.appSaved]].filter(
        (obj) => obj._id !== savedObj._id
      );

      newAppObj[varNames.appSaved] = newSaved;

      newApps[index] = newAppObj;
      newUser[varNames.userApps] = newApps;

      return newUser;
    });

    const response = await deleteAppSaved(user[varNames._id], appId, savedObj); // server call
    if (!response.ok) {
      checkErrorAndDecide(
        response.problem,
        () => alert(cz.general.invalidDataSend),
        () => alert(cz.general.deleteSavedError),
        () => alert(cz.general.deleteSavedError)
      );

      logger.logError(response);

      setUser(prevUserBackup);
    }
  };

  const handleChangePW = async (newPW) => {
    const response = await changePassword(user[varNames._id], newPW); // server call
    if (!response.ok) {
      checkErrorAndDecide(
        response.problem,
        () => alert(cz.general.invalidDataSend),
        () => alert(cz.general.changePWError),
        () => alert(cz.general.changePWError)
      );

      logger.logError(response);
    } else {
      logout();
      window.location = "/login";
    }
  };

  useEffect(() => {
    loadUser([userId]); //server call
    loadApps([]); //server call
  }, [userId]);

  const handlers = {
    handleSettingsChange,
    handleAddHistory,
    handleAddSaved,
    handleDeleteSaved,
    handleChangePW,
  };

  if (userError) {
    logger.logError(userError);

    const onClientErr = () => {
      logout();
      window.location = "/";

      return <div></div>;
    };

    return checkErrorAndDecide(
      userError,
      onClientErr,
      () => <Error />,
      () => <Error />
    );
  }

  if (appsError) {
    logger.logError(appsError);

    const onClientErr = () => {
      logout();
      window.location = "/";

      return <div></div>;
    };

    return checkErrorAndDecide(
      appsError,
      onClientErr,
      () => <Error />,
      () => <Error />
    );
  }

  if (!user || !user[varNames._id] || apps.length === 0) return <Loading />;

  return (
    <AppsContext.Provider value={apps}>
      <UserContext.Provider value={{ user, handlers }}>
        <Switch>
          <Route path="/app/dashboard" component={Dashboard} />
          <Route path="/app/applications/:id" component={Application} />
          <Route path="/app/applications" component={Applications} />
          <Route path="/app/account" component={Account} />
          <Route path="/app/admin" component={Admin} />
          <Redirect to="/app/dashboard" />
        </Switch>
      </UserContext.Provider>
    </AppsContext.Provider>
  );
}

export default App;
