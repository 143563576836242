import React from "react";
import TopScroller from "../../TopScroller";

import { cz } from "./../../../language.json";
import measures from "./../../../measures.json";
import varNames from "./../../../var-names.json";

const calMovement = () => {
  return (
    <span>
      Pohyb v kalendáři je možný pomocí tlačítek v horní a dolní části kalendáře
      vedle čísel aktuálního roku a měsíce. Tlačítko dozadu posouvá do
      minulosti, tlačítko dopředu do budoucnosti. Kalendář je možné posouvat
      pouze do měsíce, do kterého je vytvořen - tento datum naleznete v sekci
      "Nastavení" (<i className={`fa ${varNames.settingsIcon}`} />
      ).
      <br />
      Tlačítko DNES slouží k návratu na aktuální měsíc. Pokud je použito na
      aktuálním měsíci, posune obrazovku na aktuální den v kalendáři.
    </span>
  );
};

const connectedUsers = () => {
  return (
    <span>
      Ve žlutém boxu nad kalendářem se zobrazují jména aktuálně připojených
      uživatelů. Je zde tedy vidět i Vaše jméno. <b>POZOR!</b> Každý uživatel
      může zadat při vstupu libovolné jméno.
      <br />
      Pokud se vedle jména nachází <i className={`fa ${varNames.eyeIcon}`} /> je
      uživatel v režimu čtení.
      <br />
      Pokud se vedle jména nachází <i
        className={`fa ${varNames.editIconP}`}
      />{" "}
      je uživatel v režimu editace.
      <br />
      Dbejte pozor na to, aby byl v jednu chvíli v režimu editace pouze jeden
      uživatel.
    </span>
  );
};

const mode = () => {
  return (
    <span>
      Uživatelé se v aplikaci mohou pohybovat ve dvou režimech. Režim editace -
      umožňuje měnit data. Režim pro čtení - zabraňuje jakémukoliv změnění dat.
      <br />
      Mezi režimy se uživatel může přepínat pomocí modré ikony v pravé straně
      obrazovky.
      <br /> Ihned po přihlášení je uživatel automaticky nastaven do režimu pro
      čtení, zapínání tohoto režimu tedy nevyžaduje žádnou aktivitu. Pro
      přepnutí do režimu editace je potřeba kliknout na modrou ikonu{" "}
      <i className={`fa ${varNames.editIconP}`} /> na pravé straně.
      <br />
      Režim editace nabízí kromě přidávání výrobků/blokací také jejich úpravy,
      ty je možné provést po kliknutí na daný výrobek/blokaci v kalendáři.
      Uživatel v režimu pro čtení nemá možnost měnit data, může si
      výrobek/blokaci pouze prohlédnout.
    </span>
  );
};

const lineEdit = () => {
  return (
    <span>
      Veškerá editace linek probíhá v záložce "Linky" v levé části obrazovky, po
      kliknutí na <i className={`fa ${varNames.linesIcon}`} />. Je zde možnost
      linku přidat, změnit název již existujícím linkám nebo linku smazat.
      <br /> Možnost mazání linek je možné externě zablokovat. Vaši volbu
      zablokování mazání linek naleznete v záložce "Nastavení" (
      <i className={`fa ${varNames.settingsIcon}`} />
      ).
    </span>
  );
};

const blocks = () => {
  return (
    <span>
      Blokace se umisťují do kalendáře na určité datum a určitou směnu. Zadává
      se také poznámka a počet směn, po které bude daná linka blokována. Blokaci
      je možné umístit separátně na jakoukoliv linku, nebo na všechny linky
      najednou.
      <br />
      Vkládání blokace je možné po kliknutí na červené tlačítko s ikonou{" "}
      <i className={`fa ${varNames.plusIcon}`} /> v pravé části obrazovky
      (uživatel musí být v režimu editace). Následně uživatel zadá potřebné
      údaje a klikne na tlačítko "Uložit" ve spodní části okna, čímž se blokace
      vloží do kalendáře.
      <br />
      Blokace se v kalendáři zobrazují šrafovaně. Po kliknutí na blokaci v
      kalendáři je možné zjistit více informací o dané blokaci (v režimu čtění),
      v režimu editace je možné jakýkoliv údaj editovat - prostým změněním údajů
      a následným kliknutím na "Uložit". Po kliknutí lze blokace také smazat, v
      režimu editace v sekci "Akce" vyberte "Odstranit" a klikněte "Uložit".
      <br />
      <b>POZOR!</b> Blokace se odstraní jako celek (ze všech směn na kterých je
      rozložena), nikoliv pouze z dané směny.
    </span>
  );
};

const products = () => {
  const items = [
    {
      title: cz.general.moveToStart,
      text: "Posune výrobek na začátek kalendáře, na místo tzv. kotvy",
    },
    {
      title: cz.general.moveBackward,
      text: "Posune výrobek dozadu/blíž/do minulosti. Po vybrání této možnosti se objeví další políčko, do kterého je potřeba zadat počet (číslo), o kolik výrobků se má daný výrobek posunout.",
    },
    {
      title: cz.general.moveForward,
      text: "Posune výrobek dopředu/dál/do budoucnosti. Po vybrání této možnosti se objeví další políčko, do kterého je potřeba zadat počet (číslo), o kolik výrobků se má daný výrobek posunout.",
    },
    {
      title: cz.general.moveToEnd,
      text: "Posune výrobek za všechny ostatní",
    },
    {
      title: cz.general.delete,
      text: "Odstraní výrobek (předtím se ještě jednou zeptá, zda uživatel opravdu chce daný výrobek odstranit)",
    },
  ];
  return (
    <span>
      Výrobky se v kalendáři skládání automaticky za sebe. První výrobek na
      každé lince je na tzv. kotvě (počáteční datum kalendáře - naleznete ho v
      záložce "Nastavení" <i className={`fa ${varNames.settingsIcon}`} />
      ). Ostatní výrobky se postupně skládájí za sebe do směn po jednotlivých
      dnech. Pokud se na dané směně nachází blokace, je tato směna přeskočena a
      výrobky se skládají až za blokaci.
      <br />
      Výrobek obsahuje následující informace: Linku (na které je umístěn), Název
      (zobrazen v kalendáři), Poznámku, Počet směn (po které se bude vyrábět),
      Barvu (aby byl vizuálně odlišen od ostatních výrobků v kalendáři), Stav
      dokončeno (ANO/NE - určuje zda je výrobek již dokončen. Pokud je hodnota
      ANO, pak je výrobek v kalendáři šedý a přeškrtnutý).
      <br />
      Pro přidání výrobku je potřeba být v režimu editace a kliknout na zelené
      tlačítko <i className={`fa ${varNames.plusIcon}`} /> v pravé části
      obrazovky. Následně musí uživatel vyplnit údaje o výrobku a kliknout na
      tlačítko "Uložit" ve spodní části okna. (Pozn. barva se generuje
      automaticky, lze jí ale při přidávání upravovat). Po uložení se výrobek
      automaticky zařadí na konec všech výrobků v dané lince.
      <br />
      Po kliknutí na výrobek v kalendáři je možné zjistit více informací o daném
      výrobku (v režimu čtění), v režimu editace je možné jakékoliv údaje
      editovat - prostým změněním údajů a následným kliknutím na "Uložit". K
      pohybu výrobku po kalendáři slouží (po rozkliknutí výrobku) sekce "Akce"
      (na konci okna). Existují následující možnosti:
      <br />
      <ul className="m-0">
        {items.map((item, i) => (
          <li key={i}>
            <b>{item.title}:</b> {item.text}
          </li>
        ))}
      </ul>
      <br />
      Po vybrání jakékoliv možnosti je potřeba kliknout "Uložit". Výrobky se
      ihned přeskládají podle zadání.
    </span>
  );
};

const settings = () => {
  const items = [
    {
      title: cz.general.calStart,
      text: "Počáteční datum kalendáře. Na první směnu tohoto dne se ukotví vždy první výrobek na dané lince a ostatní se skládají za něj",
    },
    {
      title: cz.general.calEnd,
      text: "Poslední datum v kalendáři. Až po tento den se lze v kalendáři dostat. Konec kalendáře je zde z důvodu snažšího ukládání dat do databáze a nemá žádný vliv na funkce aplikace. Toto datum se bude automaticky zvyšovat",
    },
    {
      title: cz.general.shiftsPerDay,
      text: "Počet směn v jednom dni. Lze změnit a směny se automaticky začnou skládat po X směnách do jednoho dne. Změnění počtu směn za den většinou doprovází přegenerování blokací, které jsou nastavené na pevný počet směn s předchozím nastavením. Z tohoto důvodu může tento údaj měnit pouze administrátor",
    },
    {
      title: cz.general.lineDelete,
      text: "Údaj o tom, zda je uživatelnům povoleno smazat celou linku (včetně všech dat)",
    },
    {
      title: cz.general.pdfAdditionalCols,
      text: "Pokud je tato možnost povolena, při generování PDF se vedle každé linky vytvoří prázdný sloupec (např. pro ruční vpisování poznámek po tisku)",
    },
    {
      title: cz.general.pdfLandscape,
      text: "PDF se bude tisknout na šířku (při větším počtu linek je toto nastavení nutné)",
    },
  ];

  return (
    <span>
      Shrnutí uživatelského nastavení účtu je zobrazeno v záložce "Nastavení".
      Tato záložka se zobrazí po kliknutí na{" "}
      <i className={`fa ${varNames.settingsIcon}`} /> v levé části obrazovky.
      Nastavení není editovatelné uživatelem, nicméně lze změnit. Tato činnost
      vyžaduje administrátorskou práci. Vysvětlení jednotlivých nastaveních je
      níže:
      <br />
      <ul className="m-0">
        {items.map((item, i) => (
          <li key={i}>
            <b>{item.title}:</b> {item.text}
          </li>
        ))}
      </ul>
      <br />
    </span>
  );
};

const search = () => {
  return (
    <span>
      Záložka "Vyhledávání" se zobrazí po klinutí na{" "}
      <i className={`fa ${varNames.searchIcon}`} /> v levé části obrazovky.
      <br />
      Do vyhledávacího pole uživatel zadá název výrobku, který chce vyhledat.
      Vyhledávání se nemusí nijak potvrzovat, program ihned ukáže všechny
      výrobky, jejichž název obsahuje alespoň část zadaného textu (
      <b>nerozlišuje</b> velká a malá písmena).
      <br />
      Vyhledané výrobky se zobrazují na linkách, na kterých je naplánována
      jejich výroba. Je zobrazeno datum začátku a datum konce výroby daného
      výrobku. Po kliknutí na výrobek je uživatel přesměrován do kalendáře na
      měsíc, ve kterém se výrobek nachází. Pokud je výrobek naplánován přes 2
      měsíce, přesměrování vede na měsíc začátku výroby.
    </span>
  );
};

const pdf = () => {
  const items = [
    {
      title: cz.general.wholeMonth,
      text: "Vytvoří PDF z celého (na hlavní stránce aktuálně zvoleného) měsíce. Nebere ohled na to, jakým dnem v týdnu měsíc začíná a končí. Generování může zabrat i několiv vteřin.",
    },
    {
      title: cz.general.chooseWeeks,
      text: "Je nutno vybrat počáteční týden (zobrazují se pouze čísla týdnů z měsíce, který je aktuálně vybrán na hlavní stránce). Dále je nutné vybrat, kolik týdnů se má do PDF vložit. Po následném kliknutí na tlačítko VYGENEROVAT se PDF vytvoří. Tato možnost vždy zahrne celý týden (od pondělí do neděle), nehledě na to, zda se část týdne nachází v jiném měsíci. V nastavení na šířku se na jednu stránku vejdou 3 týdny, na výšku 4. Generování může zabrat i několiv vteřin.",
    },
  ];

  return (
    <span>
      Volba vytvoření PDF se zobrazí po kliknutí na žlutou ikonu{" "}
      <i className={`fa ${varNames.pdfIcon}`} /> v pravé části obrazovky.
      Uživatel má dvě možnosti, jak PDF vytvořit:
      <br />
      <ul className="m-0">
        {items.map((item, i) => (
          <li key={i}>
            <b>{item.title}:</b> {item.text}
          </li>
        ))}
      </ul>
      <br />
      Ať už je zvolena jakákoliv možnost, promítne se do ní uživatelské
      nastavení PDF (viz záložka <i className={`fa ${varNames.settingsIcon}`} />
      )
    </span>
  );
};

const content = [
  {
    title: "Posun v kalendáři",
    href: "month-movement",
    content: calMovement(),
  },
  {
    title: "Přihlášení uživatelé",
    href: "connected-users",
    content: connectedUsers(),
  },
  {
    title: "Režim editace/čtení",
    href: "mode",
    content: mode(),
  },
  {
    title: "Úprava linek",
    href: "line-edit",
    content: lineEdit(),
  },
  {
    title: "Blokace",
    href: "blocks",
    content: blocks(),
  },
  {
    title: "Výrobky",
    href: "products",
    content: products(),
  },
  {
    title: "Nastavení",
    href: "settings",
    content: settings(),
  },
  {
    title: "Vyhledávání",
    href: "search",
    content: search(),
  },
  {
    title: "PDF",
    href: "pdf",
    content: pdf(),
  },
];

const renderMenu = () => (
  <div className="help-content-section">
    <h3 className="help-content-heading">{cz.help.prodPlanner.content}</h3>
    <ul className="m-0">
      {content.map((c, i) => (
        <a
          href={`#${c.href}`}
          key={i}
          className="text-dark text-decoration-none app-font-secondary"
        >
          <li>{c.title}</li>
        </a>
      ))}
    </ul>
  </div>
);

export default function HelpTab() {
  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-12 p-3 p-md-5 pt-5">
          <h1 className="text-center text-white app-font-main">
            {cz.general.help}
          </h1>
          <div className="container container-white-trans app-font-secondary">
            <p className="text-center">
              <i>
                {cz.help.prodPlanner.contentText1}
                <b>{measures.contactEmail}</b>
              </i>
            </p>
            {renderMenu()}
            {content.map((section, i) => (
              <div key={i} className="help-content-section" id={section.href}>
                <h3 className="help-content-heading">{section.title}</h3>
                {section.content}
              </div>
            ))}
            <p className="text-center p-0 m-0 app-font-thirdary">
              {cz.help.prodPlanner.contentText2}
            </p>
          </div>
        </div>
      </div>

      <TopScroller />
    </>
  );
}
