import http from "./httpService";
import jwtDecode from "jwt-decode";

const endPoint = "/api/auth";
const tokenKey = "token";

export function login(email, password) {
  const data = { email, password };
  return http.post(endPoint, data);
}

export function setJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (e) {
    return null;
  }
}
