import React from "react";
import loadingGif from "../assets/loading.gif";
import { cz } from "../language.json";
import measures from "../measures.json";

export default function Loading() {
  return (
    <div className="text-center">
      <img
        src={loadingGif}
        alt={cz.general.loading}
        width={measures.loadingGifWidth}
      />
    </div>
  );
}
