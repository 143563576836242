import React from "react";
import CopyPasteInsert from "./CopyPasteInsert";

import { cz } from "../../../../language.json";

export default function Table({
  data,
  item,
  title,
  properties,
  titles,
  units,
  notInserting,
  tableStyles,
}) {
  return (
    <div className="container text-center">
      <h3 className="text-center text-secondary">{title}</h3>
      {!notInserting && (
        <div className="text-center my-2">
          <CopyPasteInsert name={item} heading={title} />
        </div>
      )}
      {data[item].length === 0 ? (
        <i>{cz.general.empty}</i>
      ) : (
        <table
          className={"table " + tableStyles}
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              {titles.map((t, i) => (
                <th key={i}>{t}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data[item].map((x, index) => (
              <tr key={index}>
                <td className="text-center">
                  {x[properties[0]]} {units[0]}
                </td>
                <td className="text-center">
                  {x[properties[1]]} {units[1]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
