import React, { useContext } from "react";
import ProdPlannerContext from "../../../context/prodPlannerContext";
import moment from "moment";

import { cz } from "./../../../language.json";
import varNames from "./../../../var-names.json";

const data = [
  { title: cz.general.calStart, value: varNames.startDate, type: "date" },
  { title: cz.general.calEnd, value: varNames.endDate, type: "date" },
  { title: cz.general.shiftsPerDay, value: varNames.shiftsPerDay },
  { title: cz.general.lineDelete, value: varNames.lineDelete, type: "bool" },
  {
    title: cz.general.pdfAdditionalCols,
    value: varNames.pdfAdditionalCols,
    type: "bool",
  },
  {
    title: cz.general.pdfLandscape,
    value: varNames.pdfLandscape,
    type: "bool",
  },
];

export default function SettingsTab() {
  const { app } = useContext(ProdPlannerContext);
  const settings = app[varNames.appSettings];

  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-12 p-3 p-md-5 pt-5">
          <h1 className="text-center text-white app-font-main">
            {cz.general.settings}
          </h1>
          <div className="container container-white-trans">
            <div className="row m-0 p-0">
              {data.map((item, i) => (
                <div key={i} className="col-sm-12 col-md-4 p-0 m-0 text-center">
                  <div className="p-3">
                    <h5 className="app-font-main fw-bold">{item.title}</h5>
                    <span className="app-font-thirdary display-6">
                      {renderValue(item, settings)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function renderValue(item, settings) {
  if (item.type === "date")
    return moment(settings[item.value]).format("D.M.YYYY");

  if (item.type === "bool")
    return settings[item.value] ? cz.general.yes : cz.general.no;

  return settings[item.value];
}
