import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

export default function Table({ columns, data }) {
  return (
    <div className="table-responsive text-center">
      <table className="table align-middle">
        <TableHeader columns={columns} />
        <TableBody data={data} columns={columns} />
      </table>
    </div>
  );
}
