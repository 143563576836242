import React, { useContext } from "react";
import LongDivisionContext from "./../../../context/longDivisionContext";
import Form from "../../forms/Form";
import Slider from "../../forms/Slider";
import SubmitButton from "../../forms/SubmitButton";
import CheckBox from "./../../forms/CheckBox";
import LineDivider from "./../../LineDivider";
import * as Yup from "yup";

import varNames from "../../../var-names.json";
import measures from "../../../measures.json";
import { cz } from "../../../language.json";
import DataChangedWarning from "../../forms/DataChangedWarning";

export default function SettingsTab() {
  const { app, handlers } = useContext(LongDivisionContext);

  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-12 p-3 p-md-5 pt-5">
          <h1 className="text-center text-white app-font-main">
            {cz.general.settings}
          </h1>
          <div className="container container-white-trans">
            <Form
              initialValues={app[varNames.appSettings]}
              validationSchema={validationSchema}
              onSubmit={handlers.handleSettingsSave}
            >
              <DataChangedWarning text={cz.general.dataChangedPlsSave} />
              <div className="slider-width-reduction">
                <Slider
                  title={cz.general.stripsOverWeight}
                  name={varNames.stripsOverWeight}
                  min={measures.minStripOverWeight}
                  max={measures.maxStripOverWeight}
                  step={1}
                />
              </div>
              <LineDivider color="black" />
              <CheckBox
                name={varNames.serverWork}
                title={cz.general.serverWork}
              />
              <LineDivider color="black" />
              <SubmitButton
                text={cz.general.save}
                divClasses="mt-4"
                btnClasses="bg-warning"
              />
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

const validationSchema = Yup.object().shape({
  [varNames.stripsOverWeight]: Yup.number()
    .typeError(cz.validation.number)
    .min(
      measures.minStripOverWeight,
      `${cz.validation.minimally} ${measures.minStripOverWeight}`
    )
    .max(
      measures.maxStripOverWeight,
      `${cz.validation.maximally} ${measures.maxStripOverWeight}`
    )
    .required(cz.validation.required),
  [varNames.serverWork]: Yup.boolean().required(cz.validation.required),
});
