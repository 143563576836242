import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { getCurrentUser, login, setJwt } from "../services/authService";
import Form from "./forms/Form";
import * as Yup from "yup";
import Input from "./forms/Input";
import SubmitButton from "./forms/SubmitButton";

import { cz } from "../language.json";
import varNames from "../var-names.json";
import { checkErrorAndDecide } from "../utils";
import Error from "./Error";
import logger from "./../services/logService";

export default function Login() {
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogIn = async (values) => {
    const response = await login(
      values[varNames.userEmail],
      values[varNames.userPassword]
    ); //server call
    if (!response.ok) {
      const clientErr = () => {
        setErrorMessage(cz.general.invalidLogin);
      };

      const others = () => {
        logger.logError(response);
        setErrorMessage(cz.general.serverError);
      };

      checkErrorAndDecide(response.problem, clientErr, others, others);
    } else {
      setJwt(response.data);
      window.location = "/app";
    }
  };

  if (getCurrentUser()) return <Redirect to="/" />;

  return (
    <div className="row p-0 m-0">
      <div className="col-12 p-3 p-md-5 pt-5">
        <h1 className="text-center text-black app-font-main">
          {cz.general.login}
        </h1>

        <div className="container">
          <div className="row p-0 m-0 justify-content-center">
            <div className="col-md-4 col-sm-12">
              <Form
                initialValues={{
                  [varNames.userEmail]: "",
                  [varNames.userPassword]: "",
                }}
                onSubmit={(values) => handleLogIn(values)}
                validationSchema={validationSchema}
              >
                <Input
                  name={varNames.userEmail}
                  type="email"
                  placeholder={cz.general.email}
                />
                <Input
                  name={varNames.userPassword}
                  type="password"
                  placeholder={cz.general.password}
                />
                <Error message={errorMessage} />
                <SubmitButton
                  text={cz.general.loginBTN}
                  btnClasses="btn-secondary"
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const validationSchema = Yup.object().shape({
  email: Yup.string(cz.validation.string)
    .email(cz.validation.email)
    .required(cz.validation.required),
  password: Yup.string(cz.validation.string).required(cz.validation.required),
});
