import React, { useEffect, useState } from "react";
import Form from "../../forms/Form";
import LeftInput from "../../forms/LeftInput";
import SubmitButton from "./../../forms/SubmitButton";
import * as Yup from "yup";
import { generateObjId } from "./../../../utils";

import { cz } from "./../../../language.json";
import varNames from "./../../../var-names.json";
import measures from "./../../../measures.json";

const editIcon = (
  <i className={`fa ${varNames.editIconP}`} aria-hidden="true"></i>
);
const watchIcon = (
  <i className={`fa ${varNames.eyeIcon}`} aria-hidden="true"></i>
);
const deleteIcon = (
  <i className={`fa ${varNames.trashIcon}`} aria-hidden="true"></i>
);

export default function LineRow({ line, onSubmit, onDelete, showDelete }) {
  const [readOnly, setReadOnly] = useState(true);
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (!line) {
      setIsNew(true);
      setReadOnly(false);
    }
  }, [line]);

  return (
    <div className="line-row my-3">
      <Form
        initialValues={initialValues(line)}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <div className="d-flex flex-row align-items-start">
          <LeftInput
            name={varNames.title}
            label={cz.general.title}
            type={"text"}
            readOnly={readOnly}
          />

          {!readOnly && <SubmitButton text={cz.general.save} />}
        </div>
      </Form>
      {!isNew && (
        <>
          <button
            className="btn btn-primary mx-3"
            onClick={() => setReadOnly((r) => !r)}
          >
            {readOnly ? editIcon : watchIcon}
          </button>
          {!readOnly && showDelete && (
            <button
              className="btn btn-danger mx-3"
              onClick={() => onDelete(line[varNames._id])}
            >
              {deleteIcon}
            </button>
          )}
        </>
      )}
    </div>
  );
}

const initialValues = (line) => ({
  [varNames._id]: line ? line[varNames._id] : generateObjId(),
  [varNames.title]: line ? line[varNames.title] : "",
  [varNames.blockedEvents]: line ? line[varNames.blockedEvents] : [],
  [varNames.events]: line ? line[varNames.events] : [],
});

const validationSchema = Yup.object().shape({
  [varNames._id]: Yup.string(cz.validation.string).required(
    cz.validation.required
  ),
  [varNames.title]: Yup.string(cz.validation.string)
    .max(
      measures.maxLineTitle,
      `${cz.validation.maximally} ${measures.maxLineTitle} ${cz.validation.chars}`
    )
    .required(cz.validation.required),
  [varNames.blockedEvents]: Yup.array().required(cz.validation.required),
  [varNames.events]: Yup.array().required(cz.validation.required),
});
