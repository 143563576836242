import React from "react";
import { useFormikContext } from "formik";
import ErrorMessage from "./ErrorMessage";

function Select({ name, label, items }) {
  const { handleBlur, handleChange, values } = useFormikContext();

  return (
    <div className="form-group row container">
      <div className="col-md-6 col-sm-12">
        <select
          id={name}
          name={name}
          aria-describedby={`${name}Help`}
          className="form-select"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name]}
        >
          <option value="" disabled></option>
          {items.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-6 col-sm-12 d-flex align-items-center">
        <label htmlFor={name} className="form-label m-0 fw-bold">
          {label}
        </label>
      </div>

      <div className="row container">
        <div className="col-12">
          <ErrorMessage name={name} />
        </div>
      </div>
    </div>
  );
}

export default Select;
