import React, { useContext, useEffect, useState } from "react";
import AppsContext from "./../../context/appsContext";
import useApi from "./../../hooks/useApi";
import {
  getUsers,
  addDaysToApp,
  addAppToUser,
} from "../../services/userService";
import moment from "moment";

import Form from "./../forms/Form";

import { cz } from "../../language.json";
import varNames from "../../var-names.json";
import measures from "../../measures.json";

import Input from "./../forms/Input";
import SubmitButton from "./../forms/SubmitButton";
import Table from "../table/Table";
import Select from "./../forms/Select";
import Error from "../Error";
import Loading from "../Loading";
import UserContext from "../../context/userContext";
import { checkErrorAndDecide } from "../../utils";
import { logout } from "../../services/authService";
import logger from "./../../services/logService";

export default function Admin() {
  const { user } = useContext(UserContext);
  const { request: loadUsers, data: users, running, error } = useApi(getUsers);
  const [chosenUser, setChosenUser] = useState(null);
  const apps = useContext(AppsContext);

  useEffect(() => {
    loadUsers([]);
  }, []);

  if (!user.isAdmin) return (window.location = "/");

  const getNewAppObj = (id) => {
    return {
      [varNames._id]: id,
      [varNames.appSaved]: [],
      [varNames.appHistory]: [],
      [varNames.appPaidUntil]: moment(new Date())
        .add(measures.numberOfFreeDays, "days")
        .toDate(),
      [varNames.appSettings]: {},
    };
  };

  const handleAddDays = async (userId, appId, days) => {
    // optimistic update...
    const prevUserBackup = { ...chosenUser };
    let newAppsArray;

    setChosenUser((prevUser) => {
      const newUser = { ...prevUser };
      const newApps = [...newUser[varNames.userApps]];
      const app = newApps.find((a) => a[varNames._id] === appId);
      const index = newApps.indexOf(app);

      const newAppObj = { ...app };
      const newPaidUntil = moment(newAppObj[varNames.appPaidUntil])
        .add(days, "days")
        .toDate();

      newAppObj[varNames.appPaidUntil] = newPaidUntil;

      newApps[index] = newAppObj;
      newUser[varNames.userApps] = newApps;

      newAppsArray = newApps;

      return newUser;
    });

    const response = await addDaysToApp(userId, newAppsArray); // server call
    if (!response.ok) {
      checkErrorAndDecide(
        response.problem,
        () => alert(cz.general.invalidDataSend),
        () => alert(cz.general.addDaysToAppError),
        () => alert(cz.general.addDaysToAppError)
      );

      logger.logError(response);

      setChosenUser(prevUserBackup);
    }
  };

  const handleAddApp = async (userId, appId) => {
    // optimistic update...
    const prevUserBackup = { ...chosenUser };

    const appObj = getNewAppObj(appId);

    let newAppsArray;

    setChosenUser((prevUser) => {
      const newUser = { ...prevUser };
      const newApps = [...newUser[varNames.userApps]];

      newApps.push(appObj);

      newUser[varNames.userApps] = newApps;

      newAppsArray = newApps;

      return newUser;
    });

    const response = await addAppToUser(userId, newAppsArray); // server call
    if (!response.ok) {
      checkErrorAndDecide(
        response.problem,
        () => alert(cz.general.invalidDataSend),
        () => alert(cz.general.addAppToUserError),
        () => alert(cz.general.addAppToUserError)
      );

      logger.logError(response);

      setChosenUser(prevUserBackup);
    }
  };

  const renderUserStuff = () => {
    if (!chosenUser) return cz.general.nothingChosen;

    const getAppsLayout = () => {
      chosenUser.apps.map((app) => {
        const appInDb = apps.find((a) => a[varNames._id] === app[varNames._id]);
        app[varNames.appName] = appInDb[varNames.appName];

        return app;
      });

      const columns = [
        {
          label: cz.general.title,
          content: (app) => app[varNames.appName],
          key: cz.general.title,
        },
        {
          label: cz.general.prepaid,
          content: (app) => moment(app[varNames.appPaidUntil]).format("D-M-YY"),
          key: cz.general.prepaid,
        },
        {
          label: cz.general.prolong,
          content: (app) => (
            <Form
              initialValues={{ [varNames.prolong]: "" }}
              onSubmit={(values) =>
                handleAddDays(
                  chosenUser[varNames._id],
                  app[varNames._id],
                  values[varNames.prolong]
                )
              }
            >
              <div className="row">
                <div className="col-8">
                  <Input
                    className="form-control"
                    type="number"
                    name={varNames.prolong}
                    label={cz.general.numberOfDays}
                  />
                </div>
                <div className="col-4">
                  <SubmitButton text={cz.general.add} />
                </div>
              </div>
            </Form>
          ),
          key: cz.general.prolong,
        },
      ];

      return (
        <div className="table-responsive">
          <Table data={chosenUser[varNames.userApps]} columns={columns} />
        </div>
      );
    };

    const userTable = [
      { value: chosenUser[varNames._id], label: cz.general.id },
      { value: chosenUser[varNames.userName], label: cz.general.title },
      { value: chosenUser[varNames.userEmail], label: cz.general.email },
      { value: chosenUser[varNames.userPhone], label: cz.general.phone },
      { value: chosenUser[varNames.userAddress], label: cz.general.address },
      { value: getAppsLayout(), label: cz.general.apps },
    ];

    const renderAppAdd = () => {
      const missingApps = apps.filter(
        (app) =>
          !chosenUser[varNames.userApps].find(
            (u_app) => u_app[varNames._id] === app[varNames._id]
          )
      );

      return (
        <Form
          initialValues={{ [varNames.appId]: "" }}
          onSubmit={(values) =>
            handleAddApp(chosenUser[varNames._id], values[varNames.appId])
          }
        >
          <div className="row">
            <div className="col-8">
              <Select
                name={varNames.appId}
                items={missingApps}
                label={cz.general.apps}
              />
            </div>
            <div className="col-4 d-flex justify-content-center align-items-center">
              <SubmitButton text={cz.general.add} />
            </div>
          </div>
        </Form>
      );
    };

    return (
      <>
        {userTable.map((row, i) => (
          <div key={i}>
            <div className="row">
              <div className="col-sm-3 d-flex align-self-center">
                <h6 className="m-0">{row.label}</h6>
              </div>
              <div className="col-sm-9 text-secondary text-md-end">
                {row.value}
              </div>
            </div>
            <hr />
          </div>
        ))}
        <div className="row">
          <div className="col-sm-3 d-flex align-self-center">
            <button
              className="btn btn-secondary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#addAppCollapse"
              aria-expanded="false"
              aria-controls="addAppCollapse"
            >
              {cz.general.addApp}
            </button>
          </div>
          <div
            className="col-sm-9 text-secondary text-md-end collapse align-self-center"
            id="addAppCollapse"
          >
            {renderAppAdd()}
          </div>
        </div>
      </>
    );
  };

  const renderUsers = () => {
    if (!users) return null;

    return (
      <ul className="list-group">
        {users.map((user) => (
          <li
            key={user[varNames.userEmail]}
            className={`list-group-item list-group-item-action cursor-pointer ${
              chosenUser && user[varNames._id] === chosenUser[varNames._id]
                ? "active"
                : ""
            }`}
            onClick={() => setChosenUser(user)}
          >
            {user[varNames.userEmail]}
          </li>
        ))}
      </ul>
    );
  };

  if (error) {
    logger.logError(error);

    const onClientErr = () => {
      logout();
      window.location = "/";

      return <div></div>;
    };

    return checkErrorAndDecide(
      error,
      onClientErr,
      () => <Error />,
      () => <Error />
    );
  }

  if (running) return <Loading />;

  return (
    <>
      <h1 className="text-center app-font-main text-uppercase fw-bold my-3">
        {cz.general.adminHeading}
      </h1>
      <div className="container">
        <div className="main-body">
          <div className="row">
            <div className="col-md-8 mb-3">
              <div className="card">
                <div className="card-body">{renderUserStuff()}</div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h6 className="d-flex align-items-center mb-3 text-uppercase">
                    {cz.general.customerAccounts}
                  </h6>
                  {renderUsers()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
