import React from "react";

export default function ProgressBar({ currentValue = 0, message = "" }) {
  // vše se bere v %, je nutné všecky hodnoty přepočítat na procenta
  const min = 0;
  const max = 100;

  return (
    <div className="progress">
      <div
        className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
        role="progressbar"
        style={{ width: `${currentValue}%` }}
        aria-valuenow={currentValue}
        aria-valuemin={min}
        aria-valuemax={max}
      >
        {message}
      </div>
    </div>
  );
}
