import React from "react";
import { Formik } from "formik";

function Form({
  initialValues,
  onSubmit,
  validationSchema,
  children,
  ...otherProps
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      {...otherProps}
    >
      {({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
    </Formik>
  );
}

export default Form;
