import React from "react";

import { cz } from "./../../../language.json";

export default function PPNav({ handlers, current, settings }) {
  const { startDate, endDate } = settings;
  return (
    <div className="d-flex align-items-center justify-content-center app-font-secondary my-2">
      <button
        className="btn btn-dark mx-3 app-font-main"
        onClick={() =>
          handlers.setCurrent(current.clone().subtract(1, "month"))
        }
        disabled={current.isSame(startDate, "month")}
      >
        {cz.general.backward}
      </button>
      <h1 className="text-center p-0 m-0">{current.format("M/YYYY")}</h1>
      <button
        className="btn btn-dark mx-3 app-font-main"
        onClick={() => handlers.setCurrent(current.clone().add(1, "month"))}
        disabled={current.isSame(endDate, "month")}
      >
        {cz.general.forward}
      </button>
    </div>
  );
}
