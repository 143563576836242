import http from "./httpService";

const endPoint = "/api/users";
const settingsEndPoint = "settings";
const historyEndPoint = "history";
const savedEndPoint = "saved";

export function getUsers() {
  return http.get(endPoint);
}

export function getUser(id) {
  return http.get(`${endPoint}/${id}`);
}

export function saveAppSettings(userId, appId, settings) {
  const data = { appId, settings };
  return http.put(`${endPoint}/${userId}/${settingsEndPoint}`, data);
}

export function addAppHistory(userId, appId, historyObj) {
  const data = { appId, history: historyObj };
  return http.post(`${endPoint}/${userId}/${historyEndPoint}`, data);
}

export function addAppSaved(userId, appId, savedObj) {
  const data = { appId, saved: savedObj };
  return http.post(`${endPoint}/${userId}/${savedEndPoint}`, data);
}

export function deleteAppSaved(userId, appId, savedObj) {
  const data = {
    appId,
    savedId: savedObj._id,
  };
  return http.delete(`${endPoint}/${userId}/${savedEndPoint}`, {}, { data });
}

export function changePassword(userId, newPW) {
  const data = { password: newPW };
  return http.put(`${endPoint}/${userId}`, data);
}

export function addDaysToApp(userId, apps) {
  const data = { apps };
  return http.put(`${endPoint}/${userId}`, data);
}

export function addAppToUser(userId, apps) {
  const data = { apps };
  return http.put(`${endPoint}/${userId}`, data);
}
