import React from "react";
import { useFormikContext } from "formik";
import ErrorMessage from "./ErrorMessage";

function Input({ name, label, type = "number", step = "any", ...rest }) {
  const { handleBlur, handleChange, values } = useFormikContext();

  return (
    <div className="mb-3">
      <div className="form-group row p-0 m-0 container">
        <div className={`col-md-${label ? "6" : "12"} col-sm-12`}>
          {" "}
          <input
            className="form-control"
            id={name}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[name]}
            type={type}
            step={step}
            {...rest}
          />
        </div>
        {label && (
          <div className="col-md-6 col-sm-12 d-flex align-items-center">
            <label htmlFor={name} className="form-label m-0 fw-bold">
              {label}
            </label>
          </div>
        )}
      </div>
      <div className="row container">
        <div className="col-12">
          <ErrorMessage name={name} />
        </div>
      </div>
    </div>
  );
}

export default Input;
