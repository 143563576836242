import React from "react";
import PropTypes from "prop-types";
import { usePagination, DOTS } from "../hooks/usePagination";

function Pagination({
  currentPage,
  itemsCount: totalCount,
  pageSize,
  onPageChange,
  siblingCount = 1,
}) {
  const oneLeft = <i className="fa fa-angle-left" aria-hidden="true"></i>;
  const oneRight = <i className="fa fa-angle-right" aria-hidden="true"></i>;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  const getDesign = (pageNumber) => {
    let defaultClasses = "cursor-pointer page-link ";

    if (currentPage === pageNumber)
      return (defaultClasses += "bg-dark text-white");

    return (defaultClasses += "text-dark");
  };

  const getDisabled = (eqToDis) => {
    return currentPage === eqToDis ? "disabled" : "";
  };

  const renderPageNumber = () => {
    return (
      <nav>
        <ul className="pagination">
          <li className={`page-item cursor-pointer ${getDisabled(1)}`}>
            <p
              onClick={onPrevious}
              className="page-link"
              style={{ color: "black" }}
            >
              {oneLeft}
            </p>
          </li>
          {paginationRange.map((pageNumber, i) => {
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === DOTS) {
              return (
                <li key={i} className="page-item disabled">
                  <p className="page-link">{DOTS}</p>
                </li>
              );
            }

            // Render our Page Pills
            return (
              <li key={i} className="page-item">
                <p
                  className={getDesign(pageNumber)}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </p>
              </li>
            );
          })}
          <li className={`page-item cursor-pointer ${getDisabled(lastPage)}`}>
            <p
              onClick={onNext}
              className="page-link"
              style={{ color: "black" }}
            >
              {oneRight}
            </p>
          </li>
        </ul>
      </nav>
    );
  };

  return renderPageNumber();
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
