import React, { useContext, useEffect, useMemo, useState } from "react";
import Result, { generteTable } from "./Result";
import Icon from "../../Icon";
import LongDivisionContext from "./../../../context/longDivisionContext";
import General from "./Output/General";
import Table from "./Output/Table";
import SettingsTable from "./Output/SettingsTable";
import FixedSideBar from "./../../FixedSideBar";
import { validationSchema } from "./EditTab";
import { renderPdfButton } from "../../../hooks/usePdf";

import varNames from "../../../var-names.json";
import { cz } from "../../../language.json";

export default function Output() {
  const { app, handlers, runData, other } = useContext(LongDivisionContext);
  const { [varNames.appData]: data, [varNames.appSettings]: settings } = app;
  const { running, result } = runData;
  const { insertedSettings } = other;

  const [startHidden, setStartHidden] = useState(true);
  const [saveHidden, setSaveHidden] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const resultPresentStrict = useMemo(
    () =>
      result[varNames.longDivOutput] &&
      result[varNames.longDivOutput].length > 0 &&
      result[varNames.usedRolls].length > 0,
    [result]
  );

  useEffect(() => {
    // data change
    validationSchema
      .validate(data)
      .then(() => {
        //checknou se ještě pásky a svitky, protože ty nejsou required ve schématu
        //ostatní taky nejsou required, ale musí být číslo...

        const stripsOK = data[varNames.strips].length > 0;
        const rollsOK = data[varNames.rolls].length > 0;

        if (!stripsOK || !rollsOK) setStartHidden(true);
        else setStartHidden(false);
      })
      .catch(() => setStartHidden(true));

    // insertedSettings & settings change
    if (!resultPresentStrict || !insertedSettings) {
      setWarningMessage("");
      setSaveHidden(false);
    } else checkInsertedSettings(insertedSettings, settings);
  }, [data, insertedSettings, settings, resultPresentStrict]);

  const playIcon = <i className="fa fa-play" aria-hidden="true"></i>;
  const eraseIcon = <i className="fa fa-eraser" aria-hidden="true"></i>;
  const resetIcon = <i className="fa fa-repeat" aria-hidden="true"></i>;
  const saveIcon = <i className="fa fa-floppy-o" aria-hidden="true"></i>;

  const resultPresent = !(
    typeof result === "object" && Object.keys(result).length > 0
  );

  const renderPdfIcon = () => {
    if (resultPresentStrict) {
      const Component = generteTable(result, true);
      return renderPdfButton(Component);
    }

    return null;
  };

  const checkInsertedSettings = (inserted, current) => {
    let notSame = [];
    let allSame = true;

    for (const property in inserted) {
      if (inserted[property] !== current[property]) {
        notSame.push(property);
        allSame = false;
      }
    }

    if (allSame) return setSaveHidden(false);
    else setSaveHidden(true);

    notSame = notSame.filter((x) => x !== varNames.serverWork);

    if (notSame.length > 0) {
      let changedStr = "";

      notSame.forEach((item) => {
        changedStr +=
          cz.general[item + "Short"] +
          "=" +
          inserted[item] +
          (item === varNames.stripsOverWeight ? "%" : "") +
          ", ";
      });

      setWarningMessage(
        cz.general.insertSettingsWarn1 +
          changedStr +
          cz.general.insertSettingsWarn2
      );
    }
  };

  return (
    <>
      <FixedSideBar>
        <Icon
          icon={playIcon}
          text={cz.general.start}
          onClick={() => handlers.handleAppStart(data)}
          bootstrap_color="success"
          hidden={running || startHidden}
        />
        {renderPdfIcon()}
        <Icon
          icon={eraseIcon}
          text={cz.general.wipe}
          onClick={() => handlers.handleResultWipe()}
          bootstrap_color="secondary"
          hidden={resultPresent}
        />
        <Icon
          icon={resetIcon}
          text={cz.general.reset}
          onClick={handlers.handleDataReset}
          bootstrap_color="danger"
          hidden={running}
        />
        <Icon
          icon={saveIcon}
          text={cz.general.save.toUpperCase()}
          onClick={handlers.handleResultSave}
          bootstrap_color="primary"
          hidden={running || saveHidden}
        />
      </FixedSideBar>
      <div className="bg-img">
        <Result />
        <div className="row p-0 m-0">
          <div className="col-12 p-3">
            <h1 className="text-center text-black app-font-main">
              {cz.general.sumup}
            </h1>
            <div className="container container-gray-trans">
              <div className="row p-0 m-0 d-flex justify-content-center">
                <p className="text-warning text-center">{warningMessage}</p>
                <div className="row p-0 m-0 col-md-6 col-sm-12">
                  <div className="col-md-12 col-lg-6">
                    <SettingsTable settings={settings} />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <General data={data} />
                  </div>
                </div>
                <div className="row p-0 m-0 col-md-6 col-sm-12">
                  <div className="col-md-12 col-lg-6">
                    <Table
                      data={data}
                      item={varNames.strips}
                      title={cz.general.strips}
                      properties={[varNames.stripWidth, varNames.neededWeight]}
                      titles={[cz.general.width, cz.general.weight]}
                      units={[cz.general.mm, cz.general.kg]}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <Table
                      data={data}
                      item={varNames.rolls}
                      title={cz.general.rolls}
                      properties={[varNames.serie, varNames.rollWeight]}
                      titles={[cz.general.serie, cz.general.weight]}
                      units={["", cz.general.kg]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
