import React, { useState, useEffect } from "react";

export default function SideIcon({
  icon,
  text,
  onClick,
  bootstrap_color,
  hidden,
}) {
  const [btnText, setBtnText] = useState();

  useEffect(() => {
    setBtnText(icon);
  }, [icon]);

  if (hidden) return null;

  const width = btnText === icon ? "2em" : "";

  return (
    <button
      className={`btn btn-${bootstrap_color} right-side-icon`}
      onMouseOver={() => setBtnText(text)}
      onMouseOut={() => setBtnText(icon)}
      onClick={onClick}
      style={{
        width,
      }}
    >
      {btnText}
    </button>
  );
}
