import ObjectID from "bson-objectid";
import _ from "lodash";
import moment from "moment";
import varNames from "./var-names.json";

export function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}

export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;

  return _.slice(items, startIndex, startIndex + pageSize);
}

export function sortByParam(items, params, order = "desc") {
  return _.orderBy(items, params, order);
}

export function checkErrorAndDecide(
  error,
  onClientErr,
  onServerErr,
  onOtherErr
) {
  const problems = varNames.apiSauceProblems;
  if (error === problems.CLIENT_ERROR) return onClientErr();
  if (error === problems.SERVER_ERROR) return onServerErr();

  return onOtherErr();
}

export function generateObjId() {
  return ObjectID().toHexString();
}

export function generateHexColor() {
  const n = (Math.random() * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

export function generateWeekEnds(shiftsPerDay, startDate, endDate) {
  const blocked = [];
  const daysBetween = getDaysBetween(startDate, endDate);
  daysBetween.forEach((day) => {
    const isSaturday = moment(day).isoWeekday() === 6;
    if (isSaturday) {
      blocked.push({
        _id: generateObjId(),
        from: {
          date: day,
          shift: 1,
        },
        shifts: shiftsPerDay * 2,
        isBlock: true,
        note: "Víkend",
      });
    }
  });

  return blocked;
}

export function block2ndShifts(startDate, endDate, note) {
  const blocked = [];
  const daysBetween = getDaysBetween(startDate, endDate);

  daysBetween.forEach((day) => {
    const isWeekend =
      moment(day).isoWeekday() === 6 || moment(day).isoWeekday() === 7;
    if (!isWeekend) {
      blocked.push({
        _id: generateObjId(),
        from: {
          date: day,
          shift: 2,
        },
        shifts: 1,
        isBlock: true,
        note,
      });
    }
  });

  return blocked;
}

export function getDaysBetween(startDate, endDate) {
  const now = moment(startDate).clone();
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.toDate());
    now.add(1, "days");
  }
  return dates;
}
