import React, { useMemo } from "react";
import { Text, View } from "@react-pdf/renderer";
import styles, { style_measures } from "./pdfStyles";
import moment from "moment";

const { shiftH } = style_measures;

export default function PDFDates({ dates, settings, shiftRows }) {
  const { shiftsPerDay } = settings;

  const shiftRowsContent = useMemo(() => getShiftRows(shiftRows), [shiftRows]);

  return (
    <View style={styles.colUnderShiftColHeading}>
      {dates.map((day) => {
        const isWeekend =
          moment(day).isoWeekday() === 6 || moment(day).isoWeekday() === 7;

        return (
          <View
            key={day}
            style={{
              ...styles.dayCover,
              height: `${shiftH * shiftsPerDay}mm`,
              backgroundColor: isWeekend ? "#C6E0B4" : "",
            }}
          >
            <Text style={styles.day}>
              {moment(day).format("dd")} {moment(day).format("D.M.")}
            </Text>
            <View style={styles.dayShift}>{shiftRowsContent}</View>
          </View>
        );
      })}
    </View>
  );
}

function getShiftRows(shiftRows) {
  return (
    <>
      {shiftRows.map((s, i) => (
        <View
          key={i}
          style={{
            height: `${shiftH}mm`,
            borderBottom: i === shiftRows.length - 1 ? "" : "1px solid black",
            borderTop: i === 0 ? "" : "1px solid black",
            ...styles.dayShiftType,
          }}
        >
          <Text>{s}</Text>
        </View>
      ))}
    </>
  );
}
