import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import styles from "./pdfStyles";
import ShiftsColumn from "../ShiftsColumn";
import PDFWeeks from "./PDFWeeks";
import PDFDates from "./PDFDates";
import moment from "moment";

import varNames from "./../../../../var-names.json";

export default function PDFoutput({
  current,
  lines,
  dates,
  weeks,
  shiftRows,
  settings,
  shifts,
}) {
  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
        orientation={settings[varNames.pdfLandscape] ? "landscape" : "portrait"}
      >
        <Text>{moment(current).format("M/YYYY")}</Text>
        <View style={styles.calCover}>
          <PDFWeeks weeks={weeks} settings={settings} />
          <PDFDates dates={dates} settings={settings} shiftRows={shiftRows} />
          <View style={styles.lineColsCover}>
            {lines.map((line, i) => {
              const propObj = {
                current,
                line,
                settings,
                handlers: {},
                shifts: shifts[i],
              };

              return (
                <ShiftsColumn key={line[varNames._id]} {...propObj} forPDF />
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
}
